import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ContextData } from '../Components/ContextData';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { FloatingLabel, Form } from 'react-bootstrap'
import ucwords from 'ucwords';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/shared/agGridColumn';
import html2canvas from "html2canvas";

var moment = require('moment')

const LeadSourceReport = () => {
    const exportRef = useRef();
    var navigate = useNavigate();
    

    const context_data = useContext(ContextData)

    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);

    const [buildersData, setBuildersData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);

    const [selectedEmployeeId, setSelectedEmployeeId] = useState("")
    const [reportRequestDate, setReportRequestDate] = useState("All Data")
    const [leadSourceData, setLeadSourceData] = useState([])

    const [sumTotal, setSumTotal] = useState(0)
    const [sumIncoming, setSumIncoming] = useState(0);
    const [sumWarm, setSumWarm] = useState(0);
    const [sumProspect, setSumProspect] = useState(0);
    const [sumClosed, setSumClosed] = useState(0);
    const [sumBooked, setSumBooked] = useState(0);

    const [typeValue, setTypeValue] = useState("all_data");
    const [dateFrom, setDateFrom] = useState(moment(new Date()).format("yyyy-MM-DD"))
    const [maxDateFrom, setMaxDateFrom] = useState(moment(new Date()).format("yyyy-MM-DD"))
    const [dateTo, setDateTo] = useState(moment(new Date()).format("yyyy-MM-DD"))
    const [minDateTo, setMinDateTo] = useState("")
    const [userRightsRegex, setUserRightsRegex] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                setUserRightsRegex(regex)
                isAvailable = regex.test("MARKETING") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }

            setUserRightStatus(isAvailable)

            if (isAvailable != true) { navigate("/noAccess") }
            else {
                getBuilders();
            }
            handleSelectChange()
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    const handleSelectChange = () => {
        document.getElementById("btn_form_submit").click()
    }

    const handleTypeChange = (e) => {
        setDateFrom(moment(new Date()).format("yyyy-MM-DD"))
        setDateTo(moment(new Date()).format("yyyy-MM-DD"))
        setTypeValue(e.target.value)
        handleSelectChange()
    }

    async function getBuilders() {
        context_data.setProcessing(true)
        const getBuildersUrl = global.config.server_url + "getBuilders.php";
        await axios.post(getBuildersUrl, { all_data: true }).then((getBuildersResponse) => {
            if (getBuildersResponse.data) {
                setBuildersData(getBuildersResponse.data);
                context_data.setProcessing(false)
            } else {
                setBuildersData([]);
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => {
            console.log(err);
            setBuildersData([]);
            context_data.setProcessing(false)
        });
    }

    async function getProjects(builder_id) {
        context_data.setProcessing(true)
        const getProjectsUrl = global.config.server_url + "getProjects.php";
        await axios.post(getProjectsUrl, { builder_id: builder_id }).then((getProjectsResponse) => {
            if (getProjectsResponse.data) {
                setProjectsData(getProjectsResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                setProjectsData([])
                context_data.setProcessing(false)
            }
        }).catch(err => {
            console.log(err);
            setProjectsData([])
            context_data.setProcessing(false)
        });
    }

    const getLeadSourceReportCount = async (e) => {
        e.preventDefault()

        setLeadSourceData([]);

        var type = e.target.view_type ? e.target.view_type.value : "all_data";
        var employee_id = e.target.employee ? e.target.employee.value : "";
        var builder_id = e.target.builder ? e.target.builder.value : "";
        var project_id = e.target.project ? e.target.project.value : "";

        setSelectedEmployeeId(employee_id)

        var getLeadSourceData = "";

        switch (type) {
            case "all_data":
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, all_data: true }
                setReportRequestDate("All Data")
                break;
            case "today":
                var start_date = moment(new Date()).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }
                var showData = moment(new Date()).format("DD-MMM-YYYY")
                setReportRequestDate(showData)
                break;
            case "yesterday":
                var date = moment(new Date()).subtract(1, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(date).format("YYYY-MM-DD")
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }
                var showData = moment(date).format("DD-MMM-YYYY")
                setReportRequestDate(showData)
                break;
            case "this_week":
                var week_start_date = moment().startOf('week').format("YYYY-MM-DD")
                var week_end_date = moment(new Date()).format("YYYY-MM-DD")

                var start_date = moment(week_start_date).format("YYYY-MM-DD")
                var end_date = week_end_date
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(week_start_date).format("DD-MMM-YYYY")) + " - " + (moment(week_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)
                break;
            case "last_7_days":
                var date = moment(new Date()).subtract(7, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_week":
                var date = moment(new Date()).subtract(1, "week").toDate()
                var week_start_date = moment(date).startOf('week').format("YYYY-MM-DD")
                var week_end_date = moment(date).endOf('week').format("YYYY-MM-DD")

                var start_date = moment(week_start_date).format("YYYY-MM-DD")
                var end_date = moment(week_end_date).format("YYYY-MM-DD")
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(week_start_date).format("DD-MMM-YYYY")) + " - " + (moment(week_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_14_days":
                var date = moment(new Date()).subtract(14, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "this_month":
                var month_start_date = moment().startOf('month').format("YYYY-MM-DD")
                var month_end_date = moment(new Date()).format("YYYY-MM-DD")

                var start_date = moment(month_start_date).format("YYYY-MM-DD")
                var end_date = month_end_date
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(month_start_date).format("DD-MMM-YYYY")) + " - " + (moment(month_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_30_days":
                var date = moment(new Date()).subtract(30, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_month":
                var date = moment(new Date()).subtract(1, "month").toDate()
                var month_start_date = moment(date).startOf('month').format("YYYY-MM-DD")
                var month_end_date = moment(date).endOf('month').format("YYYY-MM-DD")

                var start_date = moment(month_start_date).format("YYYY-MM-DD")
                var end_date = moment(month_end_date).format("YYYY-MM-DD")
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(month_start_date).format("DD-MMM-YYYY")) + " - " + (moment(month_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "this_year":
                var year_start_date = moment().startOf('year').format("YYYY-MM-DD")
                var year_end_date = moment(new Date()).format("YYYY-MM-DD")

                var start_date = moment(year_start_date).format("YYYY-MM-DD")
                var end_date = year_end_date
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(year_start_date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_year":
                var date = moment(new Date()).subtract(1, "year").toDate()
                var year_start_date = moment(date).startOf('year').format("YYYY-MM-DD")
                var year_end_date = moment(date).endOf('year').format("YYYY-MM-DD")

                var start_date = moment(year_start_date).format("YYYY-MM-DD")
                var end_date = moment(year_end_date).format("YYYY-MM-DD")
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(year_start_date).format("DD-MMM-YYYY")) + " - " + (moment(year_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "custom_date":
                var start_date = e.target.date_from ? e.target.date_from.value : moment(new Date()).format("YYYY-MM-DD");
                var end_date = e.target.date_to ? e.target.date_to.value : moment(new Date()).format("YYYY-MM-DD");
                getLeadSourceData = { employee_id: employee_id, builder_id: builder_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(year_start_date).format("DD-MMM-YYYY")) + " - " + (moment(year_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)
                break;

            default:
                alert("Error")
                setReportRequestDate("")
                break;
        }

        const getLeadSourceCountUrl = global.config.server_url + "getLeadSourceCount.php";
        await axios.post(getLeadSourceCountUrl, getLeadSourceData).then((getLeadSourceCountResponse) => {
            if (getLeadSourceCountResponse.data) {
                context_data.setProcessing(false)
                var count = getLeadSourceCountResponse.data.count
                count = (count / 100);
                var loop_value = Math.ceil(count)
                getLeadSource(loop_value, getLeadSourceData)
            } else {
                console.log("error in getting leadsource count")
                setLeadSourceData([])
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); setLeadSourceData([]); context_data.setProcessing(false) });
    }

    const getLeadSource = async (loop_value, getLeadSourceData) => {
        var progress_add_value = 100 / loop_value;

        var sum_total = 0;
        var sum_incoming = 0;
        var sum_warm = 0;
        var sum_prospect = 0;
        var sum_closed = 0;
        var sum_booked = 0;

        for (let i = 0; i < loop_value; i++) {
            getLeadSourceData.offset_value = i * 100;
            const getLeadSourceUrl = global.config.server_url + "getLeadSourceUsingOffset.php";
            await axios.post(getLeadSourceUrl, getLeadSourceData).then((getLeadSourceResponse) => {
                if (getLeadSourceResponse.data) {
                    var leads = getLeadSourceResponse.data
                    setLeadSourceData(old => [...old, ...leads]);
                    for (let i = 0; i < getLeadSourceResponse.data.length; i++) {
                        const element = getLeadSourceResponse.data[i];
                        sum_total = parseInt(sum_total) + parseInt(element.Total);
                        sum_incoming = parseInt(sum_incoming) + parseInt(element.Incoming);
                        sum_warm = parseInt(sum_warm) + parseInt(element.Warm);
                        sum_prospect = parseInt(sum_prospect) + parseInt(element.Prospect);
                        sum_closed = parseInt(sum_closed) + parseInt(element.Closed);
                        sum_booked = parseInt(sum_booked) + parseInt(element.Booked);
                    }
                } else {
                    console.log("error in getting data")
                }
            }).catch(err => { console.log(err); });
        }
        setSumTotal(sum_total);
        setSumIncoming(sum_incoming);
        setSumWarm(sum_warm);
        setSumProspect(sum_prospect);
        setSumClosed(sum_closed);
        setSumBooked(sum_booked);

    }

    const cellClassRules = {
        "alert alert-primary table_sticky_row": params => ((params.node.rowPinned))
    };

    function createSumData() {
        var result = [{
            serial_number: "-",
            first_source_of_enquiry: "-",
            Total: sumTotal,
            Incoming: sumIncoming,
            Warm: sumWarm,
            Prospect: sumProspect,
            Closed: sumClosed,
            Booked: sumBooked,
        }];
        return result;
    }

    const [divLeadSourceListStyle, setDivLeadSourceListStyle] = useState({ display: "block" })

    const exportAsImage = async (element, imageFileName) => {
        setDivLeadSourceListStyle({ display: "block" });
        // document.getElementById("div_lead_source_list").style.display = "block"
        const html = document.getElementsByTagName("html")[0];
        const body = document.getElementsByTagName("body")[0];
        let htmlWidth = html.clientWidth;
        let bodyWidth = body.clientWidth;
        const newWidth = element.scrollWidth - element.clientWidth;
        if (newWidth > element.clientWidth) {
            htmlWidth += newWidth;
            bodyWidth += newWidth;
        }
        html.style.width = htmlWidth + "px";
        body.style.width = bodyWidth + "px";
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
        html.style.width = null;
        body.style.width = null;
        setDivLeadSourceListStyle({ display: "none" });
    };

    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;

        fakeLink.href = blob;

        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);

        fakeLink.remove();
    };

    const handleDateFromChange = (e) => {
        var check_in = e.target.value
        setDateFrom(check_in)
        var check_out = moment(check_in).add(1, "days").format("yyyy-MM-DD")
        setMinDateTo(check_out)
        handleSelectChange()

    }

    const handleDateToChange = (e) => {
        var check_out = e.target.value
        setDateTo(check_out)

        var check_in = moment(check_out).subtract(1, "days").format("yyyy-MM-DD")
        setMaxDateFrom(check_in)
        handleSelectChange()
    }

    return (
        <section id="sectionLeadSource">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate("/reports")}>Reports</Breadcrumb.Item>
                            <Breadcrumb.Item active>Lead Souce Report</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className='text-center'>Lead Source Report</h3>
                    </div>
                </div>

                <form onSubmit={getLeadSourceReportCount}>
                    <div className="row">
                        <div className="col-md-3">
                            <FloatingLabel controlId="floatingSelect" label="Builder">
                                <Form.Select name="builder" id="select_builder" aria-label="Floating label Select example"
                                    onChange={(e) => { getProjects(e.target.value); handleSelectChange(); }}>
                                    <option value="" selected>Select Builder</option>
                                    {buildersData.map((builder) => <option key={builder.id} value={builder.builder_id}>{ucwords(builder.builder_name.toLowerCase())}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </div>

                        <div className="col-md-3">
                            <FloatingLabel controlId="floatingSelect" label="Project">
                                <Form.Select name="project" id="select_project" aria-label="Floating label Select example"
                                    onChange={handleSelectChange}>
                                    <option value="" selected>Select Project</option>
                                    {projectsData.map((project) => <option key={project.id} value={project.builder_project_id}>{ucwords(project.builder_project_name.toLowerCase())}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                        <div className="col-md-2">
                            <FloatingLabel controlId="floatingSelect" label="View">
                                <Form.Select name="view_type" id="select_view_type" aria-label="Floating label Select view"
                                    onChange={handleTypeChange}>
                                    <option value="all_data" selected>All Data</option>
                                    <option value="today">Today</option>
                                    <option value="yesterday">Yesterday</option>
                                    <option value="this_week">This Week</option>
                                    <option value="last_7_days">Last 7 days</option>
                                    <option value="last_week">Last Week</option>
                                    <option value="last_14_days">Last 14 days</option>
                                    <option value="this_month">This Month</option>
                                    <option value="last_30_days">Last 30 days</option>
                                    <option value="last_month">Last Month</option>
                                    <option value="this_year">This Year</option>
                                    <option value="last_year">Last Year</option>
                                    <option value="custom_date">Custom Date</option>
                                </Form.Select>
                            </FloatingLabel>
                        </div>

                        {(typeValue == "custom_date") && (
                            <div className="col-md-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-floating mb-3">
                                            <input type="date" name="date_from" className="form-control" id="floatingInput" placeholder="Date From" max={maxDateFrom} value={dateFrom} required onChange={handleDateFromChange} />
                                            <label for="floatingInput">From</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-3">
                                            <input type="date" name="date_to" className="form-control" id="floatingInput" placeholder="Date To" min={minDateTo} value={dateTo} required onChange={handleDateToChange} />
                                            <label for="floatingInput">To</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}

                        <input type="submit" value="submit" id="btn_form_submit" style={{ display: "none" }} />
                        <div className="col-md-1 text-end">
                            <button className='btn btn-secondary' onClick={() => exportAsImage(exportRef.current, ("lead_source_" + moment(new Date()).format("YYYYMMDDHHmmss") + "_" + (selectedEmployeeId ? selectedEmployeeId : localStorage.getItem("employee_id"))))}>
                                Capture Image
                            </button>
                        </div>

                    </div>
                </form>

                <div className="row">
                    <div className="col-12">
                        {(leadSourceData && leadSourceData) ? (
                            <>
                                <div className="ag-theme-alpine" style={{ height: "75vh", width: "100%" }}>
                                    <AgGridReact
                                        pinnedTopRowData={createSumData()}
                                        defaultColDef={context_data.aggridDefaultColDef}
                                        rowData={leadSourceData}
                                        pagination={true}
                                        paginationAutoPageSize={true}
                                        animateRows={true}
                                        ref={context_data.aggridRef}
                                        onGridReady={() => context_data.aggridAutoSizeAll(false)}
                                        onFirstDataRendered={() => context_data.aggridAutoSizeAll(false)}

                                    >
                                        <AgGridColumn width="100" headerName="Sl No" field="serial_number" cellClassRules={cellClassRules} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Project" field="project_id" valueGetter={context_data.aggrid_ucwords_value} cellClassRules={cellClassRules} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Project" field="project_name" valueGetter={context_data.aggrid_ucwords_value} cellClassRules={cellClassRules} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Builder" field="builder_name" valueGetter={context_data.aggrid_ucwords_value} cellClassRules={cellClassRules} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Source" field="first_source_of_enquiry" cellClassRules={cellClassRules} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Total" field="Total" cellClassRules={cellClassRules} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Incoming" field="Incoming" cellClassRules={cellClassRules} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Warm" field="Warm" cellClassRules={cellClassRules} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Prospect" field="Prospect" cellClassRules={cellClassRules} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Closed" field="Closed" cellClassRules={cellClassRules} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Booked" field="Booked" cellClassRules={cellClassRules} ></AgGridColumn>
                                    </AgGridReact>
                                </div>
                                <div className="div_lead_source_list" id="div_lead_source_list" style={divLeadSourceListStyle}>
                                    <table id="all_lead_source_list" className="display table table-striped table-bordered " ref={exportRef} >
                                        <thead className="bg-dark text-white">
                                            <tr>
                                                <th colSpan={"16"} className="text-center">Leads Summary{(reportRequestDate) ? " : " + reportRequestDate : ""}</th>
                                            </tr>
                                            <tr>
                                                <th className='lead_source_list_th'>#</th>
                                                <th className='lead_source_list_th'>Project ID</th>
                                                <th className='lead_source_list_th'>Project Name</th>
                                                <th className='lead_source_list_th'>Builder Name</th>
                                                <th className='lead_source_list_th'>Source</th>
                                                <th className='lead_source_list_th'>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={"total"} >
                                                <td className="lead_source_list_td_total">Total</td>
                                                <td className="lead_source_list_td_total">-</td>
                                                <td className="lead_source_list_td_total">-</td>
                                                <td className="lead_source_list_td_total">-</td>
                                                <td className="lead_source_list_td_total">-</td>
                                                <td className="lead_source_list_td_total text-end">{sumTotal}</td>
                                            </tr>

                                            {leadSourceData.map((leadSource, index) =>
                                                <tr key={leadSource.id} >
                                                    <td className="lead_source_list_td">{index + 1}</td>
                                                    <td className="lead_source_list_td">{(leadSource.project_id) ? leadSource.project_id : "-"}</td>
                                                    <td className="lead_source_list_td">{(leadSource.project_name) ? ucwords(leadSource.project_name.toLowerCase()) : "-"}</td>
                                                    <td className="lead_source_list_td">{(leadSource.builder_name) ? ucwords(leadSource.builder_name.toLowerCase()) : "-"}</td>
                                                    <td className="lead_source_list_td text-end">{(leadSource.first_source_of_enquiry) ? ucwords(leadSource.first_source_of_enquiry.toLowerCase()) : ""}</td>
                                                    <td className="lead_source_list_td text-end">{(leadSource.Total) ? leadSource.Total : "0"}</td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        ) : (<div><h3 className="text-center m-5">No Data Available</h3></div>)}

                    </div>
                </div>
            </div>

        </section>

    )
}

export default LeadSourceReport