import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

function ChangePassword() {

    const context_data = useContext(ContextData)
    var navigate = useNavigate();

    const [loginData, setLoginData] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    const [currentPasswordError, setCurrentPasswordError] = useState(true);
    const [confirmPasswordError, setConfirmPasswordError] = useState(true);

    const checkNewConfirmPassword = () => {
        if ((document.getElementById("new_password").value != null) && (document.getElementById("confirm_password").value != null)) {
            if (document.getElementById("new_password").value == document.getElementById("confirm_password").value) {
                setConfirmPasswordError(false);
                document.getElementById("new_password").style.border = "1px solid green";
                document.getElementById("confirm_password").style.border = "1px solid green";
            } else {
                setConfirmPasswordError(true);
                document.getElementById("new_password").style.border = "1px solid red";
                document.getElementById("confirm_password").style.border = "1px solid red";
            }
        }
    }

    const checkCurrentPassword = async (password) => {
        const checkCurrentPasswordUrl = global.config.server_url + "checkCurrentPassword.php";
        Axios.post(checkCurrentPasswordUrl, { employee_id: localStorage.getItem("employee_id"), employee_password: password }).then((checkCurrentPasswordResponse) => {
            if (checkCurrentPasswordResponse.data == true) {
                setCurrentPasswordError(false);
                document.getElementById("employee_password").style.border = "1px solid green";

            } else {
                setCurrentPasswordError(true);
                document.getElementById("employee_password").style.border = "1px solid red";
            }
        }).catch(err => console.log(err));
    }

    const showPassword = () => {
        let x = document.getElementById("employee_password");
        let y = document.getElementById("new_password");
        let z = document.getElementById("confirm_password");
        if (x.type === "password") {
            x.type = "text";
            y.type = "text";
            z.type = "text";
        } else {
            x.type = "password";
            y.type = "password";
            z.type = "password";
        }
    }

    const submitChangePassword = async (e) => {
        e.preventDefault();

        context_data.setProcessing(true);

        const submitChangePasswordUrl = global.config.server_url + "changePassword.php";
        const submitChangePasswordData = {
            employee_id: (localStorage.getItem("employee_id")),
            employee_password: e.target.new_password.value
        }
        Axios.post(submitChangePasswordUrl, submitChangePasswordData).then((response) => {
            if (response.data === true) {
                alert("Password Changed");
                context_data.setProcessing(false);

                navigate({ pathname: "/home", state: { login_data: loginData } });
            } else {
                alert("Error in Changing Password");
                context_data.setProcessing(false);

            }
        }).catch(err => { console.log(err); alert("Error in Changing Password"); context_data.setProcessing(false); });

    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 border mt-2 pb-2" >
                        <form autoComplete="off" onSubmit={(e) => submitChangePassword(e)}>
                            <div className="row mt-2">
                                <div className="col-12" >
                                    <span>Employee: <b>{localStorage.getItem("employee_id") + " : " + localStorage.getItem("employee_name")}</b></span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12" >
                                    Old Password *
                                    <input required type="password" name="employee_password" id="employee_password" className="form-control" placeholder="Old Password *" onBlur={(e) => checkCurrentPassword(e.target.value)} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12" >
                                    New Password *
                                    <input required autoComplete="" type="password" name="new_password" id="new_password" className="form-control" minLength="5" placeholder="New Password *" onChange={() => checkNewConfirmPassword()} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12" >
                                    Confirm Password *
                                    <input required type="password" name="confirm_password" id="confirm_password" className="form-control" placeholder="Confirm Password *" onChange={() => checkNewConfirmPassword()} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12" >
                                    <input type="checkbox" onClick={() => showPassword()} /> Show Password
                                </div>
                            </div>
                            {((currentPasswordError == false) && (confirmPasswordError == false)) ? (
                                <div className="row mt-2" >
                                    <div className="col-12  text-center" >
                                        <input type="submit" className="btn btn-primary center" value="Change Password" />
                                    </div>
                                </div>
                            ) : ""}
                        </form>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>

        </>
    )
}

export default ChangePassword;