import React, { useContext } from 'react'
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Form, InputGroup, Modal } from 'react-bootstrap';
var moment = require('moment')

const BookingList = () => {
    const context_data = useContext(ContextData)

    var navigate = useNavigate();
    var location = useLocation();

    const [bookingList, setBookingList] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    const [addIncentiveModal, setAddIncentiveModal] = useState(false);

    

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_LEAD") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getBookingList(login_data.employee_type);

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    async function getBookingList(employee_type) {
        context_data.setProcessing(true)

        if (employee_type == "MASTERUSER") {
            const getBookingListUrl = global.config.server_url + "getBookings.php";
            await axios.post(getBookingListUrl, { all_data: true }).then((getBookingListResponse) => {
                if (getBookingListResponse.data) {
                    setBookingList(getBookingListResponse.data);
                    context_data.setProcessing(false)
                } else {
                    alert("error in getting data")
                    context_data.setProcessing(false)
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        } else if (employee_type == "EMPLOYEE") {
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));

            const getBookingListUrl = global.config.server_url + "getBookings.php";
            await axios.post(getBookingListUrl, { employee_id: login_employee_id }).then((getBookingListResponse) => {
                if (getBookingListResponse.data) {
                    setBookingList(getBookingListResponse.data);
                    context_data.setProcessing(false)
                } else {
                    alert("error in getting data")
                    context_data.setProcessing(false)
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        }

    }

    const incentive_status_value = (params) => {
        var column_name = (params.column.colId)
        var newValue = params.data[column_name]
        if (parseInt(newValue) == true) { return "Approved" } else { return "Pending" }
    }

    const onFilterTextBoxChanged = useCallback(() => {
        context_data.aggridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    const [bookingDetailsModal, setBookingDetailsModal] = useState(false)
    const [bookingDetailsModalData, setBookingDetailsModalData] = useState(false)

    const btnDetailsRenderer = (params) => {
        let details_btn = document.createElement("button");
        details_btn.innerHTML = "Details";
        details_btn.classList = "table_list_btn btn btn-info"
        let url = "/bookingDetails/?id=" + params.data.id;
        details_btn.onclick = function () {
            context_data.setPrevPage(location.pathname)
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        };

        return details_btn;
    }

    const [incentiveValue, setIncentiveValue] = useState(0);

    const incentiveBtnRenderer = (params) => {
        let action_btn = document.createElement("button");
        action_btn.innerHTML = "Update Incentive";
        action_btn.classList = "table_list_btn btn btn-info"
        action_btn.onclick = function () {
            setIncentiveValue(params.data.incentive_value);
            setBookingDetailsModalData(params.data);
        };
        return action_btn;
    }

    const approveBtnRenderer = (params) => {
        let action_btn = document.createElement("button");
        action_btn.innerHTML = "Approve";
        action_btn.classList = "table_list_btn btn btn-info"
        action_btn.onclick = function () {
            approveIncentive(params.data.id)
        };
        if (parseInt(params.data.incentive_status) == true) {
            action_btn.disabled = "disabled";
        }
        if (params.data.incentive_value <= 0) {
            action_btn.disabled = "disabled";
        }
        return action_btn;
    }

    async function approveIncentive(booking_id) {

        var login_employee_id = (localStorage.getItem("employee_id"));
        var login_employee_name = (localStorage.getItem("employee_name"));
        var login_employee_type = (localStorage.getItem("employee_type"));

        var incentive_data = {
            booking_id: booking_id,
            login_employee_id: login_employee_id,
            login_employee_name: login_employee_name,
            login_employee_type: login_employee_type,
        }

        context_data.setProcessing(true)

        const approveIncentiveUrl = global.config.server_url + "approveIncentive.php";
        await axios.post(approveIncentiveUrl, incentive_data).then((approveIncentiveResponse) => {
            if (approveIncentiveResponse.data == true) {
                context_data.setProcessing(false)
                alert("Incentive Approved");
                getBookingList(login_employee_type);
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    async function submitAddIncentive(e) {
        e.preventDefault();

        var booking_id = e.target.booking_id.value;
        var incentive_value = e.target.incentive_value.value;
        var login_employee_id = (localStorage.getItem("employee_id"));
        var login_employee_name = (localStorage.getItem("employee_name"));
        var login_employee_type = (localStorage.getItem("employee_type"));

        var incentive_data = {
            booking_id: booking_id,
            incentive_value: incentive_value,
            login_employee_id: login_employee_id,
            login_employee_name: login_employee_name,
            login_employee_type: login_employee_type,
        }

        context_data.setProcessing(true)

        const updateIncentiveUrl = global.config.server_url + "updateIncentive.php";
        await axios.post(updateIncentiveUrl, incentive_data).then((updateIncentiveResponse) => {
            if (updateIncentiveResponse.data == true) {
                context_data.setProcessing(false)
                alert("Incentive Updated");
                getBookingList(login_employee_type);
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    return (
        <>
            <Modal
                show={addIncentiveModal}
                onHide={() => setAddIncentiveModal(false)}
                keyboard={false}
                centered
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Incentive</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitAddIncentive}>
                        <input type="hidden" name="booking_id" className='form-control' value={bookingDetailsModalData.id} />
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">&#x20B9;</InputGroup.Text>
                            <Form.Control
                                placeholder="Incentive"
                                aria-label="Incentive"
                                aria-describedby="basic-addon1"
                                type="number"
                                name="incentive_value"
                                value={incentiveValue}
                                onChange={(e) => setIncentiveValue(e.target.value)}
                            />
                        </InputGroup>
                        <button type="submit" className='btn btn-success mt-2'>Update</button>
                    </form>
                </Modal.Body>
            </Modal>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Booking List</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                        <h3 className='text-center'>All Bookings</h3>
                    </div>
                    <div className="col-md-4">
                        <input className='form-control' type="text" id="filter-text-box" placeholder="Search..." onInput={onFilterTextBoxChanged} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {(bookingList == false || bookingList == null) ? (<div><h3 className="text-center m-5">No Data Available</h3></div>) : (
                            <>
                                <div className="ag-theme-alpine" style={{ height: "75vh", width: "100%" }}>
                                    <AgGridReact
                                        defaultColDef={context_data.aggridDefaultColDef}
                                        rowData={bookingList}
                                        pagination={true}
                                        paginationAutoPageSize={true}
                                        animateRows={true}
                                        ref={context_data.aggridRef}
                                        onGridReady={() => context_data.aggridAutoSizeAll(false)}
                                        onFirstDataRendered={() => context_data.aggridAutoSizeAll(false)}
                                    >
                                        <AgGridColumn width="100" headerName="Sl No" valueGetter={"node.rowIndex+1"}  ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Lead ID" field="lead_id" ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Name" field="name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Mobile" field="mobile" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                                        {/* <AgGridColumn filter='agTextColumnFilter' headerName="Email" field="email" valueGetter={context_data.aggrid_ucwords_value}></AgGridColumn> */}
                                        {/* <AgGridColumn filter='agTextColumnFilter' headerName="Builder" field="builder_name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn> */}
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Project" field="project_name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Lead Owner" field="lead_owner_name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                                        {/* <AgGridColumn filter='agTextColumnFilter' headerName="Builder Sales Person" field="booked_builder_sales_person_name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn> */}
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Unit Number" field="booked_unit_number" ></AgGridColumn>
                                        {/* <AgGridColumn filter='agTextColumnFilter' headerName="Unit Area" field="booked_unit_area" ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Unit Price" field="booked_unit_price" ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Additional Charges" field="booked_additional_charges" ></AgGridColumn> */}
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Total Price" field="booked_total_price" ></AgGridColumn>
                                        {/* <AgGridColumn filter='agTextColumnFilter' headerName="Status" field="booked_status" valueGetter={context_data.aggrid_ucwords_value}></AgGridColumn> */}
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Advance" field="booked_advance" ></AgGridColumn>
                                        {/* <AgGridColumn filter='agTextColumnFilter' headerName="Mode of Payment" field="booked_mode_of_payment" ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Received By" field="booked_payment_received_by_name" valueGetter={context_data.aggrid_ucwords_value}></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Transaction ID" field="booked_transaction_id" ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Transaction at" field="booked_transaction_datetime" valueGetter={context_data.aggrid_datetime_value}></AgGridColumn> */}
                                        {/* <AgGridColumn filter='agTextColumnFilter' headerName="Created by" field="create_id" ></AgGridColumn> */}
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Created by" field="create_name" valueGetter={context_data.aggrid_ucwords_value}></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Created On" field="create_datetime" valueGetter={context_data.aggrid_datetime_value}></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Incentive" field="incentive_value" ></AgGridColumn>
                                        <AgGridColumn filter='agTextColumnFilter' headerName="Approval Status" field="incentive_status" valueGetter={incentive_status_value}></AgGridColumn>
                                        {/* <AgGridColumn filter='agTextColumnFilter' headerName="Approved By" field="incentive_approved_name" valueGetter={context_data.aggrid_ucwords_value}></AgGridColumn> */}
                                        {/* <AgGridColumn filter='agTextColumnFilter' headerName="Approved On" field="incentive_approved_datetime" valueGetter={context_data.aggrid_datetime_value}></AgGridColumn> */}
                                        <AgGridColumn headerName="Details" cellRenderer={btnDetailsRenderer} ></AgGridColumn>
                                        {/* <AgGridColumn headerName="Update" cellRenderer={incentiveBtnRenderer} ></AgGridColumn> */}
                                        {/* {(loginData.employee_type == "MASTERUSER") && <AgGridColumn headerName="Approve" cellRenderer={approveBtnRenderer} ></AgGridColumn>} */}
                                    </AgGridReact>
                                </div>
                            </>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}

export default BookingList