import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ucwords from 'ucwords';
import { ContextData } from './ContextData';

var moment = require('moment')

const RegisterLeadBuilder = (props) => {

    let [searchParams, setSearchParams] = useSearchParams();
    const context_data = useContext(ContextData)

    useEffect(() => {
        var lead_id = searchParams.get("id");
        setLeadId(lead_id)
    }, [])

    const [leadId, setLeadId] = useState(false)

    const [modalLRWB, setModalLRWB] = useState(false)
    const [clientName, setClientName] = useState("");
    const [clientMobile, setClientMobile] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [projectName, setProjectName] = useState("");
    const [builderName, setBuilderName] = useState("");
    const [builderMails, setBuilderMails] = useState("")

    const openLRWBModal = async () => {

        const getProjectUrl = global.config.server_url + "getProjects.php";
        await axios.post(getProjectUrl, { project_id: props.leadDetails.project_id }).then((ProjectResponse) => {
            if (ProjectResponse.data) {
                var project_data = (ProjectResponse.data);
                setClientName(props.leadDetails.name)
                setClientMobile(props.leadDetails.mobile)
                setClientEmail(props.leadDetails.email)
                setProjectName(project_data.builder_project_name)
                setBuilderName(project_data.builder_name)
                setBuilderMails(project_data.builder_mail_list)
                setModalLRWB(true)
            }
        }).catch(err => console.log(err));
    }

    const submitRegisterLeadBUilder = async (e) => {
        e.preventDefault();

        const addLRWBUrl = global.config.server_url + "addLeadRegistrationWithBuilder.php";
        let notesData = "Lead Registered with Builder";
        notesData = notesData.replaceAll("'", "");
        notesData = notesData.replaceAll('"', '');

        const addLRWBData = {
            notes_data: notesData,
            tentative_sitevisit_date: moment(e.target.date_sitevisit.value, "YYYY-MM-DD", true).format("DD-MMM-YYYY"),
            client_name: ucwords(clientName),
            client_mobile: clientMobile,
            client_email: clientEmail ? clientEmail.toLowerCase() : "",
            project_name: ucwords(projectName),
            builder_name: ucwords(builderName),
            builder_emails: builderMails ? builderMails : e.target.builder_emails.value,
            login_employee_id: localStorage.getItem("employee_id"),
            login_employee_name: localStorage.getItem("employee_name"),
            login_employee_email: localStorage.getItem("employee_email"),
            id: (leadId != undefined || leadId != null) ? leadId : searchParams.get("id")
        }

        context_data.setProcessing(true);

        await axios.post(addLRWBUrl, addLRWBData).then((response) => {
            if (response.data == true) {
                context_data.setProcessing(false);
                props.getLeadDetails();
                setModalLRWB(false)
            } else {
                alert("Error in sending Data");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Adding Data"); context_data.setProcessing(false); });
    }

    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalLRWB}
                onHide={() => setModalLRWB(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Confirm Details
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={submitRegisterLeadBUilder}>
                    <Modal.Body>
                        <table className="table table-striped table-bordered">
                            <tbody>
                                <tr><td>Client Name</td><td>{context_data.ucwords_value(clientName)}</td></tr>
                                <tr><td>Client Mobile</td><td>{clientMobile}</td></tr>
                                <tr><td>Client Email</td><td>{clientEmail}</td></tr>
                                <tr><td>Project Name</td><td>{context_data.ucwords_value(projectName)}</td></tr>
                                <tr><td>Builder Name</td><td>{context_data.ucwords_value(builderName)}</td></tr>
                                <tr><td>Builder Email</td><td>{(builderMails) ? builderMails : <input type="text" name="builder_emails" id="" className="form-control" required />}</td></tr>
                                <tr><td>Tentative Sitevisit Date</td><td><input type="date" name="date_sitevisit" className='form-control' id="" min={moment().format("YYYY-MM-DD")} defaultValue={moment().format("YYYY-MM-DD")} required /></td></tr>
                            </tbody>

                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalLRWB(false)}>Close</Button>
                        <Button type="submit">Submit</Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <input type="button" className="m-2" value="Reg. Lead with Builder" onClick={(e) => { openLRWBModal(e); }} />
        </>
    )
}

export default RegisterLeadBuilder