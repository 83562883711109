import React, { useContext } from 'react'
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import ucwords from 'ucwords';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Button, FloatingLabel, Form, InputGroup, Modal } from 'react-bootstrap';
var moment = require('moment')

const BookingDetails = () => {
    const context_data = useContext(ContextData)


    var navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const [bookingDetails, setBookingDetails] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    const [updateIncentivePaymentModal, setUpdateIncentivePaymentModal] = useState(false);
    const [employeesData, setEmployeesData] = useState([])

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_LEAD") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }

            var booking_id = searchParams.get("id");
            getBookingDetails(booking_id);
            getAllEmployees();
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    const getAllEmployees = async () => {
        context_data.setProcessing(true)
        const getAllEmployeesUrl = global.config.server_url + "getEmployees.php";
        await axios.post(getAllEmployeesUrl, { masteruser: true }).then((allEmployeesResponse) => {
            if (allEmployeesResponse.data.length > 0) {
                setEmployeesData(allEmployeesResponse.data);
                context_data.setProcessing(false)
            } else {
                setEmployeesData(false);
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); setEmployeesData(false); context_data.setProcessing(false) });
    }

    async function getBookingDetails(booking_id) {
        context_data.setProcessing(true)

        const getBookingDetailsUrl = global.config.server_url + "getBookings.php";
        await axios.post(getBookingDetailsUrl, { booking_id: booking_id }).then((getBookingDetailsResponse) => {
            if (getBookingDetailsResponse.data) {
                setBookingDetails(getBookingDetailsResponse.data);
                getIncentiveActivity(booking_id)
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const [incentiveActivity, setIncentiveActivity] = useState(false);

    async function getIncentiveActivity(booking_id) {
        context_data.setProcessing(true)

        const getIncentiveActivityUrl = global.config.server_url + "getIncentiveActivity.php";
        await axios.post(getIncentiveActivityUrl, { booking_id: booking_id }).then((getIncentiveActivityResponse) => {
            if (getIncentiveActivityResponse.data) {
                setIncentiveActivity(getIncentiveActivityResponse.data);
                context_data.setProcessing(false)
            } else {
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }


    const swipe = (src) => {
        let url = src;
        window.open(url, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
    }

    function indian_number_format(data) {
        if (data) {
            var number = parseFloat(data)
            return number.toLocaleString('en-IN', {
                maximumFractionDigits: 0,
                style: "currency", currency: "INR"
            });
        } else {
            return "-"
        }
    }

    const [addIncentiveModal, setAddIncentiveModal] = useState(false)
    const [incentiveValue, setIncentiveValue] = useState(0);

    async function approveIncentive(booking_id) {

        var login_employee_id = (localStorage.getItem("employee_id"));
        var login_employee_name = (localStorage.getItem("employee_name"));
        var login_employee_type = (localStorage.getItem("employee_type"));

        var incentive_data = {
            booking_id: booking_id,
            login_employee_id: login_employee_id,
            login_employee_name: login_employee_name,
            login_employee_type: login_employee_type,
        }

        context_data.setProcessing(true)

        const approveIncentiveUrl = global.config.server_url + "approveIncentive.php";
        await axios.post(approveIncentiveUrl, incentive_data).then((approveIncentiveResponse) => {
            if (approveIncentiveResponse.data == true) {
                context_data.setProcessing(false)
                alert("Incentive Approved");
                setAddIncentiveModal(false)
                getBookingDetails(booking_id);
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    async function submitAddIncentive(e) {
        e.preventDefault();

        var booking_id = e.target.booking_id.value;
        var incentive_value = e.target.incentive_value.value;
        var login_employee_id = (localStorage.getItem("employee_id"));
        var login_employee_name = (localStorage.getItem("employee_name"));
        var login_employee_type = (localStorage.getItem("employee_type"));

        var incentive_data = {
            booking_id: booking_id,
            incentive_value: incentive_value,
            login_employee_id: login_employee_id,
            login_employee_name: login_employee_name,
            login_employee_type: login_employee_type,
        }

        context_data.setProcessing(true)

        const updateIncentiveUrl = global.config.server_url + "updateIncentive.php";
        await axios.post(updateIncentiveUrl, incentive_data).then((updateIncentiveResponse) => {
            if (updateIncentiveResponse.data == true) {
                context_data.setProcessing(false)
                alert("Incentive Updated");
                setAddIncentiveModal(false)
                getBookingDetails(booking_id);
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const [paidAmount, setPaidAmount] = useState("");
    const [pendingAmount, setPendingAmount] = useState(0);
    const [selectedPayment, setSelectedPayment] = useState("paid");
    const [paymentDate, setPaymentDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [currentDate, setCurrentDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [paidBy, setPaidBy] = useState("");
    const [modeOfPayment, setModeOfPayment] = useState("cash");

    function handlePaymentChange(e) {
        var og_pending_amount = bookingDetails.incentive_pending_amount ? bookingDetails.incentive_pending_amount : 0;
        setPaidAmount("");
        setPendingAmount(og_pending_amount);
        setSelectedPayment(e.target.value);
    }

    function handlePaidAmountChange(e) {
        var paid_amount = parseFloat(e.target.value);
        paid_amount = Math.round(paid_amount * 100) / 100;

        var og_pending_amount = bookingDetails.incentive_pending_amount ? bookingDetails.incentive_pending_amount : 0;

        if (paid_amount >= og_pending_amount) {
            alert("Paid amount should be less than pending amount")
        } else {
            var pending_amount = og_pending_amount - paid_amount;
            pending_amount = Math.round(pending_amount * 100) / 100
            setPendingAmount(pending_amount)
            setPaidAmount(paid_amount)
        }
    }

    async function submitUpdateIncentivePayment(e) {
        e.preventDefault();
        context_data.setProcessing(true);

        var booking_id = bookingDetails.id;
        var notes = e.target.notes ? e.target.notes.value : "";
        var payment_status = e.target.payment_status ? e.target.payment_status.value : "";
        var payment_date = e.target.payment_date ? e.target.payment_date.value : "";
        var paid_amount = e.target.paid_amount ? e.target.paid_amount.value : "";
        if (payment_status == "paid") {
            paid_amount = bookingDetails.incentive_pending_amount;
        }

        var pending_amount = e.target.pending_amount ? e.target.pending_amount.value : 0;
        var mode_of_payment = e.target.mode_of_payment ? e.target.mode_of_payment.value : "";
        var transaction_id = e.target.transaction_id ? e.target.transaction_id.value : "";

        var paid_by = e.target.paid_by ? e.target.paid_by.value : false
        var paid_by_data = paid_by ? (employeesData.find(obj => (obj.employee_id == paid_by))) : ""

        var updateIncentivePaymentData = {
            booking_id: booking_id,
            notes: notes,
            payment_status: payment_status,
            payment_date: payment_date,
            paid_amount: paid_amount,
            pending_amount: pending_amount,
            paid_by_id: paid_by_data ? paid_by_data.employee_id : "",
            paid_by_name: paid_by_data ? paid_by_data.employee_name : "",
            mode_of_payment: mode_of_payment,
            transaction_id: transaction_id,
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
        }

        var updateIncentivePaymentUrl = global.config.server_url + "updateIncentivePayment.php";
        await axios.post(updateIncentivePaymentUrl, updateIncentivePaymentData).then((response) => {
            if (response.data === true) {
                context_data.setProcessing(false);
                setUpdateIncentivePaymentModal(false);
                getBookingDetails(booking_id);
            } else {
                alert("Error in Updating Incentive Payment");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Updating Incentive Payment!!"); context_data.setProcessing(false); });
    }

    return (
        <>
            <Modal
                show={updateIncentivePaymentModal}
                onHide={() => setUpdateIncentivePaymentModal(false)}
                keyboard={false}
                centered
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Payment</Modal.Title>
                </Modal.Header>
                <form onSubmit={submitUpdateIncentivePayment}>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 my-2">
                                    <FloatingLabel controlId="floatingNotes" label="Notes">
                                        <Form.Control type="text" placeholder="Notes" required name="notes" />
                                    </FloatingLabel>
                                </div>
                                <div className="col-md-6 my-2">
                                    <FloatingLabel controlId="floatingPayment" label="Payment Status">
                                        <Form.Select name="payment_status" id="select_payment" required value={selectedPayment} onChange={handlePaymentChange} >
                                            <option value="paid">Paid</option>
                                            <option value="partial">Partially Paid</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>
                                {(selectedPayment == "paid" || selectedPayment == "partial") && (
                                    <>
                                        <div className="col-md-6 my-2">
                                            <FloatingLabel controlId="floatingPaymentDate" label="Payment Date">
                                                <Form.Control type="date" placeholder="Payment Date" required name="payment_date" value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} max={currentDate} />
                                            </FloatingLabel>
                                        </div>
                                    </>
                                )}
                                {(selectedPayment == "partial") && (
                                    <>
                                        <div className="col-md-6 my-2">
                                            <FloatingLabel controlId="floatingPaidAmount" label="Paid Amount">
                                                <Form.Control type="number" placeholder="Paid Amount" min="1" required name="paid_amount" value={paidAmount} onChange={handlePaidAmountChange} />
                                            </FloatingLabel>
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <FloatingLabel controlId="floatingPendingAmount" label="Pending Amount">
                                                <Form.Control type="number" placeholder="Pending Amount" readOnly required name="pending_amount" value={pendingAmount} onChange={(e) => setPendingAmount(e.target.value)} />
                                            </FloatingLabel>
                                        </div>
                                    </>
                                )}
                                {(selectedPayment == "paid" || selectedPayment == "partial") && (
                                    <>
                                        {employeesData && (
                                            <div className="col-md-6 my-2">
                                                <FloatingLabel controlId="floatingPaidBy" label="Paid By">
                                                    <Form.Select name="paid_by" id="select_paid_by" required value={paidBy} onChange={(e) => setPaidBy(e.target.value)}>
                                                        <option value="" disabled selected>Select Employee</option>
                                                        {employeesData.map((employee) => <option value={employee.employee_id}>{ucwords(employee.employee_name.toLowerCase())}</option>)}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </div>
                                        )}
                                        <div className="col-md-6 my-2">
                                            <FloatingLabel controlId="floatingModeOfPayment" label="Mode of Payment">
                                                <Form.Select name="mode_of_payment" id="select_mode_of_payment" required value={modeOfPayment} onChange={(e) => setModeOfPayment(e.target.value)}>
                                                    <option value="cash" selected>Cash</option>
                                                    <option value="cheque" selected>Cheque</option>
                                                    <option value="credit card" selected>Credit Card</option>
                                                    <option value="debit card" selected>Debit Card</option>
                                                    <option value="upi" selected>UPI</option>
                                                    <option value="imps" selected>IMPS</option>
                                                    <option value="neft" selected>NEFT</option>
                                                    <option value="rtgs" selected>RTGS</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                        {modeOfPayment != "cash" &&
                                            <div className="col-md-6 my-2">
                                                <FloatingLabel controlId="TransactionID" label="Transaction ID">
                                                    <Form.Control type="text" placeholder="Transaction ID" required name="transaction_id" />
                                                </FloatingLabel>
                                            </div>
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="updateLeadModalFooter">
                        <Button variant="primary" type="submit" >Submit</Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal
                show={addIncentiveModal}
                onHide={() => setAddIncentiveModal(false)}
                keyboard={false}
                centered
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Incentive</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitAddIncentive}>
                        <input type="hidden" name="booking_id" className='form-control' value={bookingDetails.id} />
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">&#x20B9;</InputGroup.Text>
                            <Form.Control
                                placeholder="Incentive"
                                aria-label="Incentive"
                                aria-describedby="basic-addon1"
                                type="number"
                                name="incentive_value"
                                value={incentiveValue}
                                onChange={(e) => setIncentiveValue(e.target.value)}
                            />
                        </InputGroup>
                        <button type="submit" className='btn btn-success mt-2'>Update</button>
                    </form>
                </Modal.Body>
            </Modal>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate("/allBookings")}>Booking List</Breadcrumb.Item>
                            <Breadcrumb.Item active>Booking Details</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <section className="section_booking_details">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {(bookingDetails == false || bookingDetails == null) ? (<div><h3 className="text-center m-5">No Data Available</h3></div>) : (
                                <>
                                    <table className="table table_booking_details">
                                        <tbody>
                                            <tr className="tr_booking_details">
                                                <td className="td_booking_details td_booking_details_head">Lead Id</td>
                                                <td className="td_booking_details">{bookingDetails.lead_id}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Name</td>
                                                <td className="td_booking_details">{context_data.ucwords_value(bookingDetails.name)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Mobile</td>
                                                <td className="td_booking_details">{bookingDetails.mobile}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Email</td>
                                                <td className="td_booking_details">{context_data.lowercase_value(bookingDetails.email)}</td>
                                            </tr>
                                            <tr className="tr_booking_details">
                                                <td className="td_booking_details td_booking_details_head">Builder Name</td>
                                                <td className="td_booking_details">{context_data.ucwords_value(bookingDetails.builder_name)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Project Name</td>
                                                <td className="td_booking_details">{context_data.ucwords_value(bookingDetails.project_name)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Lead Owner</td>
                                                <td className="td_booking_details">{context_data.employee_value(bookingDetails.lead_owner_id, bookingDetails.lead_owner_name)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Created By</td>
                                                <td className="td_booking_details">{context_data.employee_value(bookingDetails.create_id, bookingDetails.create_name)}</td>
                                            </tr>
                                            <tr className="tr_booking_details">
                                                <td className="td_booking_details td_booking_details_head">Create at</td>
                                                <td className="td_booking_details">{context_data.datetime_value(bookingDetails.create_datetime)}</td>
                                            </tr>
                                            <tr className='tr_blank'></tr>
                                            <tr className="tr_booking_details">
                                                <td className="td_booking_details td_booking_details_head">Booked Builder Sales Person Name</td>
                                                <td className="td_booking_details">{context_data.ucwords_value(bookingDetails.booked_builder_sales_person_name)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Booked Unit Number</td>
                                                <td className="td_booking_details">{context_data.uppercase_value(bookingDetails.booked_unit_number)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Booked Unit Area</td>
                                                <td className="td_booking_details">{bookingDetails.booked_unit_area}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Booked Unit Price</td>
                                                <td className="td_booking_details">{indian_number_format(bookingDetails.booked_unit_price)}</td>
                                            </tr>
                                            <tr className="tr_booking_details">
                                                <td className="td_booking_details td_booking_details_head">Booked Additional Charges</td>
                                                <td className="td_booking_details">{indian_number_format(bookingDetails.booked_additional_charges)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Booked Total Price</td>
                                                <td className="td_booking_details">{indian_number_format(bookingDetails.booked_total_price)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Booked Image</td>
                                                <td className="td_booking_details">
                                                    {(((bookingDetails.booked_image).trim().split(",")).map((img) => <img className='img_table_activity_list' src={'/uploads/' + img} onClick={(e) => swipe(e.target.src)} />))}
                                                </td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Booked Status</td>
                                                <td className="td_booking_details">{context_data.ucwords_value(bookingDetails.booked_status)}</td>
                                            </tr>
                                            <tr className="tr_booking_details">
                                                <td className="td_booking_details td_booking_details_head">Booked Advance</td>
                                                <td className="td_booking_details">{indian_number_format(bookingDetails.booked_advance)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Booked Mode Of Payment</td>
                                                <td className="td_booking_details">{context_data.ucwords_value(bookingDetails.booked_mode_of_payment)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Booked Payment Received By</td>
                                                <td className="td_booking_details">{context_data.ucwords_value(bookingDetails.booked_payment_received_by_name)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Booked Transaction at</td>
                                                <td className="td_booking_details">{context_data.datetime_value(bookingDetails.booked_transaction_datetime)}</td>
                                            </tr>
                                            <tr className="tr_booking_details">
                                                <td className="td_booking_details td_booking_details_head">Booked Transaction Id</td>
                                                <td className="td_booking_details">{bookingDetails.booked_transaction_id ? bookingDetails.booked_transaction_id : "-"}</td>
                                            </tr>
                                            <tr className='tr_blank'></tr>
                                            {bookingDetails.cancelled_status &&
                                                <tr className="tr_booking_details">
                                                    <td className="td_booking_details td_booking_details_head">Cancelled Status</td>
                                                    <td className="td_booking_details">{context_data.ucwords_value(bookingDetails.cancelled_status)}</td>
                                                    <td className="td_blank"></td>
                                                    <td className="td_booking_details td_booking_details_head">Cancelled By</td>
                                                    <td className="td_booking_details">{context_data.employee_value(bookingDetails.cancelled_id, bookingDetails.cancelled_name)}</td>
                                                    <td className="td_blank"></td>
                                                    <td className="td_booking_details td_booking_details_head">Cancelled Reason</td>
                                                    <td className="td_booking_details">{context_data.ucwords_value(bookingDetails.cancelled_reason)}</td>
                                                    <td className="td_blank"></td>
                                                    <td className="td_booking_details td_booking_details_head">Cancelled at</td>
                                                    <td className="td_booking_details">{context_data.datetime_value(bookingDetails.cancelled_datetime)}</td>
                                                </tr>
                                            }
                                            <tr className='tr_blank'></tr>
                                            <tr className="tr_booking_details">
                                                <td className="td_booking_details td_booking_details_head">Incentive Value</td>
                                                <td className="td_booking_details">{indian_number_format(bookingDetails.incentive_value)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Incentive Status</td>
                                                <td className="td_booking_details">{parseInt(bookingDetails.incentive_status) ? "Approved" : "Pending"}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Incentive Approved Name</td>
                                                <td className="td_booking_details">{context_data.ucwords_value(bookingDetails.incentive_approved_name)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Incentive Approved at</td>
                                                <td className="td_booking_details">{context_data.datetime_value(bookingDetails.incentive_approved_datetime)}</td>
                                            </tr>
                                            <tr className="tr_booking_details">
                                                <td className="td_booking_details td_booking_details_head">Incentive Payment Status</td>
                                                <td className="td_booking_details">{context_data.uppercase_value(bookingDetails.incentive_payment_status)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Incentive Paid Amount</td>
                                                <td className="td_booking_details">{indian_number_format(bookingDetails.incentive_paid_amount)}</td>
                                                <td className="td_blank"></td>
                                                <td className="td_booking_details td_booking_details_head">Incentive Pending Amount</td>
                                                <td className="td_booking_details">{indian_number_format(bookingDetails.incentive_pending_amount)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div>
                                        {(localStorage.getItem("employee_type").toUpperCase() == "EMPLOYEE" && parseInt(bookingDetails.incentive_value) > 0) ? ""
                                            : <button className='btn btn-warning ms-2' onClick={() => { setAddIncentiveModal(true); setIncentiveValue(bookingDetails.incentive_value); }} >Update Incentive</button>
                                        }
                                        {localStorage.getItem("employee_type").toUpperCase() == "MASTERUSER" && <>
                                            <button className='btn btn-success ms-2' onClick={() => approveIncentive(bookingDetails.id)} disabled={(parseInt(bookingDetails.incentive_status) == true || bookingDetails.incentive_value <= 0) ? true : false}>Approve Incentive</button>
                                            <button className='btn btn-primary ms-2' onClick={() => { setUpdateIncentivePaymentModal(true); }} disabled={(parseInt(bookingDetails.incentive_pending_amount) <= 0) ? true : false}>Update Payment</button>
                                        </>}

                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                    {incentiveActivity &&
                        <div className="row">
                            <div className="col-12">
                                <table className='table table-borderd'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <td>Sl</td>
                                            <td>Notes</td>
                                            <td>Paid Amount</td>
                                            <td>Pending Amount</td>
                                            <td>Paid By</td>
                                            <td>Payment Date</td>
                                            <td>Mode of Payment</td>
                                            <td>Transaction ID</td>
                                            <td>Created by</td>
                                            <td>Created at</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {incentiveActivity.map((incentive, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{incentive.notes}</td>
                                                {/* <td>{ucwords(incentive.payment_status)}</td> */}
                                                <td>{indian_number_format(incentive.paid_amount)}</td>
                                                <td>{indian_number_format(incentive.pending_amount)}</td>
                                                <td>{context_data.employee_value(incentive.paid_by_id, incentive.paid_by_name)}</td>
                                                <td>{context_data.date_value(incentive.paid_by_date)}</td>
                                                <td>{incentive.mode_of_payment ? ucwords(incentive.mode_of_payment) : "-"}</td>
                                                <td>{incentive.transaction_id ? incentive.transaction_id : "-"}</td>
                                                <td>{context_data.employee_value(incentive.create_id, incentive.create_name)}</td>
                                                <td>{context_data.datetime_value(incentive.create_datetime)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                </div >
            </section>
        </>
    )
}

export default BookingDetails