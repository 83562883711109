import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import ucwords from 'ucwords';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { Autocomplete, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import ReminderDetails from './Components/ReminderDetails';
import { ContextData } from './Components/ContextData';
import { Form } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require("moment")

const LeadAdd = (props) => {

    const context_data = useContext(ContextData)
    var location = useLocation();

    var navigate = useNavigate();

    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    const [employeeRightsArray, setEmployeeRightsArray] = useState([])

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                setEmployeeRightsArray(employee_rights_arr)
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_LEAD") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(employee_rights_arr)
            if (isAvailable != true) { navigate("/noAccess") }
            getProjectList(login_data.employee_type);

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])


    const [selectedProject, setSelectedProject] = useState(false);
    const [employeeValue, setEmployeeValue] = useState({ employee_name: "" });
    const [propertyTypeValue, setPropertyTypeValue] = useState(false);
    const [occupationValue, setOccupationValue] = useState(false);

    const [isPendingProject, setIsPendingProject] = useState(true);
    const [projectData, setProjectData] = useState(null);
    const [reminderValue, setReminderValue] = useState("Followup")
    const [dateDOBvalue, setDateDOBvalue] = useState(null)
    const [whatsappCheckbox, setWhatsappCheckbox] = useState(true);

    const getProjectList = (employee_type) => {
        if (employee_type == "MASTERUSER") {
            const getProjectListUrl = global.config.server_url + "getProjects.php";
            axios.post(getProjectListUrl, { all_data_active: true }).then((projectListResponse) => {
                setProjectData(null);
                if (projectListResponse.data.length > 0) {
                    setProjectData(projectListResponse.data);
                    setIsPendingProject(false);
                } else {
                    setProjectData(false);
                }
            }).catch(err => { console.log(err); setProjectData(false); });
        } else if (employee_type == "EMPLOYEE") {
            const getProjectListUrl = global.config.server_url + "getProjectsFromProjectEmployees.php";
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            axios.post(getProjectListUrl, { employee_id: login_employee_id }).then((projectListResponse) => {
                setProjectData(null);
                if (projectListResponse.data.length > 0) {
                    setProjectData(projectListResponse.data);
                    setIsPendingProject(false);
                } else {
                    setProjectData(false);
                }
            }).catch(err => { console.log(err); setProjectData(false); });
        }
    }

    const [employeesData, setEmployeesData] = useState([]);
    const [isPendingEmployees, setIsPendingEmployees] = useState(true);

    const getEmployeesList = (selected_project) => {
        setEmployeesData(false)
        setEmployeeValue(false)
        if ((localStorage.getItem("employee_type") == "MASTERUSER") && selected_project) {
            var project_id = selected_project.builder_project_id;
            const getEmployeesListUrl = global.config.server_url + "getEmployeesFromProjectEmployees.php";
            axios.post(getEmployeesListUrl, { project_id: project_id }).then((employeesListResponse) => {
                if (employeesListResponse.data.length > 0) {
                    setEmployeesData(employeesListResponse.data);
                    setIsPendingEmployees(false);
                } else {
                    setEmployeesData([]);
                }
            }).catch(err => { console.log(err); setEmployeesData([]); });
        } else {
            var emp_data = (JSON.parse(localStorage.getItem("login_data")))
            setEmployeeValue(emp_data)
        }
    }

    const submitAddLead = (e) => {
        e.preventDefault();

        if (selectedProject) {

            context_data.setProcessing(true);

            var salutation = e.target.salutation ? e.target.salutation.value : ""
            var name = e.target.name ? e.target.name.value : ""
            var mobile = e.target.mobile ? e.target.mobile.value : ""
            var secondary_mobile = e.target.secondary_mobile ? e.target.secondary_mobile.value : ""
            var email = e.target.email ? e.target.email.value : ""
            var secondary_email = e.target.secondary_email ? e.target.secondary_email.value : ""
            var whatsapp = whatsappCheckbox ? e.target.mobile.value : e.target.whatsapp.value
            var date_of_birth = e.target.date_of_birth ? e.target.date_of_birth.value : ""
            var lead_source = e.target.lead_source ? e.target.lead_source.value : ""
            var builder_id = selectedProject.builder_id;
            var builder_name = selectedProject.builder_name;
            var project_id = selectedProject.builder_project_id;
            var project_name = selectedProject.builder_project_name;
            var project_sale_type = selectedProject.sale_type;
            var occupation = e.target.occupation ? e.target.occupation.value : ""
            var company = e.target.company ? e.target.company.value : ""
            var designation = e.target.designation ? e.target.designation.value : ""
            var industry = e.target.industry ? e.target.industry.value : ""

            var income = e.target.income ? e.target.income.value : ""
            var min_budget = e.target.min_budget ? e.target.min_budget.value : ""
            var max_budget = e.target.max_budget ? e.target.max_budget.value : ""
            var location = e.target.location ? e.target.location.value : ""
            var area = e.target.area ? e.target.area.value : ""
            var possession_duration = e.target.possession_duration ? e.target.possession_duration.value : ""
            var property_type = e.target.property_type ? e.target.property_type.value : ""
            var plot_size = e.target.plot_size ? e.target.plot_size.value : ""
            var bedroom_preference = e.target.bedroom_preference ? e.target.bedroom_preference.value : ""
            var min_carpet_area = e.target.min_carpet_area ? e.target.min_carpet_area.value : ""
            var max_carpet_area = e.target.max_carpet_area ? e.target.max_carpet_area.value : ""
            var purpose = e.target.purpose ? e.target.purpose.value : ""
            var reminder_type = e.target.reminder_type ? e.target.reminder_type.value : ""
            var notes = e.target.notes ? e.target.notes.value : ""
            var reminder = context_data.reminderType ? context_data.reminderType : reminder;
            var sitevisit_confirm = reminder_type.toLowerCase() == "sitevisit" ? e.target.sitevisit_confirm.value : "";

            var employee_id = employeeValue.employee_id;
            var employee_name = employeeValue.employee_name;
            var employee_mobile = employeeValue.employee_mobile;
            var employee_email = employeeValue.employee_email;

            var leadData = {
                salutation: salutation,
                name: name,
                mobile: mobile,
                secondary_mobile: secondary_mobile,
                email: email,
                secondary_email: secondary_email,
                whatsapp: whatsapp,
                date_of_birth: date_of_birth,
                lead_source: lead_source,
                builder_id: builder_id,
                builder_name: builder_name,
                project_id: project_id,
                project_name: project_name,
                project_sale_type: project_sale_type,
                occupation: occupation,
                company: company,
                designation: designation,
                industry: industry,
                income: income,
                min_budget: min_budget,
                max_budget: max_budget,
                location: location,
                area: area,
                possession_duration: possession_duration,
                property_type: property_type,
                plot_size: plot_size,
                bedroom_preference: bedroom_preference,
                min_carpet_area: min_carpet_area,
                max_carpet_area: max_carpet_area,
                purpose: purpose,
                reminder_type: reminder_type,
                notes_data: notes,
                reminder: reminder,
                sitevisit_confirm: sitevisit_confirm,

                lead_owner_id: employee_id,
                lead_owner_name: employee_name,
                lead_owner_mobile: employee_mobile,
                lead_owner_email: employee_email,

                login_employee_id: localStorage.getItem("employee_id") ? localStorage.getItem("employee_id").toUpperCase() : "",
                login_employee_name: localStorage.getItem("employee_name") ? localStorage.getItem("employee_name").toUpperCase() : "",
            }

            const submitAddLeadUrl = global.config.server_url + "addLead.php";

            axios.post(submitAddLeadUrl, leadData).then((submitAddLeadResponse) => {
                if (submitAddLeadResponse.data.result === true) {
                    alert("Lead Added");
                    if (reminder_type.toLowerCase() == "followup") {
                        submitAddFollowup(e, submitAddLeadResponse.data.id);
                    } else if (reminder_type.toLowerCase() == "sitevisit") {
                        submitAddSitevisit(e, submitAddLeadResponse.data.id);
                    } else {
                        navigate("/allLeads");
                    }
                    context_data.setProcessing(false);
                } else if (submitAddLeadResponse.data.result === false) {
                    alert("Error in Adding Lead")
                } else {
                    if (typeof submitAddLeadResponse.data == "object") {
                        alert("Lead is assigned to " + submitAddLeadResponse.data[1] + " : " + submitAddLeadResponse.data[2]);
                        context_data.setProcessing(false);

                    } else {
                        alert("Error in Adding Lead");
                        context_data.setProcessing(false);
                    }
                }
            }).catch(err => {
                console.log(err); alert("There was a error."); context_data.setProcessing(false);
            });
        } else {
            alert("Project Not Selected")
        }

    }

    function submitAddFollowup(e, id) {
        e.preventDefault()
        var notes = e.target.notes.value;
        var reminder_datetime = context_data.pickerDatetime;
        reminder_datetime.setSeconds(0);
        var id = id;

        context_data.setProcessing(true);

        const addReminderData = {
            notes_data: notes,
            reminder_datetime: reminder_datetime,
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
            id: id
        };

        var addReminderUrl = global.config.server_url + "addFollowUp.php";

        axios.post(addReminderUrl, addReminderData).then((response) => {
            if (response.data == true) {
                context_data.setPrevPage(location.pathname)
                context_data.setProcessing(false);
                navigate({ pathname: "/leadDetails/", search: 'id=' + id, state: { id: id } })
            } else {
                alert("Error in Creating Reminder");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Adding Reminder"); context_data.setProcessing(false); });

    }

    function submitAddSitevisit(e, id) {
        e.preventDefault()
        var notes = e.target.notes.value;
        var reminder_datetime = context_data.pickerDatetime;
        reminder_datetime.setSeconds(0);
        var id = id;

        context_data.setProcessing(true);

        const addReminderData = {
            notes_data: notes,
            reminder_datetime: reminder_datetime,
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
            id: id
        };

        var addReminderUrl = global.config.server_url + "addSiteVisit.php";

        axios.post(addReminderUrl, addReminderData).then((response) => {
            if (response.data == true) {
                context_data.setPrevPage(location.pathname)
                context_data.setProcessing(false);
                navigate({ pathname: "/leadDetails/", search: 'id=' + id, state: { id: id } })
            } else {
                alert("Error in Creating Reminder");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Adding Reminder"); context_data.setProcessing(false); });

    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Add Lead</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 className='text-center'>Basic Lead Profile</h3>
                    </div>
                </div>
                <form onSubmit={submitAddLead}>
                    <div className="row ">
                        <div className="col-md-6">
                            <fieldset className='fieldset_info'>
                                <legend className='fieldset_info_legend'>Basic Information</legend>
                                <div className="row">

                                    <div className="col-md-6 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={["Mr", "Ms", "Mrs", "Dr", "Prof", "Col"]}
                                            renderInput={(params) => <TextField {...params} label="Salutation" name="salutation" required />}
                                        />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <TextField fullWidth label="Name" name="name" required />
                                    </div>
                                    {/* <div className="col-md-6 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={global.config.countries}
                                            renderInput={(params) => <TextField {...params} label="Salutation" name="salutation" required />}
                                        />
                                    </div> */}
                                    <div className="col-md-6 my-2">
                                        {/* <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="Mobile" name="mobile" required /> */}
                                        <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="Mobile" name="mobile" required />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="Secondary Mobile" name="secondary_mobile" />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <TextField fullWidth label="Email" name="email" />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <TextField fullWidth label="Secondary Email" name="secondary_email" />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <Form.Check type="switch" id="custom-switch" label="Whatsapp" checked={whatsappCheckbox ? "checked" : ""} onChange={(e) => setWhatsappCheckbox(!whatsappCheckbox)} />
                                        <span>{whatsappCheckbox ? "Same as Mobile" : "Enter Whatsapp Number"}</span>
                                    </div>
                                    {whatsappCheckbox ? "" :
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="Whatsapp" name="whatsapp" required />
                                        </div>
                                    }
                                    <div className="col-md-6 my-2">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <MobileDatePicker
                                                    label="Date of Birth"
                                                    value={dateDOBvalue}
                                                    inputFormat="dd-MMM-yyyy"
                                                    onChange={(newValue) => { setDateDOBvalue(newValue); }}
                                                    renderInput={(params) => <TextField {...params} name="date_of_birth" />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={global.config.lead_source_list}
                                            renderInput={(params) => <TextField {...params} label="Lead Source" name="lead_source" required />}
                                        />
                                    </div>

                                    <div className="col-md-6 my-2">
                                        {projectData && (<>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={projectData}
                                                onChange={(event, newValue) => {
                                                    setSelectedProject(newValue);
                                                    getEmployeesList(newValue);
                                                }}
                                                getOptionLabel={(option) => ucwords((option.builder_project_name.toString()).toLowerCase())}
                                                renderInput={(params) => <TextField {...params} label="Project" name="project" required />}
                                            />
                                        </>)}
                                    </div>

                                    <div className="col-md-6 my-2">
                                        {(loginData.employee_type == "MASTERUSER" || employeeRightsArray.includes("REASSIGN_LEADS")) && (employeesData) && (<>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={employeesData}
                                                onChange={(event, newValue) => { setEmployeeValue(newValue); }}
                                                getOptionLabel={(option) => ucwords((option.employee_name.toString()).toLowerCase())}
                                                renderInput={(params) => <TextField {...params} label="Employee" name="employee" required />}
                                            />
                                        </>)}
                                    </div>

                                </div>
                            </fieldset>

                        </div>
                        <div className="col-md-6">
                            <fieldset className='fieldset_info'>
                                <legend className='fieldset_info_legend'>Additional Information</legend>

                                <div className="row">
                                    <div className="col-md-6 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={global.config.location_list}
                                            renderInput={(params) => <TextField {...params} label="Location" name="location" />}
                                        />
                                    </div>

                                    <div className="col-md-6 my-2">
                                        <TextField fullWidth label="Area" name="area" />
                                    </div>

                                    <div className="col-md-6 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            onChange={(event, newValue) => { setOccupationValue(newValue); }}
                                            options={["Retired", "Salaried", "Business / Self Employed"]}
                                            renderInput={(params) => <TextField {...params} label="Occupation" name="occupation" />}
                                        />
                                    </div>


                                    <div className="col-md-6 my-2">
                                        <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Income" name="income" />
                                    </div>

                                    {occupationValue && occupationValue == 'Salaried' && <>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Company" name="company" />
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Designation" name="designation" />
                                        </div>
                                    </>}

                                    {occupationValue && occupationValue == 'Business / Self Employed' && <>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Company" name="company" />
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Industry" name="industry" />
                                        </div>
                                    </>}

                                    <div className="col-md-6 my-2">
                                        <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Min Budget" name="min_budget" />
                                    </div>

                                    <div className="col-md-6 my-2">
                                        <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Max Budget" name="max_budget" />
                                    </div>


                                    <div className="col-md-6 my-2">
                                        <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]{2}', min: 0 }} label="Possession Duration (In Months)" name="possession_duration" />
                                    </div>

                                    <div className="col-md-6 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={["Gated Community Plots", "Individual Plots", "Apartment", "Villa", "Independent House", "Row Houses"]}
                                            onChange={(event, newValue) => { setPropertyTypeValue(newValue); }}
                                            renderInput={(params) => <TextField {...params} label="Preferred Property Type" name="property_type" />}
                                        />
                                    </div>

                                    {(propertyTypeValue == "Gated Community Plots" || propertyTypeValue == "Individual Plots") ? (<>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Plot Size (in sq ft)" name="plot_size" />
                                        </div>
                                    </>) : ""}

                                    {(propertyTypeValue == "Apartment" || propertyTypeValue == "Villa" || propertyTypeValue == "Independent House" || propertyTypeValue == "Row Houses") ? (<>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Bedroom Preference" name="bedroom_preference" />
                                        </div>
                                    </>) : ""}

                                    {(propertyTypeValue == "Apartment") ? (<>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Min Carpet Area" name="min_carpet_area" />
                                        </div>

                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} label="Max Carpet Area" name="max_carpet_area" />
                                        </div>
                                    </>) : ""}

                                    <div className="col-md-6 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={["End Use", "Investment"]}
                                            renderInput={(params) => <TextField {...params} label="Purpose" name="purpose" />}
                                        />
                                    </div>

                                </div>

                            </fieldset>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <fieldset className='fieldset_info'>
                                <legend className='fieldset_info_legend'>Reminder</legend>

                                <div className="row">

                                    <div className="col-md-3 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={["Followup", "SiteVisit"]}
                                            value={reminderValue}
                                            onChange={(event, newValue) => { context_data.setReminderType(newValue); setReminderValue(newValue); }}
                                            renderInput={(params) => <TextField {...params} label="Type" name="reminder_type" required />}
                                        />
                                    </div>

                                    <div className="col-md-6 my-2">
                                        <ReminderDetails />
                                    </div>

                                    <div className="col-md-3 my-2">
                                        {reminderValue && reminderValue.toLowerCase() == "sitevisit" && (
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="CONFIRMED"
                                                    name="sitevisit_confirm"
                                                >
                                                    <div className="d-flex">
                                                        <FormControlLabel value="CONFIRMED" control={<Radio />} label="Confirmed" />
                                                        <FormControlLabel value="TENTATIVE" control={<Radio />} label="Tentative" />
                                                    </div>
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                        <Button type="submit" variant="contained" color="success">Submit</Button>
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                    </div>

                </form >
            </div >
        </>
    )
}

export default LeadAdd