import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const RemindersSummary = () => {
    var navigate = useNavigate();
    return (
        <section id="sectionLeadsSummary">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate("/reports")}>Reports</Breadcrumb.Item>
                            <Breadcrumb.Item active>Reminders Summary</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RemindersSummary