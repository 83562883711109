import './Config';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { useCallback, useRef, useState } from 'react';
import moment from 'moment';
import ucwords from 'ucwords';

import { ContextData } from './Components/ContextData';
import Backdrop from '@mui/material/Backdrop';
import { BrowserRouter, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import Login from "./Login";
import Logout from "./Logout";
import NotFound from "./NotFound";
import NoAccess from "./NoAccess";
import Policy from "./Policy";
import SideDrawer from "./SideDrawer";
import Home from "./Home";
import LeadsList from './LeadsList';
import LeadDetails from './LeadDetails';
import BuilderAdd from './BuilderAdd';
import BuilderEdit from './BuilderEdit';
import BuildersList from './BuildersList';
import AssignEmployeeProject from './AssignEmployeeProject';
import LeadAdd from './LeadAdd';
import LeadEdit from './LeadEdit';
import ProjectAdd from './ProjectAdd';
import ProjectEdit from './ProjectEdit';
import ProjectsList from './ProjectsList';
import EmployeeAdd from './EmployeeAdd';
import EmployeeEdit from './EmployeeEdit';
import EmployeesList from './EmployeesList';
import ProjectCampaigns from './ProjectCampaigns';
import PendingActivityLeadsAll from './PendingActivityLeadsAll';
import PendingActivityLeadsTillDate from './PendingActivityLeadsTillDate';
import NoActivityLeads from './NoActivityLeads';
import CampaignAccounts from './CampaignAccounts';
import WebsiteApi from './WebsiteApi';
import ChangePassword from './ChangePassword';
import IncomingLeadsList from './IncomingLeadsList';
import LeadsSummary from './Reports/LeadsSummary';
import Reports from './Reports';
import RemindersSummary from './Reports/RemindersSummary';
import { useEffect } from 'react';
import axios from 'axios';
import ProjectRevenue from './ProjectRevenue';
import ReassignLeads from './ReassignLeads';
import RevenueReport from './Reports/RevenueReport';
import IncentiveAdd from './Reports/IncentiveAdd';
import LeadSourceReport from './Reports/LeadSourceReport';
import SpendAdd from './SpendAdd';
import SpendsList from './SpendsList';
import SitevisitList from './SitevisitList';
import BookingList from './BookingList';
import IncentivesReport from './Reports/IncentivesReport';
import BookingDetails from './BookingDetails';
import Test from './Test';
import PendingActivityListTeam from './PendingActivityListTeam';
import TeamLeads from './TeamLeads';

const $ = require("jquery");

function App() {

	var location = useLocation();
	var navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (localStorage.getItem("login_data")) {

			var login_data = JSON.parse(localStorage.getItem("login_data"))

			loginerror(login_data.employee_id)
			if (login_data.employee_type == "MASTERUSER") {
			} else if (login_data.employee_type != "MASTERUSER") {
				checkBlockEmployeeWithLead(login_data.employee_id)
			} else {
				navigate("/noAccess")
			}

			var x = window.matchMedia("(max-width: 700px)")
			if (!(x.matches)) {
				if (login_data.employee_type != "MASTERUSER") {
					openRightNav();
				}
			}
			else {
				closeRightNav()
			}
		}
	}, [location]);

	const loginerror = async (employee_id) => {

        context_data.setProcessing(true)
        const getEmployeesUrl = global.config.server_url + "getloginerror.php";
		await axios.post(getEmployeesUrl, { employee_id: employee_id }).then((getEmployeesResponse) => {
			if (getEmployeesResponse.data == true) {
				navigate("/logout")
			}
            context_data.setProcessing(false)
		}).catch(err => {
			console.log(err);
			navigate("/logout")
            context_data.setProcessing(false)
		});
	}

	const checkBlockEmployeeWithLead = async (employee_id) => {

		setProcessing(true)
		const getEmployeesUrl = global.config.server_url + "getEmployees.php";
		await axios.post(getEmployeesUrl, { employee_id: employee_id }).then((getEmployeesResponse) => {
			if (getEmployeesResponse.data) {
				if (getEmployeesResponse.data.pending_lead_id) {
					var pending_lead_id = getEmployeesResponse.data.pending_lead_id
					context_data.setPrevPage(location.pathname)

					var id = searchParams.get("id");
					if ((location.pathname).includes("leadDetails")) {
					
						if (pending_lead_id != id) {
							navigate({ pathname: "/leadDetails/", search: "id=" + pending_lead_id, state: { id: pending_lead_id } })
						}
					} else {
						navigate({ pathname: "/leadDetails/", search: "id=" + pending_lead_id, state: { id: pending_lead_id } })
					}

				} else {
					console.log("no_pending_lead_id")
					checkOlderSitevisit(employee_id)
				}
				setProcessing(false)
			} else {
				console.log("error in getting pending lead data")
				checkOlderSitevisit(employee_id)
				setProcessing(false)
			}
		}).catch(err => {
			console.log(err);
			checkOlderSitevisit(employee_id)
			setProcessing(false)
		});
	}

	const checkOlderSitevisit = async (employee_id) => {
		setProcessing(true)
		const checkOlderSitevisitUrl = global.config.server_url + "getOlderSitevisitCount.php";
		await axios.post(checkOlderSitevisitUrl, { employee_id: employee_id }).then((checkOlderSitevisitResponse) => {
			if (checkOlderSitevisitResponse.data) {
				var oldSitevisitCount = checkOlderSitevisitResponse.data.count

				if (oldSitevisitCount > 0) {
					if (!(location.pathname).includes("sitevisitList") && !(location.pathname).includes("leadDetails")) {
						if (!localStorage.getItem("master_login_data") || localStorage.getItem("master_login_data") == "") {
							navigate({ pathname: "/sitevisitList" })
						}
					}
				} else {
					console.log("no older sitevisit")
				}
				setProcessing(false)
			} else {
				console.log("error in getting older sitevisit data")
				setProcessing(false)
			}
		}).catch(err => { console.log(err); setProcessing(false) });
	}

	const [prevPage, setPrevPage] = useState("");
	const [processing, setProcessing] = useState(false);
	const [reminderType, setReminderType] = useState("followup")
	const [pickerDatetime, setPickerDatetime] = useState(moment(new Date()).add((5 - ((moment(new Date())).minute() % 5)), 'm').toDate())

	var spl_chars = ["?", "&", "+", "~", "`", "!", "$", "=", "|", "<", ">", "#", "'", '%', '"'];

	const checkSpecialCharacters = (e) => {
		var str = e.target.value;
		for (var index = 0; index < spl_chars.length; index++) {
			if (str.indexOf(spl_chars[index]) > -1) {
				var str_data = str.replace(spl_chars[index], "")
				e.target.value = str_data
				return;
			}
		}
	}

	const [showUpdateActivity, setShowUpdateActivity] = useState(true);

	const ucwords_value = (data) => {
		var newValue = data ? ucwords(data.toLowerCase()).replaceAll('_', ' ') : "-"
		return newValue
	}

	const uppercase_value = (data) => {
		var newValue = data ? (data.toUpperCase()).replaceAll('_', ' ') : "-"
		return newValue
	}

	const lowercase_value = (data) => {
		var newValue = data ? (data.toLowerCase()).replaceAll('_', ' ') : "-"
		return newValue
	}

	const datetime_value = (data) => {
		if (data) {
			if (moment(data).isValid()) {
				return moment(data).format("DD-MMM-YYYY h:mm:ss A")
			} else if (moment(data, "YYYY-MM-DD h:mm:ss A", true).isValid()) {
				return moment(data, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
			} else if (moment(data, "YYYY-MM-DD H:mm:ss", true).isValid()) {
				return moment(data, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
			} else {
				return data
			}
		} else {
			return "-"
		}
	}

	const date_value = (data) => {
		if (data) {
			if (moment(data).isValid()) {
				return moment(data).format("DD-MMM-YYYY")
			} else if (moment(data, "YYYY-MM-DD", true).isValid()) {
				return moment(data, "YYYY-MM-DD", true).format("DD-MMM-YYYY")
			} else {
				return data
			}
		} else {
			return "-"
		}
	}

	const employee_value = (employee_id, employee_name) => {
		var employee_id_value = employee_id ? employee_id.toUpperCase() : "-";
		var employee_name_value = employee_name ? ucwords(employee_name.toLowerCase()) : "-"
		if (employee_id && employee_name) { return employee_id_value + " : " + employee_name_value }
		else if (employee_id || employee_name) { return employee_id + employee_name }
		else { return "-" }
	}

	const aggrid_ucwords_value = (params) => {
		var column_name = (params.column.colId)
		var newValue = params.data[column_name] ? ucwords(params.data[column_name].toLowerCase()).replaceAll('_', ' ') : "-"
		return newValue
	}

	const aggrid_uppercase_value = (params) => {
		var column_name = (params.column.colId)
		var newValue = params.data[column_name] ? (params.data[column_name].toUpperCase()).replaceAll('_', ' ') : "-"
		return newValue
	}

	const aggrid_lowercase_value = (params) => {
		var column_name = (params.column.colId)
		var newValue = params.data[column_name] ? (params.data[column_name].toLowerCase()).replaceAll('_', ' ') : "-"
		return newValue
	}

	const aggrid_datetime_value = (params) => {
		var column_name = (params.column.colId)
		var data = params.data[column_name];;
		if (data) {
			if (moment(data).isValid()) {
				return moment(data).format("DD-MMM-YYYY h:mm:ss A")
			} else if (moment(data, "YYYY-MM-DD h:mm:ss A", true).isValid()) {
				return moment(data, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
			} else if (moment(data, "YYYY-MM-DD H:mm:ss", true).isValid()) {
				return moment(data, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
			} else {
				return data
			}
		} else {
			return "-"
		}
	}

	const aggrid_date_value = (params) => {
		var column_name = (params.column.colId)
		var data = params.data[column_name];

		if (data) {
			if (moment(data).isValid()) {
				return moment(data).format("DD-MMM-YYYY")
			} else if (moment(data, "YYYY-MM-DD", true).isValid()) {
				return moment(data, "YYYY-MM-DD", true).format("DD-MMM-YYYY")
			} else {
				return data;
			}
		} else {
			return "-"
		}
	}

	const [aggridDefaultColDef, setAggridDefaultColDef] = useState(
		{
			resizable: true,
			sortable: true,
			autoHeight: true,
			wrapText: false,
			maxWidth: 400,
		}
	)

	const [aggridLeadsDefaultColDef, setAggridLeadsDefaultColDef] = useState(
		{
			filter: true,
			floatingFilter: true,
			sortable: true,
			resizable: true,
			autoHeight: true,
			wrapText: false,
			maxWidth: 350,
			// suppressMenu: true,
			floatingFilterComponentParams: { suppressFilterButton: true },

		}
	)

	var aggridRef = useRef();

	const aggridAutoSizeAll = useCallback((skipHeader) => {
		const allColumnIds = [];
		aggridRef.current.columnApi.getAllColumns().forEach((column) => {
			allColumnIds.push(column.getId());
		});
		aggridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
	}, []);

	const toggleRightSideNav = () => {
		setRightSideNavState(!rightSideNavState)
	}

	const [rightSideNavState, setRightSideNavState] = useState(true);

	const openRightNav = () => {
		setRightSideNavState(true)
	}

	const closeRightNav = () => {
		setRightSideNavState(false)
	}

	const context_data = {
		toggleRightSideNav: toggleRightSideNav,
		rightSideNavState: rightSideNavState,
		setRightSideNavState: setRightSideNavState,

		ucwords_value: ucwords_value,
		uppercase_value: uppercase_value,
		lowercase_value: lowercase_value,
		datetime_value: datetime_value,
		date_value: date_value,
		employee_value: employee_value,
		aggrid_ucwords_value: aggrid_ucwords_value,
		aggrid_uppercase_value: aggrid_uppercase_value,
		aggrid_datetime_value: aggrid_datetime_value,
		aggrid_date_value: aggrid_date_value,
		aggridDefaultColDef: aggridDefaultColDef,
		aggridLeadsDefaultColDef: aggridLeadsDefaultColDef,
		aggridAutoSizeAll: aggridAutoSizeAll,
		aggridRef: aggridRef,
		showUpdateActivity: showUpdateActivity,
		setShowUpdateActivity: setShowUpdateActivity,
		prevPage: prevPage,
		setPrevPage: setPrevPage,
		processing: processing,
		setProcessing: setProcessing,
		reminderType: reminderType,
		setReminderType: setReminderType,
		pickerDatetime: pickerDatetime,
		setPickerDatetime: setPickerDatetime,
		checkSpecialCharacters: checkSpecialCharacters,
	}

	return (
		<ContextData.Provider value={context_data}>
			<div className={rightSideNavState ? "App right_side_nav_open_app" : "App"} id="App">

				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}
					open={processing}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				<Routes>
					<Route exact path="/" element={<Login />} />
					<Route exact path="/logout" element={<Logout />} />
					<Route exact path="/changePassword" element={<SideDrawer pageData={<ChangePassword />} />} />
					<Route exact path="/home" element={<SideDrawer pageData={<Home />} />} />

					<Route exact path="/allLeads" element={<SideDrawer pageData={<LeadsList />} />} />
					<Route exact path="/addLead" element={<SideDrawer pageData={<LeadAdd />} />} />
					<Route exact path="/editLead" element={<SideDrawer pageData={<LeadEdit />} />} />
					<Route exact path="/leadDetails" element={<SideDrawer pageData={<LeadDetails />} />} />
					{/* <Route exact path="/leadDetails" element={<LeadDetails />} /> */}

					<Route exact path="/addBuilder" element={<SideDrawer pageData={<BuilderAdd />} />} />
					<Route exact path="/editBuilder" element={<SideDrawer pageData={<BuilderEdit />} />} />
					<Route exact path="/allBuilders" element={<SideDrawer pageData={<BuildersList />} />} />

					<Route exact path="/addProject" element={<SideDrawer pageData={<ProjectAdd />} />} />
					<Route exact path="/editProject" element={<SideDrawer pageData={<ProjectEdit />} />} />
					<Route exact path="/allProjects" element={<SideDrawer pageData={<ProjectsList />} />} />

					<Route exact path="/addEmployee" element={<SideDrawer pageData={<EmployeeAdd />} />} />
					<Route exact path="/editEmployee" element={<SideDrawer pageData={<EmployeeEdit />} />} />
					<Route exact path="/allEmployees" element={<SideDrawer pageData={<EmployeesList />} />} />

					<Route exact path="/websiteApi" element={<SideDrawer pageData={<WebsiteApi />} />} />
					<Route exact path="/projectCampaigns" element={<SideDrawer pageData={<ProjectCampaigns />} />} />
					<Route exact path="/campaignAccounts" element={<SideDrawer pageData={<CampaignAccounts />} />} />
					<Route exact path="/incomingLeads" element={<SideDrawer pageData={<IncomingLeadsList />} />} />
					<Route exact path="/noActivityLeads" element={<SideDrawer pageData={<NoActivityLeads />} />} />
					<Route exact path="/allPendingActivityLeads" element={<SideDrawer pageData={<PendingActivityLeadsAll />} />} />
					<Route exact path="/tillDatePendingActivityLeads" element={<SideDrawer pageData={<PendingActivityLeadsTillDate />} />} />
					<Route exact path="/teamPendingActivityLeads" element={<SideDrawer pageData={<PendingActivityListTeam />} />} />
					
					<Route exact path="/assignEmployee" element={<SideDrawer pageData={<AssignEmployeeProject />} />} />

					<Route exact path="/projectRevenue" element={<SideDrawer pageData={<ProjectRevenue />} />} />

					<Route exact path="/reports" element={<SideDrawer pageData={<Reports />} />} />
					<Route exact path="/leadsSummary" element={<SideDrawer pageData={<LeadsSummary />} />} />
					<Route exact path="/leadSourceReport" element={<SideDrawer pageData={<LeadSourceReport />} />} />
					<Route exact path="/remindersSummary" element={<SideDrawer pageData={<RemindersSummary />} />} />
					<Route exact path="/revenueReport" element={<SideDrawer pageData={<RevenueReport />} />} />
					<Route exact path="/incentivesReport" element={<SideDrawer pageData={<IncentivesReport />} />} />

					<Route exact path="/incentiveAdd" element={<SideDrawer pageData={<IncentiveAdd />} />} />

					<Route exact path="/reassignLeads" element={<SideDrawer pageData={<ReassignLeads />} />} />

					<Route exact path="/addSpend" element={<SideDrawer pageData={<SpendAdd />} />} />
					<Route exact path="/allSpends" element={<SideDrawer pageData={<SpendsList />} />} />
					<Route exact path="/bookingDetails" element={<SideDrawer pageData={<BookingDetails />} />} />

					<Route exact path="/sitevisitList" element={<SideDrawer pageData={<SitevisitList />} />} />
					<Route exact path="/allBookings" element={<SideDrawer pageData={<BookingList />} />} />
					
					<Route exact path="/teamLeads" element={<SideDrawer pageData={<TeamLeads />} />} />
					
					<Route exact path="/policy" element={<Policy />} />
					<Route path="/noAccess" element={<SideDrawer pageData={<NoAccess />} />} />

					<Route path="/test" element={<SideDrawer pageData={<Test />} />} />

					<Route path="*" element={<SideDrawer pageData={<NotFound />} />} />

				</Routes>

			</div>
		</ContextData.Provider >

	);
}

export default App;
