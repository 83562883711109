import React, { useEffect, useState, useContext } from 'react'
import Form from 'react-bootstrap/Form';
import { ContextData } from './Components/ContextData';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FloatingLabel } from 'react-bootstrap';
import ucwords from 'ucwords';

const moment = require('moment');

const $ = require('jquery');
$.DataTable = require('datatables.net');

const ProjectRevenue = () => {

    const context_data = useContext(ContextData)

    var navigate = useNavigate();

    const [projectsData, setProjectsData] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    const [selectedProject, setSelectedProject] = useState(false);
    const [projectRevenueData, setProjectRevenueData] = useState([]);
    const [minSalesValue, setMinSalesValue] = useState(1)

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_PROJECT") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getProjects(login_data.employee_type);
            getProjectRevenueData();
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    function getProjects(employee_type) {
        context_data.setProcessing(true)
        const getProjectsUrl = global.config.server_url + "getProjects.php";
        axios.post(getProjectsUrl, { all_data: true }).then((getProjectsResponse) => {
            if (getProjectsResponse.data) {
                setProjectsData(getProjectsResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const getProjectRevenueData = (builder_project_id) => {

        var data = {};
        if (builder_project_id) {
            data = {
                builder_project_id: builder_project_id
            }
        } else {
            data = {
                all_data: true
            }
        }
        const getProjectRevenueUrl = global.config.server_url + "getProjectRevenue.php";
        axios.post(getProjectRevenueUrl, data).then((getProjectRevenueResponse) => {
            if (getProjectRevenueResponse.data) {
                setProjectRevenueData(getProjectRevenueResponse.data)
                context_data.setProcessing(false)
            } else {
                setProjectRevenueData([])
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => {
            setProjectRevenueData([])
            console.log(err); context_data.setProcessing(false)
        });
    }

    const addRevenueData = (e) => {
        e.preventDefault();
        var project_id = e.target.project.value;
        var min_sales = e.target.min_sales.value;
        var max_sales = e.target.max_sales.value;
        var revenue = e.target.revenue.value;
        var revenue_type = e.target.revenue_type.value;
        var active_from = e.target.active_from.value;
        var login_employee_id = localStorage.getItem("employee_id");
        var login_employee_name = localStorage.getItem("employee_name");

        var project_data = projectsData.filter(function (project) { return project.builder_project_id == project_id; });

        var addProjectRevenueData = {
            builder_id: project_data[0].builder_id,
            builder_name: project_data[0].builder_name,
            builder_project_id: project_data[0].builder_project_id,
            builder_project_name: project_data[0].builder_project_name,
            min_sales: min_sales,
            max_sales: max_sales,
            revenue: revenue,
            revenue_type: revenue_type,
            active_from: moment(active_from).format("YYYY-MM-DD HH:mm:ss"),
            login_employee_id: login_employee_id,
            login_employee_name: login_employee_name,
        }

        var submitAddProjectRevenueUrl = global.config.server_url + "addProjectRevenue.php";
        axios.post(submitAddProjectRevenueUrl, addProjectRevenueData).then((response) => {
            if (response.data === true) {
                getProjectRevenueData(project_id)
                context_data.setProcessing(false);
            } else {
                alert("Error in Adding Data");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Adding Data"); context_data.setProcessing(false); });


        e.target.min_sales.value = ""
        e.target.max_sales.value = ""
        e.target.revenue.value = ""
        e.target.active_from.value = moment().add(10, 'minutes').format("YYYY-MM-DDTHH:mm:ss")


    }

    // useEffect(() => {
    // let project_revenue_table = $('#project_revenue_table').DataTable();
    // $.fn.dataTable.ext.errMode = 'none';
    // }, [projectRevenueData])


    function datetime_value(value) {
        if (moment(value).isValid()) {
            return moment(value).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(value, "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            return moment(value, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(value, "YYYY-MM-DD H:mm:ss", true).isValid()) {
            return moment(value, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            return value
        }
    }

    const testsubmit = (e) => {
        var formData = ""
        axios.post("/reactimageupload.php", formData).then((response) => {
            console.log(response);
            if (response.data == true) {
                console.log("image uploaded");
                context_data.setProcessing(false);
            } else {
                console.log("Error in uploading image");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in uploading image"); context_data.setProcessing(false); });

    }

    return (
        <>
            <div className="container mt-2">
                <form onSubmit={addRevenueData}>
                    <div className="row">
                        {(projectsData == false || projectsData == null) ? ("No Project Available") : (
                            <div className="col-md-6">
                                <FloatingLabel controlId="floatingSelect" label="Project">
                                    <Form.Select name="project" id="select_project" aria-label="Floating label SELECT example" required onChange={(e) => { setSelectedProject(e.target.value); getProjectRevenueData(e.target.value) }}>
                                        <option value="" selected>SELECT Project</option>
                                        {projectsData.map((project) => <option value={project.builder_project_id}>{ucwords(project.builder_project_name.toLowerCase()) + " - " + ucwords(project.builder_name.toLowerCase())}</option>)}
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                        )}
                    </div>
                    <fieldset className='fieldset_info my-3'>
                        <legend className='fieldset_info_legend'>Project Sales Revenue</legend>
                        <div className="row">
                            <div className="col-md-3 my-2">
                                <div class="form-floating">
                                    <input type="number" className="form-control" placeholder="Min Sales" name="min_sales" onChange={(e) => setMinSalesValue(e.target.value)} required />
                                    <label for="floatingMinSales">Min Sales</label>
                                </div>
                            </div>
                            <div className="col-md-3 my-2">
                                <div class="form-floating mb-3">
                                    <input type="number" className="form-control" placeholder="Max Sales" name="max_sales" min={minSalesValue} required />
                                    <label for="floatingMaxSales">Max Sales</label>
                                </div>
                            </div>
                            <div className="col-md-3 my-2">
                                <div class="input-group mb-3">
                                    <div class="form-floating w-75">
                                        <input type="number" className="form-control" placeholder="Revenue" name="revenue" required step={"0.01"} />
                                        <label for="floatingRevenue">Revenue</label>
                                    </div>
                                    <select className='form-select' name="revenue_type" id="" >
                                        <option selected value="percentage">%</option>
                                        <option value="amount">Rs</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3 my-2">
                                <div class="form-floating mb-3">
                                    <input type="datetime-local" className="form-control" placeholder="Active From" name="active_from" required min={moment().format("YYYY-MM-DDTHH:mm:ss")} defaultValue={moment().add(10, 'minutes').format("YYYY-MM-DDTHH:mm:ss")} step="any" />
                                    <label for="floatingMaxSales">Active From (mm/dd/yyyy)</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="submit" value="Add" className="btn btn-primary" />
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>

            {(projectRevenueData && projectRevenueData.length > 0) && (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-striped mt-2" id="project_revenue_table">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Builder</th>
                                            <th>Project</th>
                                            <th>Min Sales</th>
                                            <th>Max Sales</th>
                                            <th>Revenue</th>
                                            <th>Active From</th>
                                            <th>Created by</th>
                                            <th>Created at</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectRevenueData.map((project, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{context_data.ucwords_value(project.builder_name)}</td>
                                                <td>{context_data.ucwords_value(project.builder_project_name)}</td>
                                                <td>{project.min_sales}</td>
                                                <td>{project.max_sales}</td>
                                                <td>{project.revenue + (project.revenue_type == "percentage" ? " %" : project.revenue_type == "amount" ? " Rs" : "")}</td>
                                                <td>{datetime_value(project.active_from)}</td>
                                                <td>{context_data.ucwords_value(project.create_name)} </td>
                                                <td>{datetime_value(project.create_datetime)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ProjectRevenue