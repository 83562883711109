import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import ucwords from 'ucwords';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContextData } from './ContextData';
import { Form, InputGroup, Modal } from 'react-bootstrap';

var moment = require("moment");

const Notifications = () => {
    var location = useLocation();
    const context_data = useContext(ContextData)

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            getNotifications(login_data.employee_id);
            const interval = setInterval(() => {
                getNotifications(login_data.employee_id);
            }, 300000);
            return () => clearInterval(interval);
        } else {
            navigate("/logout")
        }

    }, [])

    var navigate = useNavigate();

    const [notifications, setNotifications] = useState(false)

    const getNotifications = async (employee_id) => {
        var employee_id_data = employee_id ? employee_id : localStorage.getItem("employee_id");

        context_data.setProcessing(true)
        const getNotificationsUrl = global.config.server_url + "getNotifications.php";
        axios.post(getNotificationsUrl, { notification_employee_id: employee_id_data }).then((notificationsResponse) => {
            if (notificationsResponse.data != false) {
                setNotifications(notificationsResponse.data);
                context_data.setProcessing(false)
            } else {
                setNotifications(false);
                context_data.setProcessing(false)
            }
        }).catch(err => {
            console.log(err);
            context_data.setProcessing(false)
        });

        var x = window.matchMedia("(max-width: 700px)")

        if (!(x.matches)) {
            if (localStorage.getItem("employee_type") != "MASTERUSER") {
                context_data.setRightSideNavState(true)
            }
        } else {
            context_data.setRightSideNavState(false)
        }
    }

    const [notificationModal, setNotificationModal] = useState(false);
    const [notificationModalData, setNotificationModalData] = useState(false);

    const openNotification = async (notification_data) => {
        var notification_type = notification_data.notification_type
        var lead_id = notification_data.lead_id

        if (notification_type.toLowerCase() == "lead") {

            const updateNotificationUrl = global.config.server_url + "updateNotification.php";
            await axios.post(updateNotificationUrl, { lead_id: lead_id, status: 1 }).then((updateNotificationResponse) => {
                if (updateNotificationResponse.data == true) {
                    context_data.setProcessing(false)
                    alert("Notification Updated");
                    getNotifications();
                } else {
                    alert("error in getting data")
                    context_data.setProcessing(false)
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });

            context_data.setPrevPage(location.pathname)
            let url = "/leadDetails/?id=" + lead_id;
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        } else if (notification_type.toLowerCase() == "payment") {
            setNotificationModalData(notification_data)
            setNotificationModal(true)
        }
    }

    return (
        <>
            <Modal
                show={notificationModal}
                onHide={() => setNotificationModal(false)}
                keyboard={false}
                centered
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <form onSubmit={submitAddIncentive}> */}
                    <input type="hidden" name="notification_id" className='form-control' value={notificationModalData.id} />
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td className="table-dark">Lead ID</td>
                                <td>{notificationModalData.lead_id}</td>
                            </tr>
                            <tr>
                                <td className="table-dark">Label</td>
                                <td>{notificationModalData.notification_label}</td>
                            </tr>
                            <tr>
                                <td className="table-dark">Notes</td>
                                <td>{notificationModalData.notification_text}</td>
                            </tr>
                            <tr>
                                <td className="table-dark">Approval Status</td>
                                <td>{(parseInt(notificationModalData.approval_status) == true) ? "Approved" : "Pending"}</td>
                            </tr>
                            {(notificationModalData.status == "1" || notificationModalData.status == true) &&
                                <>
                                    <tr>
                                        <td className="table-dark">Approved by</td>
                                        <td>{notificationModalData.approval_id + notificationModalData.approval_name}</td>
                                    </tr>
                                    <tr>
                                        <td className="table-dark">Approved at</td>
                                        <td>{notificationModalData.approval_datetime}</td>
                                    </tr>
                                </>
                            }
                            <tr>
                                <td className="table-dark">Created by</td>
                                <td>{context_data.employee_value(notificationModalData.create_id, notificationModalData.create_name)}</td>
                            </tr>
                            <tr>
                                <td className="table-dark">Created at</td>
                                <td>{context_data.datetime_value(notificationModalData.create_datetime)}</td>
                            </tr>

                        </tbody>
                    </table>
                    {/* <button type="submit" className='btn btn-success mt-2'>Update</button> */}
                    {/* </form> */}
                </Modal.Body>
            </Modal >

            <div className="div_notifications border border-dark" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="text-center "><b>NOTIFICATIONS</b> </h4>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-12">
                            <div id="div_home_pending_activity">

                                {(notifications == false || notifications == null) ? (<div><h6 className="text-center m-5">No Reminders</h6></div>) :
                                    (notifications.map((notification, index) =>
                                        <div
                                            key={index}
                                            onClick={() => openNotification(notification)}
                                            className={(notification.notification_type == "payment") ? "py-1 pending_activity row border mt-1 mb-2 alert alert-warning" : "py-1 pending_activity row border mt-1 mb-2 alert alert-primary"}
                                        >
                                            < div className="col-12 div_pending_activty">
                                                <label>
                                                    <b>{ucwords(notification.notification_label)}: {(notification.lead_id)}</b>
                                                </label><br />
                                                <label>Created at: {context_data.datetime_value(notification.create_datetime)}</label>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Notifications