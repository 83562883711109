import React, { useContext } from 'react'

import HomeReminders from './HomeReminders';
import { ContextData } from './ContextData';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Notifications from './Notifications';

const RightSideNav = () => {

    const context_data = useContext(ContextData)

    return (
        <>
            <section className="section_right_side_nav">
                <div id="right_side_nav"
                    className={context_data.rightSideNavState ? "right_side_nav right_side_nav_open" : "right_side_nav"}
                >
                    <HomeReminders />
                    <Notifications />

                </div>
                <a href="javascript:void(0)" className={context_data.rightSideNavState ? "rightsidenav_closebtn rightsidenav_closebtn_open" : "rightsidenav_closebtn"} id="rightsidenav_closebtn" onClick={() => context_data.toggleRightSideNav()}><span><ArrowBackIosNewIcon /></span></a>

            </section>
        </>
    )
}

export default RightSideNav
