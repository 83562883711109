import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Stack, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { ContextData } from './ContextData';
import ReminderDetails from './ReminderDetails';

var moment = require("moment");

const CreateSitevisit = (props) => {
    const context_data = useContext(ContextData)

    let [searchParams, setSearchParams] = useSearchParams();
    const [loginData, setLoginData] = useState(false);

    useEffect(() => {
        setLoginData(localStorage.getItem("login_data"))
        var login_data = JSON.parse(localStorage.getItem("login_data"))
        setLoginData(login_data)

        var lead_id = searchParams.get("id");
        setLeadId(lead_id);
    }, [])

    const [leadId, setLeadId] = useState(false)

    const [addSiteVisitShow, setAddSiteVisitShow] = useState(false);
    const handleAddSiteVisitShow = () => {
        if (props.nextReminder) {
            alert("Please Complete Pending Action");
        } else {
            context_data.setReminderType("sitevisit");
            context_data.setPickerDatetime(moment(new Date()).add((30 - ((moment(new Date())).minute() % 15)), 'm').toDate());
            setAddSiteVisitShow(true);
            context_data.setShowUpdateActivity(false);
        }
    }
    const handleAddSiteVisitClose = () => { setAddSiteVisitShow(false); context_data.setShowUpdateActivity(true);}

    function submitAddReminder(e) {
        e.preventDefault()
        changeAttendedBy()
        props.unblockEmployeeWithLead()

        context_data.setProcessing(true)

        var notes = e.target.notes.value;
        notes = notes.replace(/["']/g, "");
        notes = notes.replace(/\s+/g, ' ').trim()
        var reminder_type = e.target.type.value;
        var reminder_datetime = context_data.pickerDatetime;
        reminder_datetime.setSeconds(0);
        var id = (leadId != undefined || leadId != null) ? leadId : searchParams.get("id");

        const addReminderData = {
            notes_data: notes,
            reminder_datetime: reminder_datetime,
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
            id: id
        };

        var addReminderUrl = global.config.server_url + "addSiteVisit.php";

        axios.post(addReminderUrl, addReminderData).then((response) => {
            if (response.data == true) {
                context_data.setProcessing(false);
                handleAddSiteVisitClose();
                props.getLeadDetails();

            } else {
                alert("Error in Creating Reminder");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Adding Reminder"); context_data.setProcessing(false); });

            }

    const changeAttendedBy = async (lead_id = props.leadDetails.id, lead_details = props.leadDetails) => {

        if (localStorage.getItem("employee_id").toUpperCase() == lead_details.lead_owner_id.toUpperCase()) {
            const changeAttendedByUrl = global.config.server_url + "changeAttendedBy.php";
            const changeAttendedByData = {
                login_employee_id: (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id")),
                login_employee_name: (loginData['employee_name'] != undefined || loginData['employee_name'] != null) ? (loginData['employee_name']) : (localStorage.getItem("employee_name")),
                id: lead_id ? lead_id : searchParams.get("id")
            }
            axios.post(changeAttendedByUrl, changeAttendedByData).then((ChangeAttendedByResponse) => {
                if (ChangeAttendedByResponse.data == true) {
                    // console.log("Changed attended by");
                }
                else {
                    // console.log("error in changing attended by");
                }
            }).catch(err => { console.log(err); console.log("error in changing attended by"); });
        }
    }

    return (
        <>
            {/* Modal for Sitevisit */}
            <Modal
                show={addSiteVisitShow}
                onHide={() => handleAddSiteVisitClose()}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Site Visit</Modal.Title>
                </Modal.Header>
                <form className="form-group" onSubmit={(e) => submitAddReminder(e)}>
                    <input type="hidden" name="type" value="sitevisit" />
                    <Modal.Body>
                        <div>
                            <div>
                                <ReminderDetails />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="submit" className="btn btn-primary" id="" value="Submit" />
                        {/* <input type="button" className="btn btn-danger" id="" onClick={() => handleAddSiteVisitClose()} value="Cancel" /> */}
                    </Modal.Footer>
                </form>
            </Modal>

            <input type="button" className="m-2" disabled={(props.leadDetails.lead_status.toLowerCase() == "closed" || props.leadDetails.lead_status.toLowerCase() == "booked") ? "disabled" : ""} value="Site Visit" onClick={() => handleAddSiteVisitShow()} />
        </>
    )
}

export default CreateSitevisit