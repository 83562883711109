import { Autocomplete, TextField } from '@mui/material'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { FloatingLabel, Form, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import ucwords from 'ucwords'
import { ContextData } from './Components/ContextData'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require('moment')

const $ = require('jquery');
$.DataTable = require('datatables.net');

const AssignEmployeeProject = () => {

    const context_data = useContext(ContextData)

    var navigate = useNavigate()
    const [loginData, setLoginData] = useState(false)
    const [employeesData, setEmployeesData] = useState(false)
    const [projectsData, setProjectsData] = useState(false)
    const [selectedProject, setSelectedProject] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState(false)
    const [allAssignedData, setAllAssignedData] = useState(false)
    const [selectedAssignedData, setSelectedAssignedData] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ASSIGN_EMPLOYEE") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            if (isAvailable != true) { navigate("/noAccess") }
            getAllProjects();
            getAllEmployees();
            getAllAssignedEmployees();
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }

        setDivAssigningDataStyle({ display: "none" })

    }, [])

    const getAllProjects = () => {
        context_data.setProcessing(true)
        const getAllProjectsUrl = global.config.server_url + "getProjects.php";
        axios.post(getAllProjectsUrl, { all_data_active: true }).then((allProjectsResponse) => {
            if (allProjectsResponse.data.length > 0) {
                setProjectsData(allProjectsResponse.data);
                context_data.setProcessing(false)
            } else {
                setProjectsData(false);
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); setProjectsData(false); context_data.setProcessing(false) });
    }

    const getAllEmployees = () => {
        context_data.setProcessing(true)
        const getAllEmployeesUrl = global.config.server_url + "getEmployees.php";
        axios.post(getAllEmployeesUrl, { all_data_active: true }).then((allEmployeesResponse) => {
            if (allEmployeesResponse.data.length > 0) {
                setEmployeesData(allEmployeesResponse.data);
                context_data.setProcessing(false)
            } else {
                setEmployeesData(false);
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); setEmployeesData(false); context_data.setProcessing(false) });
    }

    const getAllAssignedEmployees = async () => {
        context_data.setProcessing(true)
        const getAssignedProjectsUrl = global.config.server_url + "getEmployeesFromProjectEmployees.php";
        await axios.post(getAssignedProjectsUrl, { all_data: true }).then((assignedProjectsResponse) => {
            if (assignedProjectsResponse.data.length > 0) {
                setAllAssignedData(assignedProjectsResponse.data);
                context_data.setProcessing(false)
                getAssignedProjectsData(assignedProjectsResponse.data)
            } else {
                setAllAssignedData(false);
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); setAllAssignedData(false); context_data.setProcessing(false) });
    }

    const [divAssigningDataStyle, setDivAssigningDataStyle] = useState({ display: "none" })
    const [checkStatus, setCheckStatus] = useState(false)
    const [checkMonday, setCheckMonday] = useState(false)
    const [checkTuesday, setCheckTuesday] = useState(false)
    const [checkWednesday, setCheckWednesday] = useState(false)
    const [checkThursday, setCheckThursday] = useState(false)
    const [checkFriday, setCheckFriday] = useState(false)
    const [checkSaturday, setCheckSaturday] = useState(false)
    const [checkSunday, setCheckSunday] = useState(false)
    const [leadsRatio, setLeadsRatio] = useState(false);

    const getAssignedProjectsData = (all_assigned_data = allAssignedData) => {

        var project = document.getElementById("select_project")
        var employee = document.getElementById("select_employee")
        var project_value = project ? project.value : ""
        var employee_value = employee ? employee.value : ""

        setDefaultValue();

        if (project_value && employee_value) {
            console.log(project_value, employee_value)
            var foundValue = all_assigned_data.filter(obj => (obj.builder_project_id == project_value && obj.employee_id == employee_value));

            setSelectedAssignedData(foundValue)

            setDivAssigningDataStyle({ display: "block" })

            if (foundValue && foundValue[0].status == "1") { setCheckStatus(true) } else { setCheckStatus(false); }
            if (foundValue && foundValue[0].monday == "1") { setCheckMonday(true) } else { setCheckMonday(false); }
            if (foundValue && foundValue[0].tuesday == "1") { setCheckTuesday(true) } else { setCheckTuesday(false); }
            if (foundValue && foundValue[0].wednesday == "1") { setCheckWednesday(true) } else { setCheckWednesday(false); }
            if (foundValue && foundValue[0].thursday == "1") { setCheckThursday(true) } else { setCheckThursday(false); }
            if (foundValue && foundValue[0].friday == "1") { setCheckFriday(true) } else { setCheckFriday(false); }
            if (foundValue && foundValue[0].saturday == "1") { setCheckSaturday(true) } else { setCheckSaturday(false); }
            if (foundValue && foundValue[0].sunday == "1") { setCheckSunday(true) } else { setCheckSunday(false); }
            if (foundValue && foundValue[0].leads_ratio) setLeadsRatio(parseInt(foundValue[0].leads_ratio));

        } else if (project_value) {
            var foundValue = all_assigned_data.filter(obj => (obj.builder_project_id == project_value));
            console.log(foundValue)
            setSelectedAssignedData(foundValue)
        } else if (employee_value) {
            var foundValue = all_assigned_data.filter(obj => (obj.employee_id == employee_value));
            console.log(all_assigned_data)
            console.log(employee_value, foundValue)
            setSelectedAssignedData(foundValue)
        } else {
            setSelectedAssignedData(false)
        }
    }

    const setDefaultValue = () => {
        setCheckStatus(false);
        setCheckMonday(false);
        setCheckTuesday(false);
        setCheckWednesday(false);
        setCheckThursday(false);
        setCheckFriday(false);
        setCheckSaturday(false);
        setCheckSunday(false);
        setLeadsRatio("");
    }

    const submitAssignEmployee = async (e) => {
        e.preventDefault();

        context_data.setProcessing(true);
        var project = projectsData.find(obj => (obj.builder_project_id == e.target.project.value))
        var employee = employeesData.find(obj => (obj.employee_id == e.target.employee.value))

        if (project && employee) {

            const assignEmployeeUrl = global.config.server_url + "assignEmployeeProject.php";
            const assignEmployeeData = {

                employee_id: employee ? employee.employee_id : "",
                employee_name: employee ? employee.employee_name : "",
                employee_mobile: employee ? employee.employee_mobile : "",
                employee_email: employee ? employee.employee_email : "",

                project_id: project ? project.builder_project_id : "",
                project_name: project ? project.builder_project_name : "",
                builder_id: project ? project.builder_id : "",
                builder_name: project ? project.builder_name : "",

                leads_ratio: e.target.leads_ratio.value,
                status: (e.target.status.checked == true) ? "1" : "0",
                sunday: (e.target.sunday.checked == true) ? "1" : "0",
                monday: (e.target.monday.checked == true) ? "1" : "0",
                tuesday: (e.target.tuesday.checked == true) ? "1" : "0",
                wednesday: (e.target.wednesday.checked == true) ? "1" : "0",
                thursday: (e.target.thursday.checked == true) ? "1" : "0",
                friday: (e.target.friday.checked == true) ? "1" : "0",
                saturday: (e.target.saturday.checked == true) ? "1" : "0",

                login_employee_id: (localStorage.getItem("employee_id")),
                login_employee_name: (localStorage.getItem("employee_name")),
            }

            await axios.post(assignEmployeeUrl, assignEmployeeData).then((assignedProjectResponse) => {
                if (assignedProjectResponse.data == true) {
                    alert("Assigned");
                    getAllAssignedEmployees()
                    context_data.setProcessing(false);
                } else {
                    alert("Error in Assigning");
                    context_data.setProcessing(false);
                }
            }).catch(err => {
                alert("Error in Assigning");
                context_data.setProcessing(false);
                console.log(err);
            }
            );

        } else {
            alert("SELECT data")
            return
        }
    }

    const deleteAssignedProject = async (assigned_project_table_id) => {
        context_data.setProcessing(true);

        const deleteAssignedProjectUrl = global.config.server_url + "deleteAssignedProject.php";
        await axios.post(deleteAssignedProjectUrl, { assigned_project_table_id: assigned_project_table_id }).then((deleteAssignedProjectResponse) => {
            if (deleteAssignedProjectResponse.data == true) {
                alert("Deleted");
                context_data.setProcessing(false);
                getAllAssignedEmployees()
            } else {
                alert("Error in Deleting");
                context_data.setProcessing(false);
            }
        }).catch(err => {
            alert("Error in Deleting");
            context_data.setProcessing(false);
            console.log(err);
        }
        );
    }

    $(document).ready(function () {
        let all_assigned_projects_list = $('#all_assigned_projects_list').DataTable({ scrollX: true });
        let assigned_projects_list = $('#assigned_projects_list').DataTable({ scrollX: true });
        $.fn.dataTable.ext.errMode = 'none';
    });

    return (

        <>
            <section className="section_assignEmployee">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>Assign Employee</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>

                <form onSubmit={submitAssignEmployee}>
                    <div className="container">

                        <div className="row">
                            {(projectsData == false || projectsData == null) ? ("No Project Available") : (
                                <div className="col-md-6">
                                    <FloatingLabel controlId="floatingSelect" label="Project">
                                        <Form.Select name="project" id="select_project" aria-label="Floating label SELECT example" required onChange={(e) => { setSelectedProject(e.target.value); getAssignedProjectsData() }}>
                                            <option value="" selected>SELECT Project</option>
                                            {projectsData.map((project) => <option value={project.builder_project_id}>{ucwords(project.builder_project_name.toLowerCase())}</option>)}
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>
                            )}
                            {(employeesData == false || employeesData == null) ? ("No Employee Available") : (
                                <div className="col-md-6">
                                    <FloatingLabel controlId="floatingSelect" label="Employee">
                                        <Form.Select name="employee" id="select_employee" aria-label="Floating label SELECT example" required onChange={(e) => { setSelectedEmployee(e.target.value); getAssignedProjectsData() }}>
                                            <option value="" selected>SELECT Employee</option>
                                            {employeesData.map((employee) => <option value={employee.employee_id}>{ucwords(employee.employee_name.toLowerCase())}</option>)}
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>
                            )}

                            <div className="col-md-12 mt-3" id="div_assigning_data" style={divAssigningDataStyle} >
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className="assign_project_input_td text-center">Status</th>
                                            <th className="assign_project_input_td text-center">Leads Ratio</th>
                                            <th className="assign_project_input_td text-center">Sunday</th>
                                            <th className="assign_project_input_td text-center">Monday</th>
                                            <th className="assign_project_input_td text-center">Tuesday</th>
                                            <th className="assign_project_input_td text-center">Wednesday</th>
                                            <th className="assign_project_input_td text-center">Thursday</th>
                                            <th className="assign_project_input_td text-center">Friday</th>
                                            <th className="assign_project_input_td text-center">Saturday</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="assign_project_input_td text-center"><Form.Check id="check_status" name="status" type="switch" value='1' checked={checkStatus ? "checked" : ""} onChange={(e) => setCheckStatus(e.target.checked)} /></td>
                                            <td className="assign_project_input_td text-center"><input type="number" name="leads_ratio" min="0" value={leadsRatio} onChange={(e) => setLeadsRatio(e.target.value)} /></td>
                                            <td className="assign_project_input_td text-center"><Form.Check id="check_sunday" name="sunday" type="switch" value='1' checked={checkSunday ? "checked" : ""} onChange={(e) => setCheckSunday(e.target.checked)} /></td>
                                            <td className="assign_project_input_td text-center"><Form.Check id="check_monday" name="monday" type="switch" value='1' checked={checkMonday ? "checked" : ""} onChange={(e) => setCheckMonday(e.target.checked)} /></td>
                                            <td className="assign_project_input_td text-center"><Form.Check id="check_tuesday" name="tuesday" type="switch" value='1' checked={checkTuesday ? "checked" : ""} onChange={(e) => setCheckTuesday(e.target.checked)} /></td>
                                            <td className="assign_project_input_td text-center"><Form.Check id="check_wednesday" name="wednesday" type="switch" value='1' checked={checkWednesday ? "checked" : ""} onChange={(e) => setCheckWednesday(e.target.checked)} /></td>
                                            <td className="assign_project_input_td text-center"><Form.Check id="check_thursday" name="thursday" type="switch" value='1' checked={checkThursday ? "checked" : ""} onChange={(e) => setCheckThursday(e.target.checked)} /></td>
                                            <td className="assign_project_input_td text-center"><Form.Check id="check_friday" name="friday" type="switch" value='1' checked={checkFriday ? "checked" : ""} onChange={(e) => setCheckFriday(e.target.checked)} /></td>
                                            <td className="assign_project_input_td text-center"><Form.Check id="check_saturday" name="saturday" type="switch" value='1' checked={checkSaturday ? "checked" : ""} onChange={(e) => setCheckSaturday(e.target.checked)} /></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div>
                                    <input type="submit" value="Submit" className="btn btn-success" />
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
                <div className="container-fluid">
                    <div className="col-12 my-2">
                        {(selectedAssignedData) ? (
                            <div className="div_assigned_projects_list">
                                <table id="assigned_projects_list" className="display table table-striped table-bordered compact" width="100%" >
                                    <thead className="bg-dark text-white">
                                        <tr>
                                            <th>#</th>
                                            <th>Project</th>
                                            <th>Employee ID</th>
                                            <th>Employee Name</th>
                                            <th>Status</th>
                                            <th>Leads Ratio</th>
                                            <th>Created by</th>
                                            <th>Created at</th>
                                            <th>Updated by</th>
                                            <th>Updated at</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td></td></tr>
                                        {selectedAssignedData.map((assignedProject, index) =>
                                            <tr key={assignedProject.id} >
                                                <td className="assigned_projects_list_td">{index + 1}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.builder_project_name) ? ucwords(assignedProject.builder_project_name.toLowerCase()) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.employee_id) ? (assignedProject.employee_id) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.employee_name) ? ucwords(assignedProject.employee_name.toLowerCase()) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.status) ? ((assignedProject.status == "1" || assignedProject.status == 1) ? ("Active") : "Inactive") : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.leads_ratio) ? (assignedProject.leads_ratio) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.create_name) ? (ucwords(assignedProject.create_name.toLowerCase())) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.create_datetime) ? context_data.datetime_value(assignedProject.create_datetime) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.update_name) ? (ucwords(assignedProject.update_name.toLowerCase())) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.update_datetime) ? context_data.datetime_value(assignedProject.update_datetime) : "-"}</td>
                                                <td className="assigned_projects_list_td"> <input type="button" className="projects_list_btn btn btn-info m-0 pt-0 pb-0 pl-1 pr-1" onClick={() => deleteAssignedProject(assignedProject.id)} value="Delete" />  </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : ""}
                        {(!selectedAssignedData && allAssignedData) ? (
                            <div className="div_assigned_projects_list">
                                <table id="all_assigned_projects_list" className="display table table-striped table-bordered compact" width="100%" >
                                    <thead className="bg-dark text-white">
                                        <tr>
                                            <th>#</th>
                                            <th>Project</th>
                                            <th>Employee ID</th>
                                            <th>Employee Name</th>
                                            <th>Status</th>
                                            <th>Leads Ratio</th>
                                            <th>Created by</th>
                                            <th>Created at</th>
                                            <th>Updated by</th>
                                            <th>Updated at</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allAssignedData.map((assignedProject, index) =>
                                            <tr key={assignedProject.id} >
                                                <td className="assigned_projects_list_td">{index + 1}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.builder_project_name) ? ucwords(assignedProject.builder_project_name.toLowerCase()) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.employee_id) ? (assignedProject.employee_id) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.employee_name) ? ucwords(assignedProject.employee_name.toLowerCase()) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.status) ? ((assignedProject.status == "1" || assignedProject.status == 1) ? ("Active") : "Inactive") : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.leads_ratio) ? (assignedProject.leads_ratio) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.create_name) ? (ucwords(assignedProject.create_name.toLowerCase())) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.create_datetime) ? context_data.datetime_value(assignedProject.create_datetime) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.update_name) ? (ucwords(assignedProject.update_name.toLowerCase())) : "-"}</td>
                                                <td className="assigned_projects_list_td">{(assignedProject.update_datetime) ? context_data.datetime_value(assignedProject.update_datetime) : "-"}</td>
                                                <td className="assigned_projects_list_td"> <input type="button" className="projects_list_btn btn btn-info m-0 pt-0 pb-0 pl-1 pr-1" onClick={() => deleteAssignedProject(assignedProject.id)} value="Delete" />  </td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        ) : ""}
                    </div>
                </div>
            </section>
        </>
    )
}

export default AssignEmployeeProject