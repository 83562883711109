import React, { useContext, useEffect, useState } from 'react'
import { AgGridReact } from "ag-grid-react";
import { ContextData } from './Components/ContextData';
import axios from 'axios';
import ucwords from 'ucwords';
import { useLocation, useNavigate } from 'react-router-dom';
import CachedIcon from '@mui/icons-material/Cached';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const moment = require('moment');

const Test = () => {
    const context_data = useContext(ContextData)
    const [leadsData, setLeadsData] = useState([]);
    const [leadsCount, setLeadsCount] = useState(0);
    var navigate = useNavigate();
    var location = useLocation();

    const [gridApi, setGridApi] = useState(null);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);


    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_LEAD") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getLeadsCount(login_data.employee_type)

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    async function getLeadsCount(employee_type) {
        context_data.setProcessing(true)
        if (employee_type == "MASTERUSER") {
            const getLeadsCountUrl = global.config.server_url + "getLeadsCount.php";
            await axios.post(getLeadsCountUrl, { employee_type: employee_type, all_data: true }).then((getLeadsCountResponse) => {
                if (getLeadsCountResponse.data) {
                    context_data.setProcessing(false)
                    var count = getLeadsCountResponse.data.count
                    setLeadsCount(parseInt(count))
                } else {
                    console.log("error in getting leads count")
                    context_data.setProcessing(false)
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        } else if (employee_type == "EMPLOYEE") {
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            const getLeadsCountUrl = global.config.server_url + "getLeadsCount.php";
            await axios.post(getLeadsCountUrl, { employee_type: employee_type, all_data: true, login_employee_id: login_employee_id }).then((getLeadsCountResponse) => {
                if (getLeadsCountResponse.data) {
                    context_data.setProcessing(false)
                    var count = getLeadsCountResponse.data.count
                    setLeadsCount(parseInt(count))
                } else {
                    console.log("error in getting leads count")
                    context_data.setProcessing(false)
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        }
    }

    const attended_value = (params) => {
        if (params.data.attended_name) { return "Yes" } else { return "No" }
    }

    const openLead = (params) => {
        context_data.setPrevPage(location.pathname)
        let url = "/leadDetails/?id=" + params.data.id;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    const btnRenderer = (params) => {
        let btn = document.createElement("button");
        let url = "/leadDetails/?id=" + params.data.id;
        btn.innerHTML = "Details";
        btn.classList = "table_list_btn btn btn-info"
        btn.onclick = function () {
            context_data.setPrevPage(location.pathname)
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        };
        return btn;
    }

    const columns = [
        { headerName: "ID", field: "sl_no", valueGetter: "node.rowIndex+1", sortable: false, width: 100 },
        { headerName: "Lead ID", field: "id", filter: "agTextColumnFilter", width: 100, onCellClicked: openLead, cellClass: "column_lead_id" },
        { headerName: "Lead Added on", field: 'create_datetime', filter: "agDateColumnFilter", valueGetter: context_data.aggrid_datetime_value },
        { headerName: "Lead Owner", field: 'lead_owner_name', filter: "agTextColumnFilter", valueGetter: context_data.aggrid_ucwords_value, hide: (localStorage.getItem('employee_type').toUpperCase() == "MASTERUER" ? true : false) },
        { headerName: "Name", field: "name", filter: "agTextColumnFilter", valueGetter: context_data.aggrid_ucwords_value },
        { headerName: "Mobile", field: "mobile", filter: "agTextColumnFilter" },
        { headerName: "Project", field: 'project_name', filter: "agTextColumnFilter", valueGetter: context_data.aggrid_ucwords_value },
        { headerName: "Status", field: 'lead_status', filter: "agTextColumnFilter", valueGetter: context_data.aggrid_ucwords_value },
        { headerName: "Next Activity", field: 'next_reminder_activity', filter: "agTextColumnFilter", valueGetter: context_data.aggrid_ucwords_value },
        { headerName: "Attended", field: 'attended_name', valueGetter: attended_value },
        { headerName: "Action", cellRenderer: btnRenderer },

    ]

    const datasource = {
        getRows(params) {
            const { startRow, endRow, filterModel, sortModel } = params.request
            let url = global.config.server_url + "test.php?"
            //Sorting
            if (sortModel.length) {
                const { colId, sort } = sortModel[0]
                url += `_sort_value=${colId}&_sort_order=${sort}&`
            }
            //Filtering
            const filterKeys = Object.keys(filterModel)

            filterKeys.forEach(filter => {
                url += (`${filter}` == "create_datetime" || `${filter}` == "next_reminder_datetime") ? (`${filter}=` + moment(`${filterModel[filter].dateFrom}`, "YYYY-MM-DD H:mm:ss", true).format("YYYY-MM-DD") + `&`) : `${filter}=${filterModel[filter].filter}&`
            })

            //Pagination
            url += `_start_value=${startRow}&_end_value=${endRow}&_user_type=${localStorage.getItem('employee_type')}&_user_id=${localStorage.getItem('employee_id')}`
            fetch(url)
                .then(httpResponse => httpResponse.json())
                .then(response => {
                    params.successCallback(response, parseInt(response[0].count_value));
                })
                .catch(error => {
                    console.error(error);
                    params.failCallback();
                })
        }
    };


    const onGridReady = (params) => {
        setGridApi(params);
        params.api.setServerSideDatasource(datasource);
    }

    const getRowStyle1 = params => {
        var newValue = params.data.next_reminder

        if (newValue) {
            return
        }
        if (params.data.lead_status && (params.data.lead_status.toLowerCase() == "incoming")) {
            return { color: "#842029", backgroundColor: "#f8d7da", borderColor: "#f5c2c7" };
        }
    }

    const [refreshTime, setRefreshTime] = useState(moment(new Date()).format("DD-MMM-YYYY h:mm:ss A"));

    const refreshPage = () => {
        let current_datetime = moment(new Date()).format("DD-MMM-YYYY h:mm:ss A");
        setRefreshTime(current_datetime);
        setLeadsData([])
        getLeadsCount(loginData.employee_type);
    }

    const rowStyle = { background: 'black' };

    // set background colour on even rows again, this looks bad, should be using CSS classes
    const getRowStyle = params => {
        console.log(params)
        if (params.node.rowIndex % 2 === 0) {
            console.log("tasdf")
            return { background: 'red' };
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Leads</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 d-flex mb-2">
                        <div onClick={() => { document.getElementById("span_refresh").value = "Refreshing..."; refreshPage(); }} style={{ cursor: "pointer" }}>
                            <span id="span_refresh">Last refreshed at: {refreshTime} </span>
                            <CachedIcon className="refreshIcon" onClick={() => { document.getElementById("span_refresh").value = "Refreshing..."; refreshPage(); }} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h3 className='text-center'>All Leads</h3>
                    </div>
                    <div className="col-md-4 text-end">
                        <input className='btn btn-primary mx-auto' type="button" value="Add New Lead" onClick={() => navigate("/addLead")} />
                    </div>
                </div>
            </div>
            {leadsCount > 0 ?
                <div className="ag-theme-alpine" style={{ height: "75vh", width: "100%" }}>
                    <AgGridReact
                        rowData={leadsData}
                        onGridReady={onGridReady}
                        columnDefs={columns}
                        rowModelType='serverSide'
                        pagination={true}
                        paginationPageSize={10}
                        domLayout="autoHeight"
                        defaultColDef={context_data.aggridLeadsDefaultColDef}
                        serverSideStoreType='partial'
                        ref={context_data.aggridRef}
                    />
                </div>
                : "No Leads"}
        </div>
    )
}

export default Test