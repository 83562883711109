import React, { useContext, useEffect } from 'react'
import { Breadcrumb, FloatingLabel, Form, InputGroup } from 'react-bootstrap'
import { ContextData } from './Components/ContextData';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment/moment';
import axios from 'axios';
import ucwords from 'ucwords';

const SpendAdd = () => {
    const context_data = useContext(ContextData)

    var navigate = useNavigate();

    const [selectedPurpose, setSelectedPurpose] = useState("marketing")

    const [amount, setAmount] = useState("");
    const [cgst, setCgst] = useState("9");
    const [cgstValue, setCgstValue] = useState("");
    const [sgst, setSgst] = useState("9");
    const [sgstValue, setSgstValue] = useState("");
    const [totalValue, setTotalValue] = useState("");
    const [campaignDate, setCampaignDate] = useState("");

    const [loginData, setLoginData] = useState();
    const [userRightStatus, setUserRightStatus] = useState();
    const [employeesData, setEmployeesData] = useState();

    const [projectsData, setProjectsData] = useState();
    const [currentDate, setCurrentDate] = useState(moment(new Date()).format("YYYY-MM-DD"))

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_SPEND") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getAllEmployees();
            getAllProjects();
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }

    }, [])

    const getAllEmployees = async () => {
        context_data.setProcessing(true)
        const getAllEmployeesUrl = global.config.server_url + "getEmployees.php";
        await axios.post(getAllEmployeesUrl, { masteruser: true }).then((allEmployeesResponse) => {
            if (allEmployeesResponse.data.length > 0) {
                setEmployeesData(allEmployeesResponse.data);
                context_data.setProcessing(false)
            } else {
                setEmployeesData(false);
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); setEmployeesData(false); context_data.setProcessing(false) });
    }

    const getAllProjects = async () => {
        context_data.setProcessing(true)
        const getAllProjectsUrl = global.config.server_url + "getProjects.php";
        axios.post(getAllProjectsUrl, { all_data_active: true }).then((allProjectsResponse) => {
            if (allProjectsResponse.data.length > 0) {
                setProjectsData(allProjectsResponse.data);
                context_data.setProcessing(false)
            } else {
                setProjectsData(false);
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); setProjectsData(false); context_data.setProcessing(false) });
    }

    async function submitAddSpend(e) {
        e.preventDefault();
        context_data.setProcessing(true);

        var purpose = e.target.purpose.value;
        var lead_source = e.target.lead_source.value;
        var campaign_id = e.target.campaign_id.value;
        var campaign_name = e.target.campaign_name.value;
        var campaign_date = e.target.campaign_date.value;
        var campaign_start_date = e.target.campaign_start_date.value;
        var campaign_end_date = e.target.campaign_end_date.value;
        var leads_received = e.target.leads_received.value;
        var amount = e.target.amount.value;
        var cgst = e.target.cgst.value;
        var cgst_value = e.target.cgst_value.value;
        var sgst = e.target.sgst.value;
        var sgst_value = e.target.sgst_value.value;
        var total_value = e.target.total_value.value;
        var remarks = e.target.remarks.value;

        var project = projectsData.find(obj => (obj.builder_project_id == e.target.project.value));

        var submitAddSpendData = {
            purpose: purpose,
            builder_id: project.builder_id,
            builder_name: project.builder_name,
            project_id: project.builder_project_id,
            project_name: project.builder_project_name,
            lead_source: lead_source,
            campaign_id: campaign_id,
            campaign_name: campaign_name,
            campaign_date: campaign_date,
            campaign_start_date: campaign_start_date,
            campaign_end_date: campaign_end_date,
            leads_received: leads_received,
            amount: amount,
            cgst: cgst,
            cgst_value: cgst_value,
            sgst: sgst,
            sgst_value: sgst_value,
            total_value: total_value,
            remarks: remarks,
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
        }

        var submitAddSpendUrl = global.config.server_url + "addSpend.php";
        axios.post(submitAddSpendUrl, submitAddSpendData).then((response) => {
            if (response.data === true) {
                alert("Spend Added");
                context_data.setProcessing(false);
                navigate({ pathname: "/allSpends", state: { login_data: loginData } });
            } else {
                alert("Error in Adding Spend");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Adding Spend"); context_data.setProcessing(false); });

    }

    function handleAmountChange(e) {
        var amount_val = parseFloat(e.target.value);
        setAmount(Math.round(amount_val * 100) / 100);

        var cgst_val = cgst ? parseFloat(cgst) : 0;
        var sgst_val = sgst ? parseFloat(sgst) : 0;

        var cgst_res = amount_val * cgst_val / 100;
        var sgst_res = amount_val * sgst_val / 100;
        var total_res = parseFloat(amount_val) + parseFloat(cgst_res) + parseFloat(sgst_res)

        var cgst_res = Math.round(cgst_res * 100) / 100
        setCgstValue(cgst_res)
        var sgst_res = Math.round(sgst_res * 100) / 100
        setSgstValue(sgst_res)
        var total_res = Math.round(total_res * 100) / 100
        setTotalValue(total_res)

    }

    function handleCgstChange(e) {
        var cgst_val = parseFloat(e.target.value);
        setCgst(Math.round(cgst_val * 100) / 100);

        var amount_val = amount ? parseFloat(amount) : 0;
        var sgst_val = sgst ? parseFloat(sgst) : 0;

        var cgst_res = amount_val * cgst_val / 100;
        var sgst_res = amount_val * sgst_val / 100;
        var total_res = parseFloat(amount_val) + parseFloat(cgst_res) + parseFloat(sgst_res)

        var cgst_res = Math.round(cgst_res * 100) / 100
        setCgstValue(cgst_res)
        var sgst_res = Math.round(sgst_res * 100) / 100
        setSgstValue(sgst_res)
        var total_res = Math.round(total_res * 100) / 100
        setTotalValue(total_res)
    }

    function handleSgstChange(e) {
        var sgst_val = parseFloat(e.target.value);
        setSgst(Math.round(sgst_val * 100) / 100);

        var amount_val = amount ? parseFloat(amount) : 0;
        var cgst_val = cgst ? parseFloat(cgst) : 0;

        var cgst_res = amount_val * cgst_val / 100;
        var sgst_res = amount_val * sgst_val / 100;
        var total_res = parseFloat(amount_val) + parseFloat(cgst_res) + parseFloat(sgst_res)

        var cgst_res = Math.round(cgst_res * 100) / 100
        setCgstValue(cgst_res)
        var sgst_res = Math.round(sgst_res * 100) / 100
        setSgstValue(sgst_res)
        var total_res = Math.round(total_res * 100) / 100
        setTotalValue(total_res)

    }

    return (
        <section id="section_spend_add">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate("/allSpends")}>Spends</Breadcrumb.Item>
                            <Breadcrumb.Item active>Add Spend</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <div className="container">
                <form onSubmit={submitAddSpend}>
                    <div className="row ">
                        <div className="col-md-12">
                            <fieldset className='fieldset_info'>
                                <legend className='fieldset_info_legend'>Add Spend Information</legend>
                                <div className="row">
                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingPurpose" label="Purpose *">
                                            <Form.Select name="purpose" id="select_purpose" required onChange={(e) => { setSelectedPurpose(e.target.value); }}>
                                                <option value="marketing">Marketing</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </div>
                                    {projectsData && (
                                        <div className="col-md-3 my-2">
                                            <FloatingLabel controlId="floatingProject" label="Project *">
                                                <Form.Select name="project" id="select_project" required >
                                                    <option value="" disabled selected>Select Project</option>
                                                    {projectsData.map((project) => <option value={project.builder_project_id}>{ucwords(project.builder_project_name.toLowerCase())}</option>)}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                    )}
                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingLeadSoruce" label="Lead Source *">
                                            <Form.Select name="lead_source" id="select_lead_source" required >
                                                <option value="" disabled selected>Select Lead Source</option>
                                                {global.config.lead_source_list.map((source) => <option value={source}>{source}</option>)}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </div>

                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingCampaignID" label="Campaign ID *">
                                            <Form.Control type="text" placeholder="campaign_id" required name="campaign_id" />
                                        </FloatingLabel>
                                    </div>

                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingCampaignName" label="Campaign Name *">
                                            <Form.Control type="text" placeholder="campaign_name" required name="campaign_name" />
                                        </FloatingLabel>
                                    </div>

                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingCampaignDate" label="Campaign Date *">
                                            <Form.Control type="date" placeholder="Campaign Date" required name="campaign_date" max={currentDate} />
                                        </FloatingLabel>
                                    </div>

                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingStartDate" label="Campaign Start Date *">
                                            <Form.Control type="date" placeholder="Campaign Start Date" required name="campaign_start_date" max={currentDate} />
                                        </FloatingLabel>
                                    </div>

                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingEndDate" label="Campaign End Date *">
                                            <Form.Control type="date" placeholder="Campaign End Date" required name="campaign_end_date" max={currentDate} />
                                        </FloatingLabel>
                                    </div>

                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingValue" label="Amount in INR *">
                                            <Form.Control type="number" placeholder="Amount in INR" min="1" required name="amount" value={amount} onChange={handleAmountChange} />
                                        </FloatingLabel>
                                    </div>
                                    <div className="col-md-3 my-2">
                                        <div className="row">
                                            <div className="col-6">
                                                <FloatingLabel controlId="floatingCGST" label="CGST *">
                                                    <Form.Control type="number" placeholder="CGST" min="0" step="0.01" required name="cgst" value={cgst} onChange={handleCgstChange} />
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-6">
                                                <FloatingLabel controlId="floatingCGSTValue" label="CGST Value">
                                                    <Form.Control type="number" placeholder="CGST Value" readOnly required name="cgst_value" value={cgstValue} />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 my-2">
                                        <div className="row">
                                            <div className="col-6">
                                                <FloatingLabel controlId="floatingSGST" label="SGST *">
                                                    <Form.Control type="number" placeholder="SGST" min="0" step="0.01" required name="sgst" value={sgst} onChange={handleSgstChange} />
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-6">
                                                <FloatingLabel controlId="floatingCGSTValue" label="SGST Value">
                                                    <Form.Control type="number" placeholder="SGST Value" readOnly required name="sgst_value" value={sgstValue} />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingTotalValue" label="Total Value">
                                            <Form.Control type="number" placeholder="Total Value" readOnly required name="total_value" value={totalValue} />
                                        </FloatingLabel>
                                    </div>
                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingLeadsReceived" label="Leads Received">
                                            <Form.Control type="number" name="leads_received" min="0" placeholder="Leads Received" />
                                        </FloatingLabel>
                                    </div>
                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingRemarks" label="Remarks">
                                            <Form.Control type="text" placeholder="Remarks" name="remarks" />
                                        </FloatingLabel>
                                    </div>
                                    <div className="col-md-3 my-2">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                    </div>
                </form >
            </div >

        </section >
    )
}

export default SpendAdd