import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

const ProjectDetails = () => {
    const [modalProjectDetails, setModalProjectDetails] = useState(false);

    const openProjectDetailsModal = (e) => {
        setModalProjectDetails(true);
    }
    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size='lg'
                show={modalProjectDetails}
                onHide={() => setModalProjectDetails(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Project Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Project Details
                </Modal.Body>
            </Modal>

            {/* <input type="button" className="m-2" value="Project Details" onClick={(e) => { openProjectDetailsModal(e); }} /> */}
        </>
    )
}

export default ProjectDetails