import React, { useContext } from 'react'
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { CSVLink, CSVDownload } from "react-csv";
import { FloatingLabel, Form, Modal } from 'react-bootstrap';

var moment = require('moment')

const SpendsList = (props) => {

	const context_data = useContext(ContextData)

	var navigate = useNavigate();

	const [spendsData, setSpendsData] = useState(false);
	const [loginData, setLoginData] = useState(false);
	const [userRightStatus, setUserRightStatus] = useState(false);


	useEffect(() => {
		if (localStorage.getItem("login_data")) {
			setLoginData(localStorage.getItem("login_data"))
			var login_data = JSON.parse(localStorage.getItem("login_data"))
			setLoginData(login_data)

			var isAvailable;

			if (login_data.employee_type == "MASTERUSER") {
				isAvailable = true;
			} else if (login_data.employee_rights) {
				var employee_rights_arr = (login_data.employee_rights).trim().split(",");
				var regex = new RegExp(employee_rights_arr.join("|"), "i");
				isAvailable = regex.test("ADD_SPEND") || login_data.employee_type == "MASTERUSER";
			} else {
				navigate("/noAccess")
			}
			setUserRightStatus(isAvailable)
			if (isAvailable != true) { navigate("/noAccess") }
			getSpends(login_data.employee_type);

		} else {
			navigate("/logout")
		}

		if (!localStorage.getItem("employee_id")) {
			navigate("/logout");
		}
	}, [])

	async function getSpends(employee_type) {
		context_data.setProcessing(true)
		if (employee_type == "MASTERUSER") {
			const getSpendsListUrl = global.config.server_url + "getSpends.php";
			await axios.post(getSpendsListUrl, { all_data: true }).then((getSpendsListResponse) => {
				if (getSpendsListResponse.data) {
					setSpendsData(getSpendsListResponse.data);
					context_data.setProcessing(false)
				} else {
					alert("error in getting data")
					context_data.setProcessing(false)
				}
			}).catch(err => { console.log(err); context_data.setProcessing(false) });
		} else if (employee_type == "EMPLOYEE") {
			var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));

			const getSpendsListUrl = global.config.server_url + "getSpends.php";
			await axios.post(getSpendsListUrl, { employee_id: login_employee_id }).then((getSpendsListResponse) => {
				if (getSpendsListResponse.data) {
					setSpendsData(getSpendsListResponse.data);
					context_data.setProcessing(false)
				} else {
					alert("error in getting data")
					context_data.setProcessing(false)
				}
			}).catch(err => { console.log(err); context_data.setProcessing(false) });
		}

	}

	const onFilterTextBoxChanged = useCallback(() => {
		context_data.aggridRef.current.api.setQuickFilter(
			document.getElementById('filter-text-box').value
		);
	}, []);


	function indian_number_format(params) {
		var column_name = (params.column.colId);
		var data = params.data[column_name];
		if (data) {
			var number = parseFloat(data)
			return number.toLocaleString('en-IN', {
				maximumFractionDigits: 0,
				style: "currency", currency: "INR"
			});
		} else {
			return "-"
		}
	}

	function percentage_format(params) {
		var column_name = (params.column.colId);
		var data = params.data[column_name];
		if (data) {
			return data + "%"
		} else {
			return "-"
		}
	}

	const [updateLeadsReceivedModal, setUpdateLeadsReceivedModal] = useState(false);
	const [spendID, setSpendID] = useState("");
	const [leadsReceived, setLeadsReceived] = useState("");

	const updateBtnRenderer = (params) => {
		let action_btn = document.createElement("button");
		action_btn.innerHTML = "Update Leads Received";
		action_btn.classList = "table_list_btn btn btn-info"
		action_btn.onclick = function () {
			handleUpdateLeadsReceivedModal(true);
			setSpendID(params.data.id);
			setLeadsReceived(params.data.leads_received);
		};
		return action_btn;
	}


	const handleUpdateLeadsReceivedModal = (action) => {
		if (action == true) {
			setUpdateLeadsReceivedModal(true)
		} else if (action == false) {
			setSpendID("");
			setLeadsReceived("");
			setUpdateLeadsReceivedModal(false);
		}
	}

	async function submitUpdateLeadsReceived(e) {
		e.preventDefault();

		var spend_id = e.target.spend_id.value;
		var leads_received = parseInt(e.target.leads_received.value);
		var login_employee_id = (localStorage.getItem("employee_id"));
		var login_employee_name = (localStorage.getItem("employee_name"));

		var updateLeadsReceivedData = {
			spend_id: spend_id,
			leads_received: leads_received,
			login_employee_id: login_employee_id,
			login_employee_name: login_employee_name,
		}
		const updateLeadsReceivedUrl = global.config.server_url + "updateLeadsReceived.php";
		await axios.post(updateLeadsReceivedUrl, updateLeadsReceivedData).then((updateLeadsReceivedResponse) => {
			if (updateLeadsReceivedResponse.data) {
				alert("Updated")
				handleUpdateLeadsReceivedModal(false)
				getSpends()
				context_data.setProcessing(false)
			} else {
				alert("error in getting data")
				context_data.setProcessing(false)
			}
		}).catch(err => { console.log(err); context_data.setProcessing(false) });
	}

	return (
		<>
			<Modal
				show={updateLeadsReceivedModal}
				onHide={() => handleUpdateLeadsReceivedModal(false)}
				keyboard={false}
				centered
				scrollable={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Update Leads Received</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={submitUpdateLeadsReceived}>
						<div className="container-fluid">
							<div className="row">
								<div className="col-12">
									<input type="hidden" name="spend_id" className='form-control' value={spendID} required />
								</div>
								<div className="col-md-12 my-2">
									<FloatingLabel controlId="floatingLeadsReceived" label="Leads Received">
										<Form.Control
											type="number"
											name="leads_received"
											min="0"
											value={leadsReceived}
											onChange={(e) => setLeadsReceived(e.target.value)}
											placeholder="Leads Received"
											required
											autoFocus
										/>
									</FloatingLabel>
								</div>
								<div className="col-12">
									<button type="submit" className='btn btn-success mt-2'>Update</button>
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>

			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<Breadcrumb>
							<Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
							<Breadcrumb.Item active>Spends</Breadcrumb.Item>
						</Breadcrumb>
					</div>
				</div>
			</div>

			<div className="container-fluid">
				<div className="row">
					<div className="col-md-4">
						<input className='btn btn-primary' type="button" value="Add New Spend" onClick={() => navigate("/addSpend")} />
						{(spendsData == false || spendsData == null) ? (<div><h3 className="text-center m-5">No Data Available</h3></div>) : (
							<>
								<CSVLink data={spendsData} className='btn btn-primary ms-2' type="button" filename={"spends-list.csv"} >Download Excel</CSVLink>
							</>
						)}
					</div>
					<div className="col-md-4">
						<h3 className='text-center'>All Spends</h3>
					</div>
					<div className="col-md-4">
						<input className='form-control' type="text" id="filter-text-box" placeholder="Search..." onInput={onFilterTextBoxChanged} />
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						{(spendsData == false || spendsData == null) ? (<div><h3 className="text-center m-5">No Data Available</h3></div>) : (
							<>

								<div className="ag-theme-alpine" style={{ height: "75vh", width: "100%" }}>
									<AgGridReact
										defaultColDef={context_data.aggridDefaultColDef}
										rowData={spendsData}
										pagination={true}
										paginationAutoPageSize={true}
										animateRows={true}
										ref={context_data.aggridRef}
										onGridReady={() => context_data.aggridAutoSizeAll(false)}
										onFirstDataRendered={() => context_data.aggridAutoSizeAll(false)}

									>
										<AgGridColumn width="100" headerName="Sl No" valueGetter={"node.rowIndex+1"} ></AgGridColumn>
										{/* <AgGridColumn filter='agTextColumnFilter' headerName="ID" field="id" ></AgGridColumn> */}
										<AgGridColumn filter='agTextColumnFilter' headerName="Purpose" field="purpose" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Builder" field="builder_name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Project" field="project_name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Lead Source" field="lead_source" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Campaign ID" field="campaign_id" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Campaign Name" field="campaign_name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Campaign Date" field="campaign_date" valueGetter={context_data.aggrid_date_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Campaign Start Date" field="campaign_start_date" valueGetter={context_data.aggrid_date_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Campaign End Date" field="campaign_end_date" valueGetter={context_data.aggrid_date_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Leads Received" field="leads_received" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Amount" field="amount" valueGetter={indian_number_format}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="CGST" field="cgst" valueGetter={percentage_format}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="CGST in INR" field="cgst_value" valueGetter={indian_number_format}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="SGST" field="sgst" valueGetter={percentage_format}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="SGST in INR" field="sgst_value" valueGetter={indian_number_format}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Total" field="total_value" valueGetter={indian_number_format}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Remarks" field="remarks" valueGetter={context_data.aggrid_ucwords_value}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Created By" field="create_name" valueGetter={context_data.aggrid_ucwords_value}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Created On" field="create_datetime" valueGetter={context_data.aggrid_datetime_value}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Updated By" field="update_name" valueGetter={context_data.aggrid_ucwords_value}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Updated On" field="update_datetime" valueGetter={context_data.aggrid_datetime_value}></AgGridColumn>
										<AgGridColumn headerName="Actions" cellRenderer={updateBtnRenderer} ></AgGridColumn>
									</AgGridReact>
								</div>
							</>
						)}
					</div>
				</div>
			</div>

		</>
	)
}

export default SpendsList