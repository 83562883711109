
module.exports = global.config = {
    // server_url: "https://poolserver.poolcrm.co.in/poolserver/", 
    // public_folder_path: "https://www.poolcrm.co.in/",

    server_url: "/server/",
    // server_url: "http://localhost/poolcrm_react_php/public/server/",

    lead_source_list: ["99acres", "Adpushup", "Adgebra", "Facebook", "Google", "Housing", "Instagram", "IVR", "JustDial", "MagicBricks", "Outbrain", "Reference", "SEO", "Sulekha", "Taboola", "Website", "Whatsapp", "Youtube"],
    location_list: ["Central Bangalore", "North Bangalore", "South Bangalore", "East Bangalore", " West Bangalore", "Out Station"],

    employee_designation_list: ["CRM Manager", "CRM Executive", "Zonal Manager", "Sales Manager", "Team Leader", "Sales Executive", "Pre Sales Executive", "HR"],
    report_to_list: ["Masteruser", "CRM Manager", "CRM Executive", "Zonal Manager", "Sales Manager", "Team Leader", "Sales Executive", "Pre Sales Executive"],

    employee_rights_list: [
        { title: "Add / Edit Lead", empRight: "ADD_LEAD" },
        { title: "Reassign Lead", empRight: "REASSIGN_LEADS" },
        { title: "Add / Edit Employee", empRight: "ADD_EMPLOYEE" },
        { title: "Assign Employee", empRight: "ASSIGN_EMPLOYEE" },
        { title: "Add / Edit Project", empRight: "ADD_PROJECT" },
        { title: "Add / Edit Builder", empRight: "ADD_BUILDER" },
        { title: "Add / Edit Campaign ID", empRight: "ADD_PROJECT_CAMPAIGN" },
        { title: "Add / Update Spend", empRight: "ADD_SPEND" },
        { title: "Marketing", empRight: "MARKETING" },
    ],
    closed_reasons_list: [
        { reason: "switched off / not reachable" },
        { reason: "lost to competitor" },
        { reason: "wrong enquiry" },
        { reason: "plan drop" },
        { reason: "low budget" },
        { reason: "location mismatch" },
        { reason: "project mismatch" },
        { reason: "already purchased" },
        { reason: "not interested" },
        { reason: "duplicate lead" },
    ],
};      