import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import ucwords from 'ucwords';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContextData } from './ContextData';

var moment = require("moment");

const HomeReminders = () => {
    var location = useLocation();
    const context_data = useContext(ContextData)

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            getAllRemindersEmployee(login_data.employee_id);
            const interval = setInterval(() => {
                getAllRemindersEmployee(login_data.employee_id);
            }, 300000);
            return () => clearInterval(interval);
        } else {
            navigate("/logout")
        }

    }, [])

    var navigate = useNavigate();

    const [allReminders, setAllReminders] = useState(null);
    const [selectedReminders, setSelectedReminders] = useState(null);

    const getAllRemindersEmployee = async (employee_id) => {
        context_data.setProcessing(true)
        const getAllRemindersEmployeeUrl = global.config.server_url + "getAllRemindersEmployee.php";
        axios.post(getAllRemindersEmployeeUrl, { login_employee_id: employee_id }).then((allRemindersResponse) => {
            if (allRemindersResponse.data != false) {
                setAllReminders(allRemindersResponse.data);
                setSelectedReminders(allRemindersResponse.data);
                context_data.setProcessing(false)
            } else {
                setAllReminders(false);
                setSelectedReminders(false);
                context_data.setProcessing(false)
            }
        }).catch(err => {
            console.log(err);
            context_data.setProcessing(false)
        });

        var x = window.matchMedia("(max-width: 700px)")

        if (!(x.matches)) {
            if (localStorage.getItem("employee_type") != "MASTERUSER") {
                context_data.setRightSideNavState(true)
            }
        } else {
            context_data.setRightSideNavState(false)
        }
    }

    const showReminderData = (reminder, showData) => {
        try {
            let reminderData = JSON.parse(reminder);
            return reminderData[showData];
        } catch (error) {
            console.log(reminder, showData)
            console.log(error)
            return "invalid data";
        }
    }

    const showReminderDate = (reminder) => {
        let reminderData = JSON.parse(reminder);
        let value = reminderData.reminder
        if (moment(value).isValid()) {
            return moment(value).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(value, "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            return moment(value, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(value, "YYYY-MM-DD H:mm:ss", true).isValid()) {
            return moment(value, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            return value
        }
    }

    const compareDate = (stringDate) => {
        var string_date = "";

        if (moment(stringDate).isValid()) {
            string_date = moment(stringDate).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(stringDate, "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            string_date = moment(stringDate, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(stringDate, "YYYY-MM-DD H:mm:ss", true).isValid()) {
            string_date = moment(stringDate, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            string_date = stringDate
        }
        var result = ((moment(string_date, "DD-MMM-YYYY h:mm:ss A")).diff(moment()) < 0)
        return result
    }

    const handlePendingActivityChange = (e) => {
        var val = (e.target.value)
        if (val == "all") {
            setSelectedReminders(allReminders)
        } else if (val == "followup") {
            var foundValue = allReminders.filter(reminder => ((showReminderData(reminder.next_reminder, "activity")).toLowerCase() == "followup"));
            setSelectedReminders(foundValue)
        } else if (val == "sitevisit") {
            var foundValue = allReminders.filter(reminder => ((showReminderData(reminder.next_reminder, "activity")).toLowerCase() == "sitevisit"));
            setSelectedReminders(foundValue)
        }
    }

    const openLead = (lead_id) => {
        context_data.setPrevPage(location.pathname)
        let url = "/leadDetails/?id=" + lead_id;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (
        <div className="div_home_reminders border border-dark" >
            <div className="container-fluid">

                <div className="row home_reminders_heading">
                    <div className="col-8">
                        <h4 className="text-center "><b>PENDING ACTIVITY LIST</b> </h4>
                    </div>
                    <div className="col-4">
                        <select className='form-select' onChange={handlePendingActivityChange}>
                            <option value="all">All</option>
                            <option value="followup">Followup</option>
                            <option value="sitevisit">Sitevisit</option>
                        </select>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12">
                        <div id="div_home_pending_activity">

                            {(selectedReminders == false || selectedReminders == null) ? (<div><h6 className="text-center m-5">No Reminders</h6></div>) :
                                (selectedReminders.map((reminder, index) =>
                                    <div
                                        key={index}
                                        onClick={() => openLead(reminder.id)}
                                        className={(compareDate(showReminderData(reminder.next_reminder, "reminder"))) ? "py-1 pending_activity row border mt-1 mb-2 alert alert-danger" : "py-1 pending_activity row border mt-1 mb-2"} >
                                        <div className="col-12 div_pending_activty">
                                            <label name="nextReminder_activity" id="nextReminder_activity">
                                                <b>{ucwords(showReminderData(reminder.next_reminder, "activity"))}:</b>
                                                &nbsp;<b>{(reminder.name)}&nbsp;({(reminder.id)})</b>
                                            </label><br />
                                            <label name="nextReminder_reminder" id="nextReminder_reminder">Reminder: {(showReminderDate(reminder.next_reminder))}</label>

                                        </div>
                                        {/* <div className="col-2 p-0 my-auto">
                <input type="button" onClick={() => { navigate({ pathname: "/leadDetails", search: 'id=' + reminder.id, state: { id: reminder.id } }) }} className="leads_list_btn w-auto btn btn-success px-1 " value="Details" />
            </div> */}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default HomeReminders