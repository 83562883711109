import { Autocomplete, Button, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ucwords from 'ucwords';
import { ContextData } from './Components/ContextData';

import { FloatingLabel, Form } from 'react-bootstrap';

var moment = require('moment')

const $ = require('jquery');
$.DataTable = require('datatables.net');

const ReassignLeads = () => {

    const context_data = useContext(ContextData)
    var navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        getEmployeesFromLeads();
        getAllActiveProjects();

    }, [])

    const [employeesList, setEmployeesList] = useState(false);
    const [projectsList, setProjectsList] = useState(false);
    const [assignEmployeesList, setAssignEmployeesList] = useState(false);

    const [assignFromEmployee, setAssignFromEmployee] = useState("");
    const [assignFromProject, setAssignFromProject] = useState(false);
    const [assignToEmployee, setAssignToEmployee] = useState(false);
    const [assignToProject, setAssignToProject] = useState(false)
    const [selectedLeadStatus, setSelectedLeadStatus] = useState([]);

    const [allProjectsList, setAllProjectsList] = useState(false)

    const [changeReassignDate, setChangeReassignDate] = useState(false);

    const getAllActiveProjects = async () => {
        context_data.setProcessing(true)
        const getProjectListUrl = global.config.server_url + "getProjects.php";
        await axios.post(getProjectListUrl, { all_data_active: true }).then((projectListResponse) => {
            if (projectListResponse.data.length > 0) {
                setAllProjectsList(projectListResponse.data);
                context_data.setProcessing(false)
            } else {
                setAllProjectsList(false);
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); setAllProjectsList(false); });
    }

    const getEmployeesFromLeads = async () => {
        context_data.setProcessing(true)
        const getEmployeesUrl = global.config.server_url + "getEmployeesFromLeads.php";
        await axios.post(getEmployeesUrl, { all_data: true }).then((getEmployeesResponse) => {
            if (getEmployeesResponse.data) {
                setEmployeesList(getEmployeesResponse.data);
                context_data.setProcessing(false);
                var search_employee_id = searchParams.get("employee_id");
                if (search_employee_id) {
                    getProjectsFromLeads(search_employee_id)
                }
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const getProjectsFromLeads = async (lead_owner_id) => {
        setProjectsList(false)
        setAssignFromProject(false)
        setAssignEmployeesList(false);
        setAssignToEmployee(false);
        setEmployeeLeadsData(false);

        context_data.setProcessing(true)
        const getProjectsUrl = global.config.server_url + "getProjectsFromLeads.php";
        await axios.post(getProjectsUrl, { lead_owner_id: lead_owner_id }).then((getProjectsResponse) => {
            if (getProjectsResponse.data) {
                setProjectsList(getProjectsResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });

        setAssignFromEmployee(lead_owner_id)
        handleStatusChange(lead_owner_id)
    }

    const getEmployeesFromProjectEmployees = async (selected_project_id) => {
        setAssignEmployeesList(false);
        setAssignToEmployee(false);

        context_data.setProcessing(true)
        const getEmployeesFromProjectEmployeesUrl = global.config.server_url + "getEmployeesFromProjectEmployees.php";
        await axios.post(getEmployeesFromProjectEmployeesUrl, { project_id: selected_project_id }).then((getEmployeesFromProjectEmployeesResponse) => {
            if (getEmployeesFromProjectEmployeesResponse.data) {
                setAssignEmployeesList(getEmployeesFromProjectEmployeesResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });

    }

    const getLeadStatusFromLeads = async (selected_project = assignFromProject) => {
        var lead_owner_id = assignFromEmployee ? assignFromEmployee.lead_owner_id : "";
        var project_id = selected_project.project_id;

        context_data.setProcessing(true)
        if (lead_owner_id && project_id) {
            const getLeadStatusUrl = global.config.server_url + "getLeadStatusFromLeads.php";
            await axios.post(getLeadStatusUrl, { lead_owner_id: lead_owner_id, project_id: project_id }).then((getLeadStatusResponse) => {
                if (getLeadStatusResponse.data) {
                    setLeadStatusList(getLeadStatusResponse.data);
                    context_data.setProcessing(false)
                } else {
                    alert("error in getting data")
                    context_data.setProcessing(false)
                    setLeadStatusList(['Incoming', 'Warm', 'Prospect', 'Booked', 'Closed']);
                }
            }).catch(err => {
                console.log(err); context_data.setProcessing(false)
                setLeadStatusList(['Incoming', 'Warm', 'Prospect', 'Booked', 'Closed']);
            });

        } else {
            setLeadStatusList(['Incoming', 'Warm', 'Prospect', 'Booked', 'Closed']);
        }
    }


    const showLeadOwnerData = (employeeData) => {
        var id = employeeData.lead_owner_id ? employeeData.lead_owner_id.toString().toUpperCase() : "";
        var name = employeeData.lead_owner_name ? ucwords((employeeData.lead_owner_name.toString()).toLowerCase()) : "";
        return id + " : " + name;
    }

    const showEmployeeData = (employeeData) => {
        var id = employeeData.employee_id ? employeeData.employee_id.toString().toUpperCase() : "";
        var name = employeeData.employee_name ? ucwords((employeeData.employee_name.toString()).toLowerCase()) : "";
        return id + " : " + name;
    }

    const showProjectData = (projectData) => {
        var id = projectData.project_id ? projectData.project_id.toString().toUpperCase() : "";
        var name = projectData.project_name ? ucwords((projectData.project_name.toString()).toLowerCase()) : "";
        return id + " : " + name;
    }

    const showAllProjectData = (projectData) => {
        if (projectData.builder_project_id) {
            var id = projectData.builder_project_id ? projectData.builder_project_id.toString().toUpperCase() : "";
            var name = projectData.builder_project_name ? ucwords((projectData.builder_project_name.toString()).toLowerCase()) : "";
            return id + " : " + name;
        } else if (projectData.project_id) {
            var data = showProjectData(projectData)
            return data;
        } else {
            return "";
        }
    }

    const [leadStatusList, setLeadStatusList] = useState(false);

    const handleLeadStatusChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedLeadStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const submitReassignLeads = async (e) => {
        e.preventDefault();
        setReassignResult([])

        var assignFromEmployeeId = assignFromEmployee;
        var assignFromProjectId = assignFromProject;

        var assignFromEmployeeData = employeesList.filter(obj => (obj.lead_owner_id.toUpperCase() == assignFromEmployeeId.toUpperCase()));
        var assignFromEmployeeName = assignFromEmployeeData[0].lead_owner_name;
        var assignFromProjectData = allProjectsList.filter(obj => (obj.builder_project_id == assignFromProjectId));
        var assignFromProjectName = assignFromProjectData[0].builder_project_name

        var assignToProjectId = e.target.assign_to_project.value;
        var assignToProjectData = allProjectsList.filter(obj => (obj.builder_project_id == assignToProjectId));
        var assignToProjectName = assignToProjectData[0].builder_project_name;
        var assignToBuilderId = assignToProjectData[0].builder_id;
        var assignToBuilderName = assignToProjectData[0].builder_name;

        var assignToEmployeeId = e.target.assign_to_employee.value;

        var inputIncoming = e.target.input_incoming ? e.target.input_incoming.value : 0;
        var inputProspect = e.target.input_prospect ? e.target.input_prospect.value : 0;
        var inputWarm = e.target.input_warm ? e.target.input_warm.value : 0;
        var inputClosed = e.target.input_closed ? e.target.input_closed.value : 0;
        var inputBooked = e.target.input_booked ? e.target.input_booked.value : 0;

        var closedIncoming = (e.target.check_closed_incoming && e.target.check_closed_incoming != undefined) ? (e.target.check_closed_incoming.checked ? "true" : "false") : "";
        var deleteHistory = (e.target.check_delete_history && e.target.check_delete_history != undefined) ? (e.target.check_delete_history.checked ? "true" : "false") : "";

        var check_reassign_date = e.target.check_reassign_date.checked;
        var reassign_date = e.target.reassign_date ? e.target.reassign_date.value : "";

        var reassign_leads_ids = [];

        var reassign_incoming = incomingIds.slice(0, inputIncoming);
        reassign_leads_ids = reassign_leads_ids.concat(reassign_incoming);

        var reassign_prospect = prospectIds.slice(0, inputProspect);
        reassign_leads_ids = reassign_leads_ids.concat(reassign_prospect);

        var reassign_warm = warmIds.slice(0, inputWarm);
        reassign_leads_ids = reassign_leads_ids.concat(reassign_warm);

        var reassign_closed = closedIds.slice(0, inputClosed);
        reassign_leads_ids = reassign_leads_ids.concat(reassign_closed);

        var reassign_booked = bookedIds.slice(0, inputBooked);
        reassign_leads_ids = reassign_leads_ids.concat(reassign_booked);

        for (let i = 0; i < reassign_leads_ids.length; i++) {
            const reassign_id = reassign_leads_ids[i];

            var reassignLeadsData = {
                assignFromEmployeeId: assignFromEmployeeId,
                assignFromEmployeeName: assignFromEmployeeName,
                assignFromProjectId: assignFromProjectId,
                assignFromProjectName: assignFromProjectName,
                assignToProjectId: assignToProjectId,
                assignToProjectName: assignToProjectName,
                assignToBuilderId: assignToBuilderId,
                assignToBuilderName: assignToBuilderName,
                assignToEmployeeId: assignToEmployeeId,
                id: reassign_id,
                check_reassign_date: check_reassign_date,
                reassign_date: reassign_date,
                closedIncoming: closedIncoming,
                deleteHistory: deleteHistory,
                login_employee_id: (localStorage.getItem("employee_id")),
                login_employee_name: (localStorage.getItem("employee_name")),
            }

            const submitReassignLeadsUrl = global.config.server_url + "reassignLeads.php";
            context_data.setProcessing(true)

            await axios.post(submitReassignLeadsUrl, reassignLeadsData).then((response) => {
                if (response.data == true) {
                    var result = reassignResult;
                    result.push("Reassigned Lead: " + reassign_id);
                    setReassignResult(result);
                } else if (response.data == "No Leads") {
                    result.push("No Leads Found");
                } else {
                    result.push("Error in reassign lead: " + reassign_id);
                }
            }).catch(err => { console.log(err); alert("Error in Reassigning Leads"); });
        }
        context_data.setProcessing(false);
        handleSelectChange();
    }

    const [reassignResult, setReassignResult] = useState([])

    const [dateType, setDateType] = useState("all_data");
    const [dateFrom, setDateFrom] = useState(moment(new Date()).format("yyyy-MM-DD"))
    const [maxDateFrom, setMaxDateFrom] = useState(moment(new Date()).format("yyyy-MM-DD"))
    const [dateTo, setDateTo] = useState(moment(new Date()).format("yyyy-MM-DD"))
    const [minDateTo, setMinDateTo] = useState("")

    const handleDateTypeChange = (e) => {
        setDateType(e.target.value)
        handleSelectChange()
    }

    const handleDateFromChange = (e) => {
        var check_in = e.target.value
        setDateFrom(check_in)
        var check_out = moment(check_in).add(1, "days").format("yyyy-MM-DD")
        setMinDateTo(check_out)
        handleSelectChange()
    }

    const handleDateToChange = (e) => {
        var check_out = e.target.value
        setDateTo(check_out)

        var check_in = moment(check_out).subtract(1, "days").format("yyyy-MM-DD")
        setMaxDateFrom(check_in)
        handleSelectChange()
    }

    const handleSelectChange = () => {
        document.getElementById("btn_form_submit").click()
    }

    const [reportRequestDate, setReportRequestDate] = useState("All Data")
    const [selectedEmployeeId, setSelectedEmployeeId] = useState("")

    const getLeadsCount = async (e) => {
        e.preventDefault();

        var type = e.target.date_type ? e.target.date_type.value : "all_data";
        var employee_id = e.target.assign_from_employee ? e.target.assign_from_employee.value : "";
        var project_id = e.target.assign_from_project ? e.target.assign_from_project.value : "";

        setSelectedEmployeeId(employee_id)

        var getReassignLeadsCountData = "";

        switch (type) {
            case "all_data":
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, all_data: true }
                setReportRequestDate("All Data")
                break;
            case "today":
                var start_date = moment(new Date()).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }
                var showData = moment(new Date()).format("DD-MMM-YYYY")
                setReportRequestDate(showData)
                break;
            case "yesterday":
                var date = moment(new Date()).subtract(1, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(date).format("YYYY-MM-DD")
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }
                var showData = moment(date).format("DD-MMM-YYYY")
                setReportRequestDate(showData)
                break;
            case "this_week":
                var week_start_date = moment().startOf('week').format("YYYY-MM-DD")
                var week_end_date = moment(new Date()).format("YYYY-MM-DD")

                var start_date = moment(week_start_date).format("YYYY-MM-DD")
                var end_date = week_end_date
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(week_start_date).format("DD-MMM-YYYY")) + " - " + (moment(week_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)
                break;
            case "last_7_days":
                var date = moment(new Date()).subtract(7, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_week":
                var date = moment(new Date()).subtract(1, "week").toDate()
                var week_start_date = moment(date).startOf('week').format("YYYY-MM-DD")
                var week_end_date = moment(date).endOf('week').format("YYYY-MM-DD")

                var start_date = moment(week_start_date).format("YYYY-MM-DD")
                var end_date = moment(week_end_date).format("YYYY-MM-DD")
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(week_start_date).format("DD-MMM-YYYY")) + " - " + (moment(week_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_14_days":
                var date = moment(new Date()).subtract(14, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "this_month":
                var month_start_date = moment().startOf('month').format("YYYY-MM-DD")
                var month_end_date = moment(new Date()).format("YYYY-MM-DD")

                var start_date = moment(month_start_date).format("YYYY-MM-DD")
                var end_date = month_end_date
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(month_start_date).format("DD-MMM-YYYY")) + " - " + (moment(month_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_30_days":
                var date = moment(new Date()).subtract(30, "days").toDate()
                var start_date = moment(date).format("YYYY-MM-DD")
                var end_date = moment(new Date()).format("YYYY-MM-DD")
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_month":
                var date = moment(new Date()).subtract(1, "month").toDate()
                var month_start_date = moment(date).startOf('month').format("YYYY-MM-DD")
                var month_end_date = moment(date).endOf('month').format("YYYY-MM-DD")

                var start_date = moment(month_start_date).format("YYYY-MM-DD")
                var end_date = moment(month_end_date).format("YYYY-MM-DD")
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(month_start_date).format("DD-MMM-YYYY")) + " - " + (moment(month_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "this_year":
                var year_start_date = moment().startOf('year').format("YYYY-MM-DD")
                var year_end_date = moment(new Date()).format("YYYY-MM-DD")

                var start_date = moment(year_start_date).format("YYYY-MM-DD")
                var end_date = year_end_date
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(year_start_date).format("DD-MMM-YYYY")) + " - " + (moment(new Date()).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;
            case "last_year":
                var date = moment(new Date()).subtract(1, "year").toDate()
                var year_start_date = moment(date).startOf('year').format("YYYY-MM-DD")
                var year_end_date = moment(date).endOf('year').format("YYYY-MM-DD")

                var start_date = moment(year_start_date).format("YYYY-MM-DD")
                var end_date = moment(year_end_date).format("YYYY-MM-DD")
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(year_start_date).format("DD-MMM-YYYY")) + " - " + (moment(year_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)

                break;

            case "custom_date":
                var start_date = e.target.date_from ? e.target.date_from.value : moment(new Date()).format("YYYY-MM-DD");
                var end_date = e.target.date_to ? e.target.date_to.value : moment(new Date()).format("YYYY-MM-DD");
                getReassignLeadsCountData = { employee_id: employee_id, project_id: project_id, start_date: start_date, end_date: end_date }

                var showData = (moment(year_start_date).format("DD-MMM-YYYY")) + " - " + (moment(year_end_date).format("DD-MMM-YYYY"))
                setReportRequestDate(showData)
                break;
            default:
                alert("Error")
                setReportRequestDate("")
                break;
        }

        const getReassignLeadsCountUrl = global.config.server_url + "getReassignLeadsCount.php";
        await axios.post(getReassignLeadsCountUrl, getReassignLeadsCountData).then((getReassignLeadsCountResponse) => {
            if (getReassignLeadsCountResponse.data) {
                context_data.setProcessing(false)

                var var_incoming_ids = getReassignLeadsCountResponse.data.Incoming ? getReassignLeadsCountResponse.data.Incoming.split(",") : [];
                var var_prospect_ids = getReassignLeadsCountResponse.data.Prospect ? getReassignLeadsCountResponse.data.Prospect.split(",") : [];
                var var_warm_ids = getReassignLeadsCountResponse.data.Warm ? getReassignLeadsCountResponse.data.Warm.split(",") : [];
                var var_booked_ids = getReassignLeadsCountResponse.data.Booked ? getReassignLeadsCountResponse.data.Booked.split(",") : [];
                var var_closed_ids = getReassignLeadsCountResponse.data.Closed ? getReassignLeadsCountResponse.data.Closed.split(",") : [];
                var var_all_ids = getReassignLeadsCountResponse.data.IDs ? getReassignLeadsCountResponse.data.IDs.split(",") : [];

                setAllIds(var_all_ids);
                setIncomingIds(var_incoming_ids);
                setProspectIds(var_prospect_ids);
                setWarmIds(var_warm_ids);
                setBookedIds(var_booked_ids);
                setClosedIds(var_closed_ids);

                setTotalCount(var_all_ids.length);
                setIncomingCount(var_incoming_ids.length);
                setProspectCount(var_prospect_ids.length);
                setWarmCount(var_warm_ids.length);
                setBookedCount(var_booked_ids.length);
                setClosedCount(var_closed_ids.length);

                setRemIncomingCount(var_incoming_ids.length);
                setRemProspectCount(var_prospect_ids.length);
                setRemWarmCount(var_warm_ids.length);
                setRemBookedCount(var_booked_ids.length);
                setRemClosedCount(var_closed_ids.length);

            } else {
                console.log("error in getting reassigning leads count")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const [allIds, setAllIds] = useState([]);
    const [incomingIds, setIncomingIds] = useState([]);
    const [prospectIds, setProspectIds] = useState([]);
    const [warmIds, setWarmIds] = useState([]);
    const [bookedIds, setBookedIds] = useState([]);
    const [closedIds, setClosedIds] = useState([]);

    const [totalCount, setTotalCount] = useState(0);
    const [incomingCount, setIncomingCount] = useState(0);
    const [prospectCount, setProspectCount] = useState(0);
    const [warmCount, setWarmCount] = useState(0);
    const [bookedCount, setBookedCount] = useState(0);
    const [closedCount, setClosedCount] = useState(0);

    const handleAssignToProjectChange = (project_id) => {
        setAssignToProject(project_id)
        getEmployeesFromProjectEmployees(project_id);
    }

    const [remIncomingCount, setRemIncomingCount] = useState(0);
    const [inputIncoming, setInputIncoming] = useState(0);
    const handleInputIncomingChange = (e) => {
        var data = parseInt(e.target.value);
        if (e.target.value == "") {
            setInputIncoming(0)
        } else if (data <= remIncomingCount && data >= 0) {
            setInputIncoming(data)
        }

    }

    const [remProspectCount, setRemProspectCount] = useState(0);
    const [inputProspect, setInputProspect] = useState(0);
    const handleInputProspectChange = (e) => {
        var data = parseInt(e.target.value);
        if (e.target.value == "") {
            setInputProspect(0)
        } else if (data <= remProspectCount && data >= 0) {
            setInputProspect(data)
        }
    }

    const [remWarmCount, setRemWarmCount] = useState(0);
    const [inputWarm, setInputWarm] = useState(0);
    const handleInputWarmChange = (e) => {
        var data = parseInt(e.target.value);
        if (e.target.value == "") {
            setInputWarm(0)
        } else if (data <= remWarmCount && data >= 0) {
            setInputWarm(data)
        }
    }

    const [remClosedCount, setRemClosedCount] = useState(0);
    const [inputClosed, setInputClosed] = useState(0);
    const handleInputClosedChange = (e) => {
        var data = parseInt(e.target.value);
        if (e.target.value == "") {
            setInputClosed(0)
        } else if (data <= remClosedCount && data >= 0) {
            setInputClosed(data)
        }
    }

    const [remBookedCount, setRemBookedCount] = useState(0);
    const [inputBooked, setInputBooked] = useState(0);
    const handleInputBookedChange = (e) => {
        var data = parseInt(e.target.value);
        if (e.target.value == "") {
            setInputBooked(0)
        } else if (data <= remBookedCount && data >= 0) {
            setInputBooked(data)
        }
    }

    const [employeeLeadsData, setEmployeeLeadsData] = useState(false);

    const handleStatusChange = async (employee_id) => {
        context_data.setProcessing(true)
        const getLeadsCountUrl = global.config.server_url + "getLeadsCountWithGroup.php";
        await axios.post(getLeadsCountUrl, { employee_id: employee_id, all_data: true }).then((getLeadsCountResponse) => {
            if (getLeadsCountResponse.data) {
                context_data.setProcessing(false)
                var data = getLeadsCountResponse.data;
                setEmployeeLeadsData(data)
            } else {
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }


    // const AddElement = (param) => {
    //     var intId = $("#buildyourform div.fieldwrapper").length + 1;
    //     var fieldWrapper = $("<div class=\"fieldwrapper\" id=\"field" + intId + "\"/>").hide();

    //     var html_data = $('<div class="my-2 p-2 border" style="position:relative; border-radius: 4px"> ' +
    //         '<div class="p-2" style="display: flex;"> ' +
    //         '<div class="mx-1"><label><b>Assign To </b></label></div> ' +
    //         '<div class="form-floating">'
    //     );


    //     var project_list = $(
    //         '<select class="form-select" id="floatingSelect" aria-label="Floating label select example">' +
    //         '<option value="" disabled selected>Select Assign From Project</option>'
    //     );

    //     allProjectsList.map((project) => {
    //         project_list.append('<option value="' + project.builder_project_id + '">' + ucwords(project.builder_project_name.toLowerCase()) + '</option>')
    //     })

    //     project_list.append('</select>' +
    //         '<label for="floatingSelect">Works with selects</label>' +
    //         '</div>' +
    //         '</div>'
    //     )

    //     html_data.append(project_list);

    //     html_data.append('<div style=""><button id="remove" style="position:absolute; top: 10px; right: 10px" class="btn btn-danger ">Remove</button></div>');

    //     html_data.find("#remove").click(function () {
    //         $(this).parent().parent().parent().remove();
    //     });

    //     html_data.find("#floatingSelect").on("change", function () {
    //         var age_data = $('<div class="div_child mx-1" id="div_child_" style="transition:display 0.5s">' +
    //             '<div class="form-floating">' +
    //             '<select class="form-select" id="floatingSelect" aria-label="Floating label select example">' +
    //             '<option selected>Open this select menu</option>' +
    //             '<option value="1">One</option>' +
    //             '<option value="2">Two</option>' +
    //             '<option value="3">Three</option>' +
    //             '</select>' +
    //             '</div>'
    //         );
    //         html_data.append(age_data)
    //     });

    //     fieldWrapper.append(html_data);

    //     $("#buildyourform").append(fieldWrapper);
    //     fieldWrapper.show(50);

    // }

    $(document).ready(function () {
        // let table_active_leads = $('#table_active_leads').DataTable({ scrollX: true });
        $('#table_active_leads').DataTable()
        $.fn.dataTable.ext.errMode = 'none';
    });

    return (

        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Reassign Leads</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <form onSubmit={getLeadsCount}>
                <div className="container">
                    <fieldset className='fieldset_info'>
                        <legend className='fieldset_info_legend'>Assign From</legend>

                        <div className="row">

                            {(employeesList == false || employeesList == null) ? ("No Employee Available") : (
                                <div className="col-md-3 my-2">
                                    <FloatingLabel controlId="floatingSelect" label="Assign From Employee">
                                        <Form.Select name="assign_from_employee" id="assign_from_employee" aria-label="Floating label SELECT example" required value={assignFromEmployee} onChange={(e) => { getProjectsFromLeads(e.target.value); }}>
                                            <option value="" disabled selected>Select Assign From Employee</option>
                                            {employeesList.map((employee) => <option value={employee.lead_owner_id.toUpperCase()}>{ucwords(employee.lead_owner_name.toLowerCase() + " : " + (employee.lead_owner_id.toUpperCase()))}</option>)}
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>
                            )}

                            {projectsList && projectsList.length > 0 && (
                                <>
                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingSelect" label="Assign From Project">
                                            <Form.Select name="assign_from_project" id="assign_from_project" aria-label="Floating label SELECT example" required onChange={(e) => { setAssignFromProject(e.target.value); handleSelectChange(); }}>
                                                <option value="" disabled selected>Select Assign From Project</option>
                                                {projectsList.map((project) => <option value={project.project_id ? project.project_id : "-"}>{project.project_name ? ucwords(project.project_name.toLowerCase()) : "-"}</option>)}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </div>

                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingSelect" label="View">
                                            <Form.Select name="date_type" id="select_date_type" aria-label="Floating label Select view"
                                                onChange={handleDateTypeChange}>
                                                <option value="all_data" selected>All Data</option>
                                                <option value="today">Today</option>
                                                <option value="yesterday">Yesterday</option>
                                                <option value="this_week">This Week</option>
                                                <option value="last_7_days">Last 7 days</option>
                                                <option value="last_week">Last Week</option>
                                                <option value="last_14_days">Last 14 days</option>
                                                <option value="this_month">This Month</option>
                                                <option value="last_30_days">Last 30 days</option>
                                                <option value="last_month">Last Month</option>
                                                <option value="this_year">This Year</option>
                                                <option value="last_year">Last Year</option>
                                                <option value="custom_date">Custom Date</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </div>
                                    {(dateType == "custom_date") && (
                                        <div className="col-md-3 my-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-floating">
                                                        <input type="date" name="date_from" className="form-control" id="floatingInput" placeholder="Date From" max={maxDateFrom} value={dateFrom} required onChange={handleDateFromChange} />
                                                        <label for="floatingInput">From</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating">
                                                        <input type="date" name="date_to" className="form-control" id="floatingInput" placeholder="Date To" min={minDateTo} value={dateTo} required onChange={handleDateToChange} />
                                                        <label for="floatingInput">To</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                    {totalCount > 0 ? (
                                        <div className="col-md-12 my-2">
                                            <table className='table table-bordered w-auto'>
                                                <thead>
                                                    <tr>
                                                        {totalCount > 0 && <td>Total</td>}
                                                        {incomingCount > 0 && <td>Incoming</td>}
                                                        {prospectCount > 0 && <td>Prospect</td>}
                                                        {warmCount > 0 && <td>Warm</td>}
                                                        {closedCount > 0 && <td>Closed</td>}
                                                        {bookedCount > 0 && <td>Booked</td>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        {totalCount > 0 && <td>{totalCount}</td>}
                                                        {incomingCount > 0 && <td>{incomingCount}</td>}
                                                        {prospectCount > 0 && <td>{prospectCount}</td>}
                                                        {warmCount > 0 && <td>{warmCount}</td>}
                                                        {closedCount > 0 && <td>{closedCount}</td>}
                                                        {bookedCount > 0 && <td>{bookedCount}</td>}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {/* <div>
                                            <button className="btn btn-primary" type="button" onClick={() => AddElement()}> Add Assign to</button>
                                        </div> */}
                                        </div>
                                    ) : (
                                        <div className="col-md-12 my-2">
                                            No Leads Available
                                        </div>
                                    )}
                                </>
                            )}

                        </div>
                    </fieldset>
                </div>
                <input type="submit" value="submit" id="btn_form_submit" style={{ display: "none" }} />

            </form>
            {totalCount > 0 && (
                <form onSubmit={submitReassignLeads}>
                    <div className="container">
                        <fieldset className='fieldset_info'>
                            <legend className='fieldset_info_legend'>Assign To</legend>

                            <div className="row">
                                {allProjectsList &&
                                    <div className="col-md-3  my-2">
                                        <FloatingLabel controlId="floatingSelect" label="Assign To Project">
                                            <Form.Select name="assign_to_project" id="assign_to_project" aria-label="Floating label SELECT example" required onChange={(e) => { handleAssignToProjectChange(e.target.value); }}>
                                                <option value="" disabled selected>Select Assign From Project</option>
                                                {allProjectsList.map((project) => <option value={project.builder_project_id}>{ucwords(project.builder_project_name.toLowerCase())}</option>)}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </div>
                                }
                                {(assignEmployeesList == false || assignEmployeesList == null) ? ("No Employee Available") : (
                                    <div className="col-md-3 my-2">
                                        <FloatingLabel controlId="floatingSelect" label="Assign To Employee">
                                            <Form.Select name="assign_to_employee" id="assign_to_employee" aria-label="Floating label SELECT example" required onChange={(e) => setAssignToEmployee(e.target.value)} >
                                                <option value="" disabled selected>Select Assign To Employee</option>
                                                {assignEmployeesList.map((employee) => <option value={employee.employee_id}>{ucwords(employee.employee_name.toLowerCase() + " : " + (employee.employee_id.toUpperCase()))}</option>)}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </div>
                                )}

                                {assignToEmployee && (
                                    <>
                                        <table className='table table-bordered w-auto'>
                                            <thead>
                                                <tr>
                                                    {incomingCount > 0 && <td>Incoming</td>}
                                                    {prospectCount > 0 && <td>Prospect</td>}
                                                    {warmCount > 0 && <td>Warm</td>}
                                                    {closedCount > 0 && <td>Closed</td>}
                                                    {bookedCount > 0 && <td>Booked</td>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {incomingCount > 0 && <td><input className='form-control' type='number' id="input_incoming" name="input_incoming" onChange={handleInputIncomingChange} value={inputIncoming} /></td>}
                                                    {prospectCount > 0 && <td><input className='form-control' type='number' id="input_prospect" name="input_prospect" onChange={handleInputProspectChange} value={inputProspect} /></td>}
                                                    {warmCount > 0 && <td><input className='form-control' type='number' id="input_warm" name="input_warm" onChange={handleInputWarmChange} value={inputWarm} /></td>}
                                                    {closedCount > 0 && <td><input className='form-control' type='number' id="input_closed" name="input_closed" onChange={handleInputClosedChange} value={inputClosed} /></td>}
                                                    {bookedCount > 0 && <td><input className='form-control' type='number' id="input_booked" name="input_booked" onChange={handleInputBookedChange} value={inputBooked} /></td>}
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="col-md-3 my-2">
                                            <Form.Check
                                                type="switch"
                                                id="reassign-date-switch"
                                                name="check_reassign_date"
                                                label="Change Reassign Date"
                                                onChange={(e) => setChangeReassignDate(e.target.checked)}
                                            />
                                            {changeReassignDate &&
                                                <div className="form-floating">
                                                    <input type="date" name="reassign_date" className="form-control" id="floatingInput" placeholder="Reassign Date" required />
                                                    <label for="floatingInput">Reassign Date</label>
                                                </div>
                                            }
                                        </div>

                                        <div className="col-md-4 my-2">
                                            {(inputClosed > 0) && (
                                                // <div className="col-md-4 my-2">
                                                <Form.Check
                                                    type="switch"
                                                    id="closed-to-incoming-switch"
                                                    name="check_closed_incoming"
                                                    label="Change Closed to Incoming"
                                                />
                                                // </div>
                                            )}

                                            {/* <div className="col-md-4 my-2"> */}
                                            <Form.Check
                                                type="switch"
                                                id="delete-history-switch"
                                                name="check_delete_history"
                                                label="Delete History"
                                            />
                                        </div>

                                        <div>
                                            <button type="submit" className='btn btn-success w-auto'>Submit</button>
                                        </div>
                                    </>
                                )}
                                {/* <fieldset id="buildyourform"></fieldset> */}
                            </div>
                        </fieldset>
                    </div>
                </form>
            )}

            {employeeLeadsData && (
                <div className="container">
                    <div className="row my-2">
                        <div className="col-md-12"><h3 className="text-center">Active Leads in Account</h3></div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="div_active_leads_list">

                                <table className="table table-bordered" id="table_active_leads">
                                    <thead className="table-dark">
                                        <tr>
                                            <td>Sl No</td>
                                            <td>Builder ID</td>
                                            <td>Builder Name</td>
                                            <td>Project ID</td>
                                            <td>Project Name</td>
                                            <td>Total</td>
                                            <td>Incoming</td>
                                            <td>Warm</td>
                                            <td>Prospect</td>
                                            <td>Booked</td>
                                            <td>Closed</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employeeLeadsData.map((leadsData, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{leadsData.builder_id}</td>
                                                <td>{context_data.ucwords_value(leadsData.builder_name)}</td>
                                                <td>{leadsData.project_id}</td>
                                                <td>{context_data.ucwords_value(leadsData.project_name)}</td>
                                                <td>{leadsData.Total}</td>
                                                <td>{leadsData.Incoming}</td>
                                                <td>{leadsData.Warm}</td>
                                                <td>{leadsData.Prospect}</td>
                                                <td>{leadsData.Booked}</td>
                                                <td>{leadsData.Closed}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {reassignResult && reassignResult.length > 0 && <>
                <div className="container">
                    <fieldset className='fieldset_info'>
                        <legend className='fieldset_info_legend'>Result</legend>
                        {reassignResult.map((res) => <p>Result: {res} </p>)}
                    </fieldset>
                </div>
            </>
            }
        </>
    )
}

export default ReassignLeads