import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {

        if (localStorage.getItem("master_login_data")) {
            try {
                console.log("try")
                var master_user_data = JSON.parse(localStorage.getItem("master_login_data"))
                localStorage.setItem('login_data', localStorage.getItem("master_login_data"));
                localStorage.setItem('employee_id', master_user_data.employee_id);
                localStorage.setItem('employee_name', master_user_data.employee_name);
                localStorage.setItem('employee_mobile', master_user_data.employee_mobile);
                localStorage.setItem('employee_email', master_user_data.employee_email);
                localStorage.setItem('employee_type', master_user_data.employee_type);
                localStorage.setItem('master_login_data', "");
                navigate({ pathname: "/" });
            } catch (error) {
                console.log("catch")
                console.log(error)
                localStorage.removeItem('master_login_data');
                localStorage.removeItem('employee_userid');
                localStorage.removeItem('employee_type');
                localStorage.removeItem('employee_name');
                localStorage.removeItem('login_data');
                localStorage.clear()
                navigate({ pathname: "/" });
            }
        } else {
            localStorage.removeItem('master_login_data');
            localStorage.removeItem('employee_userid');
            localStorage.removeItem('employee_type');
            localStorage.removeItem('employee_name');
            localStorage.removeItem('login_data');
            localStorage.clear()
            navigate({ pathname: "/" });
        }
    }, [])

    return (
        <div></div>
    )
}

export default Logout