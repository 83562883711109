import React, { useContext, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { Autocomplete, Button, Stack } from '@mui/material'
import ucwords from 'ucwords'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import moment from 'moment';
import { Modal } from 'react-bootstrap';

const $ = require('jquery');
$.DataTable = require('datatables.net');

const EmployeeEdit = (props) => {

    const context_data = useContext(ContextData)

    var navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    var employeeRightsList = global.config.employee_rights_list

    const [employeeData, setEmployeeData] = useState(false);
    const [allEmployeesData, setAllEmployeesData] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    const [employeeRights, setEmployeeRights] = useState(false)
    const [empJoinDate, setEmpJoinDate] = useState()
    const [employeeType, setEmployeeType] = useState("Employee");

    const [reportToEmployees, setReportToEmployees] = useState([])
    const [reportTo, setReportTo] = useState({ employee_name: "" })

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_EMPLOYEE") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }

            var employee_table_id = searchParams.get("id");

            getAllEmployees()

            getEmployees(employee_table_id);
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }

    }, [])

    async function getEmployees(employee_table_id) {
        context_data.setProcessing(true)
        const getEmployeesUrl = global.config.server_url + "getEmployees.php";
        await axios.post(getEmployeesUrl, { employee_table_id: employee_table_id }).then((getEmployeesResponse) => {
            if (getEmployeesResponse.data) {
                setEmployeeData(getEmployeesResponse.data);
                context_data.setProcessing(false)
                setEmpJoinDate(moment(getEmployeesResponse.data.employee_joindate, "DD-MMM-YYYY"))
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    async function getAllEmployees() {
        context_data.setProcessing(true)
        const getEmployeesUrl = global.config.server_url + "getEmployees.php";
        await axios.post(getEmployeesUrl, { all_data: true }).then((getEmployeesResponse) => {
            if (getEmployeesResponse.data) {
                setAllEmployeesData(getEmployeesResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    const [errorEmployeeId, setErrorEmployeeId] = useState(false);
    const [errorEmployeeMobile, setErrorEmployeeMobile] = useState(false);
    const [errorEmployeeEmail, setErrorEmployeeEmail] = useState(false);

    const checkEmployeeId = (e) => {
        var employee_id = e.target.value.toUpperCase()
        for (var i = 0; i < allEmployeesData.length; i++) {
            if (allEmployeesData[i].employee_id == employee_id) {
                setErrorEmployeeId(true)
                break;
            } else {
                setErrorEmployeeId(false)
            }
        }
    }

    const checkEmployeeMobile = (e) => {
        var employee_mobile = e.target.value.toUpperCase()
        for (var i = 0; i < allEmployeesData.length; i++) {
            if (employeeData.employee_id != allEmployeesData[i].employee_id && allEmployeesData[i].employee_mobile == employee_mobile && allEmployeesData[i].employee_status == "ACTIVE") {
                setErrorEmployeeMobile(true)
                break;
            } else {
                setErrorEmployeeMobile(false)
            }
        }
    }

    const checkEmployeeEmail = (e) => {
        var employee_email = e.target.value.toUpperCase()
        for (var i = 0; i < allEmployeesData.length; i++) {
            if (allEmployeesData[i].employee_email) {
                if (employeeData.employee_id != allEmployeesData[i].employee_id && (allEmployeesData[i].employee_email.toUpperCase()) == employee_email && allEmployeesData[i].employee_status == "ACTIVE") {
                    setErrorEmployeeEmail(true)
                    break;
                } else {
                    setErrorEmployeeEmail(false)
                }
            } else {
                setErrorEmployeeEmail(false)
            }
        }
    }

    const [errorEmployeeRights, setErrorEmployeeRights] = useState(false)
    const submitEditEmployee = (e) => {
        e.preventDefault();

        if (employeeRights && employeeRights.length > 0) {
            setErrorEmployeeRights(false);
        } else {
            setErrorEmployeeRights(true)
            return
        }

        if (errorEmployeeId || errorEmployeeMobile || errorEmployeeEmail) { alert("Clear the errors"); return; }
        else {
            context_data.setProcessing(true);
            var employee_rights_arr = [];
            for (let i = 0; i < employeeRights.length; i++) {
                const element = employeeRights[i];
                employee_rights_arr.push(element.empRight)
            }

            var employee_rights = employee_rights_arr.toString();

            var employee_id = (e.target.employee_id.value).toUpperCase();
            var employee_name = (e.target.employee_name.value).toUpperCase();
            var employee_mobile = (e.target.employee_mobile.value).toUpperCase();
            var employee_email = (e.target.employee_email.value).toUpperCase();
            var employee_gender = (e.target.employee_gender.value).toUpperCase();
            var employee_joindate = (e.target.employee_joindate.value).toUpperCase();
            var employee_type = (e.target.employee_type.value).toUpperCase();
            var employee_designation = e.target.employee_designation ? (e.target.employee_designation.value).toUpperCase() : "";
            var employee_status = (e.target.employee_status.value).toUpperCase();
            var report_to_id = e.target.report_to ? reportTo.employee_id : "";

            var submitEditEmployeeData = {
                id: employeeData.id,
                employee_rights: employee_rights,
                employee_id: employee_id,
                employee_name: employee_name,
                employee_mobile: employee_mobile,
                employee_email: employee_email,
                employee_gender: employee_gender,
                employee_joindate: employee_joindate,
                employee_type: employee_type,
                employee_designation: employee_designation,
                employee_status: employee_status,
                report_to_id: report_to_id,

                login_employee_id: (localStorage.getItem("employee_id")),
                login_employee_name: (localStorage.getItem("employee_name")),

            }

            var submitEditEmployeeUrl = global.config.server_url + "updateEmployee.php";
            axios.post(submitEditEmployeeUrl, submitEditEmployeeData).then((response) => {
                if (response.data === true) {
                    alert("Employee Edited");
                    context_data.setProcessing(false);
                    navigate({ pathname: "/allEmployees", state: { login_data: loginData } });
                } else {
                    alert("Error in Editing Employee");
                    context_data.setProcessing(false);
                }
            }).catch(err => { console.log(err); alert("Error in Editing Employee"); context_data.setProcessing(false); });
        }
    }

    const getEmployeeRights = () => {
        if (employeeData.employee_rights) {
            var employee_rights = employeeData.employee_rights;
            var employee_rights_arr = employee_rights.split(",")
            var result_arr = employeeRightsList.filter(right => employee_rights_arr.includes(right.empRight))
            setEmployeeRights(result_arr)
            return result_arr
        } else {
            setEmployeeRights(result_arr)
            return []
        }
    }

    const getDefaultReportTo = () => {
        var report_to_id = employeeData.report_to_id;
        if (report_to_id) {
            let report_to_data = allEmployeesData.find(emp => emp.employee_id === report_to_id);
            setReportTo(report_to_data)
            return report_to_data;
        } else {
            return { employee_name: "" }
        }
    }

    const getDefaultReportToDesignation = () => {
        var report_to_id = employeeData.report_to_id;
        if (report_to_id) {
            let report_to_data = allEmployeesData.find(emp => emp.employee_id === report_to_id);
            if (report_to_data.employee_type.toLowerCase() == "masteruser") return "Masteruser";
            else return ucwords(report_to_data.employee_designation.toLowerCase());
        } else {
            return []
        }
    }

    const getReportToEmployees = (newValue) => {
        setReportToEmployees(false)
        setReportTo({ employee_name: "" })

        if (newValue.toLowerCase() == "masteruser") {
            var reportToEmployees = allEmployeesData.filter(function (employee) {
                return ((employee.employee_type.toLowerCase() == "masteruser") && (employee.employee_status == "ACTIVE") && (employee.employee_id != employeeData.employee_id));
            });
            setReportToEmployees(reportToEmployees);
        } else {
            var reportToEmployees = allEmployeesData.filter(function (employee) {
                return ((employee.employee_designation.toLowerCase() == newValue.toLowerCase()) && (employee.employee_status == "ACTIVE") && (employee.employee_id != employeeData.employee_id));
            });
            setReportToEmployees(reportToEmployees);
        }

    }

    const [showRedirectModal, setShowRedirectModal] = useState(false);
    const [employeeLeadsData, setEmployeeLeadsData] = useState(false);

    const handleStatusChange = async (newValue) => {
        if (newValue.toLowerCase() == "inactive") {
            context_data.setProcessing(true)
            const getLeadsCountUrl = global.config.server_url + "getLeadsCountWithGroup.php";
            await axios.post(getLeadsCountUrl, { employee_id: employeeData.employee_id, all_data: true }).then((getLeadsCountResponse) => {
                if (getLeadsCountResponse.data) {
                    context_data.setProcessing(false)
                    var data = getLeadsCountResponse.data;
                    if (data && data.length > 0) {
                        setShowRedirectModal(true)
                        setEmployeeLeadsData(data)
                        setEmployeeStatus("Active")
                    } else {
                        setEmployeeStatus(newValue)
                    }
                } else {
                    context_data.setProcessing(false)
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        } else {
            setEmployeeStatus(newValue)
        }
    }

    const [employeeStatus, setEmployeeStatus] = useState("")

    const getDefaultStatus = () => {
        var status = employeeData.employee_status ? ucwords(employeeData.employee_status.toLowerCase()) : "";
        setEmployeeStatus(status)
        return status

    }

    const openInNewTab = (url) => {
        if (url) {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        setShowRedirectModal(false);
    }

    $(document).ready(function () {
        // let table_active_leads = $('#table_active_leads').DataTable({ scrollX: true });
        $('#table_active_leads').DataTable()
        $.fn.dataTable.ext.errMode = 'none';
    });

    return (
        <>
            <Modal
                show={showRedirectModal}
                onHide={() => setShowRedirectModal(false)}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reassign the existing leads to other account before inactive</Modal.Title>
                </Modal.Header>
                {/* <Modal.Footer className="text-end">
                    <button className="btn btn-success" onClick={() => openInNewTab("/reassignLeads")}>Redirect</button>
                </Modal.Footer> */}
            </Modal>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate("/allEmployees")}>Employees</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit Employee</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h3 className='text-center'>Edit Employee</h3>
                    </div>
                </div>
                {employeeData && (
                    <form onSubmit={submitEditEmployee}>
                        <div className="row ">
                            <div className="col-md-12">
                                <fieldset className='fieldset_info'>
                                    <legend className='fieldset_info_legend'>Employee Information</legend>
                                    <div className="row">

                                        <div className="col-md-4 my-2">
                                            <TextField fullWidth label="Emp ID" name="employee_id" required onChange={checkEmployeeId} error={errorEmployeeId} InputLabelProps={{ shrink: true }} defaultValue={employeeData.employee_id ? (employeeData.employee_id.toUpperCase()) : ""} />
                                        </div>
                                        <div className="col-md-4 my-2">
                                            <TextField fullWidth label="Name" name="employee_name" required InputLabelProps={{ shrink: true }} defaultValue={employeeData.employee_name ? ucwords(employeeData.employee_name.toLowerCase()) : ""} />
                                        </div>
                                        <div className="col-md-4 my-2">
                                            <TextField fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="Mobile" name="employee_mobile" required onChange={checkEmployeeMobile} error={errorEmployeeMobile} InputLabelProps={{ shrink: true }} defaultValue={employeeData.employee_mobile ? employeeData.employee_mobile : ""} />
                                        </div>
                                        <div className="col-md-4 my-2">
                                            <TextField fullWidth label="Email" name="employee_email" required onChange={checkEmployeeEmail} error={errorEmployeeEmail} InputLabelProps={{ shrink: true }} defaultValue={employeeData.employee_email ? (employeeData.employee_email.toLowerCase()) : ""} />
                                        </div>
                                        <div className="col-md-4 my-2">
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={["Male", "Female", "Other"]}
                                                defaultValue={employeeData.employee_gender ? ucwords(employeeData.employee_gender.toLowerCase()) : ""}
                                                renderInput={(params) => <TextField {...params} label="Gender" name="employee_gender" required />}
                                            />
                                        </div>
                                        <div className="col-md-4 my-2">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <Stack>
                                                    <MobileDatePicker
                                                        label="Join Date"
                                                        inputFormat="dd-MMM-yyyy"
                                                        onChange={(e) => setEmpJoinDate(e)}
                                                        renderInput={(params) => <TextField required {...params} name="employee_joindate" />}
                                                        value={empJoinDate}
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                        </div>
                                        <div className="col-md-4 my-2">
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={["Employee", "Masteruser"]}
                                                defaultValue={employeeData.employee_type ? ucwords(employeeData.employee_type.toLowerCase()) : ""}
                                                onChange={(e, newValue) => setEmployeeType(newValue)}
                                                renderInput={(params) => <TextField {...params} label="Type" name="employee_type" required />}
                                            />
                                        </div>
                                        {(employeeType == "Employee" &&
                                            <>
                                                <div className="col-md-4 my-2">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={global.config.employee_designation_list}
                                                        defaultValue={employeeData.employee_designation ? ucwords(employeeData.employee_designation.toLowerCase()) : ""}
                                                        renderInput={(params) => <TextField {...params} label="Designation" name="employee_designation" required />}
                                                    />
                                                </div>
                                                <div className="col-md-4 my-2">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={global.config.report_to_list}
                                                        defaultValue={getDefaultReportToDesignation}
                                                        onChange={(e, newValue) => getReportToEmployees(newValue)}
                                                        renderInput={(params) => <TextField {...params} label="Report To (Designation)" name="" required />}
                                                    />
                                                </div>
                                                <div className="col-md-4 my-2">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={reportToEmployees}
                                                        defaultValue={getDefaultReportTo}
                                                        value={reportTo}
                                                        onChange={(e, newValue) => setReportTo(newValue)}
                                                        getOptionLabel={(option) => ucwords((option.employee_name.toString()).toLowerCase())}
                                                        renderInput={(params) => <TextField {...params} label="Report To (Employee)" name="report_to" required />}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        <div className="col-md-4 my-2">
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={["Active", "Inactive"]}
                                                defaultValue={getDefaultStatus}
                                                value={employeeStatus}
                                                onChange={(e, newValue) => handleStatusChange(newValue)}
                                                renderInput={(params) => <TextField {...params} label="Status" value={employeeStatus} name="employee_status" required />}
                                            />
                                        </div>

                                        {(employeeType == "Employee" &&
                                            <div className="col-md-4 my-2">
                                                <Autocomplete
                                                    multiple
                                                    id="tags-outlined"
                                                    options={employeeRightsList}
                                                    getOptionLabel={(option) => option.title}
                                                    onChange={(event, newValue) => {
                                                        setEmployeeRights(newValue);
                                                    }}
                                                    defaultValue={getEmployeeRights}
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Emp Rights"
                                                            placeholder="Emp Rights"
                                                            error={errorEmployeeRights}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}

                                        <div className="col-md-4 my-2">
                                            <Button type="submit" variant="contained" color="success">Submit</Button>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </form>
                )}
            </div>
            {employeeLeadsData && (
                <div className="container">
                    <div className="row my-2">
                        <div className="col-md-4">
                            <button className="btn btn-primary" onClick={() => openInNewTab("/reassignLeads/?employee_id=" + employeeData.employee_id)}>Redirect to Reassign Leads</button>
                        </div>
                        <div className="col-md-4"><h3 className="text-center">Active Leads in Account</h3></div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="div_active_leads_list">

                                <table className="table table-bordered" id="table_active_leads">
                                    <thead className="table-dark">
                                        <tr>
                                            <td>Sl No</td>
                                            <td>Builder ID</td>
                                            <td>Builder Name</td>
                                            <td>Project ID</td>
                                            <td>Project Name</td>
                                            <td>Total</td>
                                            <td>Incoming</td>
                                            <td>Warm</td>
                                            <td>Prospect</td>
                                            <td>Booked</td>
                                            <td>Closed</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employeeLeadsData.map((leadsData, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{leadsData.builder_id}</td>
                                                <td>{context_data.ucwords_value(leadsData.builder_name)}</td>
                                                <td>{leadsData.project_id}</td>
                                                <td>{context_data.ucwords_value(leadsData.project_name)}</td>
                                                <td>{leadsData.Total}</td>
                                                <td>{leadsData.Incoming}</td>
                                                <td>{leadsData.Warm}</td>
                                                <td>{leadsData.Prospect}</td>
                                                <td>{leadsData.Booked}</td>
                                                <td>{leadsData.Closed}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default EmployeeEdit