import { LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Stack, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import ucwords from 'ucwords';
import { ContextData } from './ContextData';

var moment = require('moment')

const ReminderDetails = (props) => {

    const context_data = useContext(ContextData)

    useEffect(() => {
        context_data.setPickerDatetime(context_data.reminderType == "sitevisit" ? moment(new Date()).add((30 - ((moment(new Date())).minute() % 15)), 'm').toDate() : moment(new Date()).add((5 - ((moment(new Date())).minute() % 5)), 'm').toDate())
        checkDates(context_data.pickerDatetime)
    }, [])

    const [sameDate, setSameDate] = useState(true)

    const checkDates = (newValue) => {
        var picker = (moment(newValue).format('yyyy-MM-DD'));
        var current = (moment(new Date()).format('yyyy-MM-DD'))
        var check = (moment(picker).isSame(moment(current)))
        setSameDate(check)
    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>

                    <TextField id="notes" label={"Notes For " + context_data.reminderType} name="notes" variant="outlined" required />
                    <MobileDateTimePicker
                        value={context_data.pickerDatetime}
                        onChange={(newValue) => {
                            context_data.setPickerDatetime(newValue);
                            checkDates(newValue)
                        }}
                        label={context_data.reminderType ? ("SELECT " + ucwords(context_data.reminderType.toLowerCase())) + " Date and Time" : "SELECT Date and Time"}
                        minutesStep={context_data.reminderType == "sitevisit" ? 15 : 5}
                        disablePast
                        minDate={new Date()}
                        minTime={sameDate ? new Date() : new Date(0, 0, 0, 7)}
                        maxTime={new Date(0, 0, 0, 21, 55)}
                        inputFormat={"dd-MMM-yyyy hh:mm a"}
                        mask="__/__/____ __:__ _M"
                        renderInput={(params) => <TextField {...params} />}

                    />
                </Stack>
            </LocalizationProvider>
        </div>

    )
}

export default ReminderDetails