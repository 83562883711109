import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Stack, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { ContextData } from './ContextData';

const CreateNotes = (props) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const context_data = useContext(ContextData)

    useEffect(() => {
        var lead_id = searchParams.get("id");
        setLeadId(lead_id)
    }, [])

    const [leadId, setLeadId] = useState(false)
    const [notesShow, setNotesShow] = useState(false);
    const handleNotesShow = () => { setNotesShow(true); }
    const handleNotesClose = () => { setNotesShow(false); }

    const submitAddNotes = async (e) => {
        e.preventDefault();
        context_data.setProcessing(true);

        const addNotesUrl = global.config.server_url + "addNotes.php";
        let notes = e.target.typeNotes.value;
        notes = notes.replace(/["']/g, "");
        notes = notes.replace(/\s+/g, ' ').trim()

        const addNotesData = {
            notes_data: notes,
            login_employee_id: localStorage.getItem("employee_id"),
            login_employee_name: localStorage.getItem("employee_name"),
            id: (leadId != undefined || leadId != null) ? leadId : searchParams.get("id")
        }

        axios.post(addNotesUrl, addNotesData).then((response) => {
            if (response.data == true) {
                // alert("Notes Added");
                context_data.setProcessing(false);
                handleNotesClose();
                props.getLeadDetails();

            } else {
                alert("Error in Adding Notes");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Adding Notes"); context_data.setProcessing(false); });
    }


    return (
        <>
            {/* Notes Modal */}
            <Modal
                show={notesShow}
                onHide={() => handleNotesClose()}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Notes for Lead</Modal.Title>
                </Modal.Header>
                <form className="form-group" onSubmit={(e) => submitAddNotes(e)}>
                    <Modal.Body>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <TextField label={"Notes "} name="typeNotes" variant="outlined" required />
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="submit" className="btn btn-primary" id="" value="Submit" />
                        {/* <input type="button" className="btn btn-danger" id="" onClick={() => handleNotesClose()} value="Cancel" /> */}
                    </Modal.Footer>
                </form>
            </Modal>

            <input className='m-2' onClick={() => handleNotesShow()} type="button" value="Notes" />
        </>
    )
}

export default CreateNotes