import { Card } from 'react-bootstrap'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ContextData } from './Components/ContextData';
import { useNavigate } from 'react-router-dom';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const Reports = () => {
    var navigate = useNavigate();
    

    const context_data = useContext(ContextData)

    const [projectReportData, setProjectReportData] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    const [userRightsRegex, setUserRightsRegex] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                setUserRightsRegex(regex)
                isAvailable = login_data.employee_type == "EMPLOYEE";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            // getProjectReport("all_data");

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Reports</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    {(userRightsRegex && userRightsRegex.test("ADD_LEAD") || loginData.employee_type == "MASTERUSER") && (<>
                        <div className="col-6 col-md-2 div_reports_leadsummary">
                            <Card onClick={() => navigate("/leadsSummary")}>
                                <SummarizeIcon className='home_fa_icon' />
                                <div className="text-center">Leads Summary</div>
                            </Card>
                        </div>
                    </>)}

                    {(userRightsRegex && userRightsRegex.test("MARKETING") || loginData.employee_type == "MASTERUSER") && (<>
                        <div className="col-6 col-md-2 div_reports_remindersSummary">
                            <Card onClick={() => navigate("/leadSourceReport")}>
                                <TrendingUpIcon className='home_fa_icon' />
                                <div className="text-center">Lead Source Report</div>
                            </Card>
                        </div>
                    </>)}

                    {/* <div className="col-6 col-md-2 div_reports_remindersSummary">
                        <Card onClick={() => navigate("/revenueReport")}>
                            <TrendingUpIcon className='home_fa_icon' />
                            <div className="text-center">Revenue Report</div>
                        </Card>
                    </div> */}

                    {/* <div className="col-6 col-md-2 div_reports_remindersSummary">
                        <Card onClick={() => navigate("/remindersSummary")}>
                            <CalendarTodayIcon className='home_fa_icon' />
                            <div className="text-center">Reminders Summary</div>
                        </Card>
                    </div> */}

                </div>
            </div>

        </>
    )
}

export default Reports