import { Button, Chip, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Autocomplete } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';
import Resizer from 'react-image-file-resizer';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

var moment = require("moment")

const ProjectAdd = (props) => {

	const context_data = useContext(ContextData)

	var navigate = useNavigate();

	const [buildersData, setBuildersData] = useState(false);
	const [loginData, setLoginData] = useState(false);
	const [userRightStatus, setUserRightStatus] = useState(false);
	const [selectedBuilder, setSelectedBuilder] = useState(false);
	const [contactMailId, setContactMailId] = useState([])
	const [facebookCampaignId, setFacebookCampaignId] = useState([])
	const [housingCampaignId, setHousingCampaignId] = useState([])
	const [acres99CampaignId, setAcres99CampaignId] = useState([])
	const [magicbricksCampaignId, setMagicbricksCampaignId] = useState([])

	const [employeesData, setEmployeesData] = useState([]);
	const [employeeValue, setEmployeeValue] = useState({ employee_name: "" });
	const [defaultEmployee, setDefaultEmployee] = useState({ employee_name: "" })
	const [ivrData, setIvrData] = useState([])
	const [errorIvrNumber, setErrorIvrNumber] = useState(false)
	const [ivrNumber, setIvrNumber] = useState([])
	const [allProjectData, setAllProjectData] = useState(null);

	useEffect(() => {
		if (localStorage.getItem("login_data")) {
			setLoginData(localStorage.getItem("login_data"))
			var login_data = JSON.parse(localStorage.getItem("login_data"))
			setLoginData(login_data)

			var isAvailable;

			if (login_data.employee_type == "MASTERUSER") {
				isAvailable = true;
			} else if (login_data.employee_rights) {
				var employee_rights_arr = (login_data.employee_rights).trim().split(",");
				var regex = new RegExp(employee_rights_arr.join("|"), "i");
				isAvailable = regex.test("ADD_PROJECT") || login_data.employee_type == "MASTERUSER";
			} else {
				navigate("/noAccess")
			}
			setUserRightStatus(isAvailable)
			if (isAvailable != true) { navigate("/noAccess") }
			getNextProjectId();
			getAllBuildersData();

			getEmployees(login_data.employee_type)
			// getIvr(login_data.employee_type)
			getAllProjectData(login_data.employee_type)

		} else {
			navigate("/logout")
		}

		if (!localStorage.getItem("employee_id")) {
			navigate("/logout");
		}
	}, [])

	function getEmployees(employee_type) {
		context_data.setProcessing(true)
		const getEmployeesUrl = global.config.server_url + "getEmployees.php";
		axios.post(getEmployeesUrl, { all_data_active: true }).then((getEmployeesResponse) => {
			if (getEmployeesResponse.data) {
				setEmployeesData(getEmployeesResponse.data);
				context_data.setProcessing(false)
			} else {
				alert("error in getting data")
				context_data.setProcessing(false)
			}
		}).catch(err => { console.log(err); context_data.setProcessing(false) });
	}

	function getIvr(employee_type) {
		context_data.setProcessing(true)
		const getIvrUrl = global.config.server_url + "getIvr.php";
		axios.post(getIvrUrl, { all_data_list: true }).then((getIvrResponse) => {
			if (getIvrResponse.data) {
				setIvrData(getIvrResponse.data);
				context_data.setProcessing(false)
			} else {
				alert("error in getting data")
				context_data.setProcessing(false)
			}
		}).catch(err => { console.log(err); context_data.setProcessing(false) });
	}

	const getAllProjectData = async () => {
		const getAllProjectsUrl = global.config.server_url + "getProjects.php";
		axios.post(getAllProjectsUrl, { all_data_active: true }).then((AllProjectsResponse) => {
			if (AllProjectsResponse.data.length) {
				setAllProjectData(AllProjectsResponse.data);
			}
		}).catch(err => console.log(err));
	}

	const [nextProjectId, setNextProjectId] = useState(null);

	const getNextProjectId = async () => {
		const getNextProjectIdUrl = global.config.server_url + "getNextProjectId.php";

		axios.get(getNextProjectIdUrl).then((getNextProjectIdResponse) => {
			if (getNextProjectIdResponse.data === false) {
				console.log(getNextProjectIdResponse.data);
			} else {
				let project_id = getNextProjectIdResponse.data.builder_project_id;
				project_id = parseInt(project_id) + 1;
				project_id = (String("00000000" + project_id).slice(-8));
				setNextProjectId(project_id)
			}
		}).catch(err => { console.log(err); });
	}

	const getAllBuildersData = async () => {
		const getAllBuildersUrl = global.config.server_url + "getBuilders.php";
		axios.post(getAllBuildersUrl, { all_data: true }).then((AllBuildersResponse) => {
			setBuildersData(AllBuildersResponse.data);
		}).catch(err => console.log(err));
	}

	const submitAddProject = (e) => {
		e.preventDefault();

		if (selectedProjectData || errorIvrNumber) {
			alert("Clear the Errors")
		} else {
			const file = e.target.uploadImg.files[0];
			if (!file) {
				alert("Project Logo not selected"); return;
			}

			context_data.setProcessing(true);

			let now = new Date();
			let current_date_time = moment(now).format("YYYYMMDD");

			const image_name = "project" + e.target.project_id.value + "_datetime" + current_date_time + ".png";

			const formData = new FormData();
			formData.append(
				"myFile",
				e.target.uploadImg.files[0],
				image_name
			);

			let project_email = contactMailId.join("||||");
			let acres99_campaign_id = acres99CampaignId.toString();
			let magicbricks_campaign_id = magicbricksCampaignId.toString();
			let housing_campaign_id = housingCampaignId.toString();
			let facebook_campaign_id = facebookCampaignId.toString();
			let ivr_number = ivrNumber.toString();

			var project_status = (e.target.project_status.value).toUpperCase();

			const submitAddProjectUrl = global.config.server_url + "addProject.php";
			const submitAddProjectData = {
				builder_id: selectedBuilder.builder_id,
				builder_name: selectedBuilder.builder_name,
				builder_city: selectedBuilder.builder_city,

				project_id: e.target.project_id.value,
				project_name: e.target.project_name.value,
				project_location: e.target.project_location.value,
				project_area: e.target.project_area.value,
				project_city: e.target.project_city.value,
				project_mail_list: project_email,
				housing_campaign_id: housing_campaign_id,
				facebook_campaign_id: facebook_campaign_id,
				acres99_campaign_id: acres99_campaign_id,
				magicbricks_campaign_id: magicbricks_campaign_id,
				project_logo: image_name,
				sale_type: e.target.sale_type.value,

				project_status: project_status,
				ivr_number: ivr_number ? ivr_number : "",
				default_employee_id: employeeValue.employee_id,
				default_employee_name: employeeValue.employee_name,
				default_employee_mobile: employeeValue.employee_mobile,
				default_employee_email: employeeValue.employee_email,

				login_employee_id: (localStorage.getItem("employee_id")),
				login_employee_name: (localStorage.getItem("employee_name")),
			}
			console.log(submitAddProjectData);

			axios.post(submitAddProjectUrl, submitAddProjectData).then((response) => {
				if (response.data === true) {
					axios.post("/reactimageupload.php", formData).then((response) => {
						console.log(response);
						if (response.data == true) {
							console.log("image uploaded");
							context_data.setProcessing(false);
						} else {
							console.log("Error in uploading image");
							context_data.setProcessing(false);
						}
					}).catch(err => { console.log(err); alert("Error in uploading image"); context_data.setProcessing(false); });

					alert("Project Added");
					context_data.setProcessing(false);
					navigate({ pathname: "/allProjects", state: { login_data: loginData } });
				} else {
					alert("Error in Adding Project");
					context_data.setProcessing(false);
				}
			}).catch(err => { console.log(err); alert("Error in Adding Project"); context_data.setProcessing(false); });
		}
	}

	const processSelImg = (e) => {
		// const file = document.querySelector("#uploadImg").files[0];
		const file = e.target.files[0];
		if (!file) { alert("Project Logo not selected"); return; }

		if (file) {
			try {
				Resizer.imageFileResizer(
					file, 100, 100, 'JPEG', 100, 0,
					uri => {
						document.querySelector("#output").src = uri;
					},
					'base64', 100, 100,
				);
			} catch (err) {
				console.log(err)
			}
		}
	}

	const selImgBtnClick = () => {
		document.getElementById("uploadImg").click()
	}

	const [projectsData, setProjectsData] = useState([])

	function getProjects(newValue) {
		context_data.setProcessing(true)
		const getProjectsUrl = global.config.server_url + "getProjects.php";
		axios.post(getProjectsUrl, { builder_id: newValue.builder_id }).then((getProjectsResponse) => {
			if (getProjectsResponse.data) {
				setProjectsData(getProjectsResponse.data);
				context_data.setProcessing(false)
			} else {
				alert("error in getting data")
				context_data.setProcessing(false)
			}
		}).catch(err => { console.log(err); context_data.setProcessing(false) });
	}

	const [selectedProjectData, setSelectedProjectData] = useState(false)

	const projectNameOnBlur = (e) => {
		var project_name = e.target.value.toUpperCase()
		var found = false;
		var project_data;
		if (projectsData.length > 0) {
			for (var i = 0; i < projectsData.length; i++) {
				if (projectsData[i].builder_project_name == project_name) {
					found = true;
					setSelectedProjectData(projectsData[i])
					break;
				} else {
					setSelectedProjectData(false)
				}
			}
		}
		else {
			setSelectedProjectData(false)
		}
		if (found) {
			console.log(project_data)
		}

	}

	const checkIvrNumber = (e) => {
		setIvrNumber(e.target.value)
		var ivr_number = e.target.value
		if (allProjectData.length > 0 && ivr_number) {
			for (var i = 0; i < allProjectData.length; i++) {
				if (allProjectData[i].ivr_number == ivr_number && allProjectData[i].project_status == "ACTIVE") {
					setErrorIvrNumber(true)
					break;
				} else {
					setErrorIvrNumber(false)
				}
			}
		} else {
			setErrorIvrNumber(false)
		}
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<Breadcrumb>
							<Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
							<Breadcrumb.Item onClick={() => navigate("/allProjects")}>Projects</Breadcrumb.Item>
							<Breadcrumb.Item active>Add Project</Breadcrumb.Item>
						</Breadcrumb>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h3 className='text-center'>Add Project</h3>
					</div>
				</div>
				<form onSubmit={submitAddProject}>
					<div className="row ">
						<div className="col-md-12">
							<fieldset className='fieldset_info'>
								<legend className='fieldset_info_legend'>Project Information</legend>
								<div className="row">

									<div className="col-md-6 my-2">
										{buildersData && (<>
											<Autocomplete
												disablePortal
												id="combo-box-demo"
												options={buildersData}
												onChange={(event, newValue) => {
													setSelectedBuilder(newValue);
													getProjects(newValue);
												}}
												getOptionLabel={(option) => ucwords((option.builder_name.toString()).toLowerCase())}
												renderInput={(params) => <TextField {...params} label="Builder" name="builder" required />}
											/>
										</>
										)}
									</div>
									<div className="col-md-6 my-2">
										<TextField fullWidth label="Project ID" name="project_id" required InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true }} value={nextProjectId} />
									</div>
									<div className="col-md-6 my-2">
										{/* <TextField fullWidth label="Name" name="project_name" required /> */}
										<Autocomplete
											freeSolo

											disableClearable
											options={projectsData}
											onBlur={projectNameOnBlur}
											onChange={(event, newValue) => {
												setSelectedProjectData(newValue)
											}}

											getOptionLabel={(option) => ucwords((option.builder_project_name.toString()).toLowerCase())}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Project Name"
													name="project_name"
													InputProps={{
														...params.InputProps,
														type: 'search',
													}}
													required
													error={selectedProjectData ? true : false}
												/>
											)}
										/>
									</div>
									<div className="col-md-6 my-2">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
											options={global.config.location_list}
                                            renderInput={(params) => <TextField {...params} label="Location" name="project_location" />}
                                        />
                                    </div>

									<div className="col-md-6 my-2">
										<TextField fullWidth label="Area" name="project_area" required />
									</div>
									<div className="col-md-6 my-2">
										<TextField fullWidth label="City" name="project_city" required />
									</div>
									<div className="col-md-6 my-2">
										<Autocomplete
											multiple
											id="tags-filled"
											options={[]}
											freeSolo
											onChange={(event, newValue) => {
												setContactMailId(newValue);
											}}
											renderTags={(value, getTagProps) =>
												value.map((option, index) => (
													<Chip variant="outlined" label={option} {...getTagProps({ index })} />
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label="Contact Mail ID"
													placeholder="Enter Mail ID and Press `Enter` Key"
												/>
											)}
										/>
									</div>
									<div className="col-md-6 my-2">
										<Autocomplete
											multiple
											id="tags-filled"
											options={[]}
											freeSolo
											onChange={(event, newValue) => {
												setFacebookCampaignId(newValue);
											}}
											renderTags={(value, getTagProps) =>
												value.map((option, index) => (
													<Chip variant="outlined" label={option} {...getTagProps({ index })} />
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label="Facebook Campaign ID"
													placeholder="Campaign ID and Press `Enter` Key"
												/>
											)}
										/>
									</div>
									<div className="col-md-6 my-2">
										<Autocomplete
											multiple
											id="tags-filled"
											options={[]}
											freeSolo
											onChange={(event, newValue) => {
												setHousingCampaignId(newValue);
											}}
											renderTags={(value, getTagProps) =>
												value.map((option, index) => (
													<Chip variant="outlined" label={option} {...getTagProps({ index })} />
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label="Housing Campaign ID"
													placeholder="Campaign ID and Press `Enter` Key"
												/>
											)}
										/>
									</div>

									<div className="col-md-6 my-2">
										<Autocomplete
											multiple
											id="tags-filled"
											options={[]}
											freeSolo
											onChange={(event, newValue) => {
												setAcres99CampaignId(newValue)
											}}
											renderTags={(value, getTagProps) =>
												value.map((option, index) => (
													<Chip variant="outlined" label={option} {...getTagProps({ index })} />
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label="Acres 99 ID"
													placeholder="Campaign ID and Press `Enter` Key"
												/>
											)}
										/>
									</div>

									<div className="col-md-6 my-2">
										<Autocomplete
											multiple
											id="tags-filled"
											options={[]}
											freeSolo
											onChange={(event, newValue) => {
												setMagicbricksCampaignId(newValue)
											}}
											renderTags={(value, getTagProps) =>
												value.map((option, index) => (
													<Chip variant="outlined" label={option} {...getTagProps({ index })} />
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label="Magic Bricks ID"
													placeholder="Campaign ID and Press `Enter` Key"
												/>
											)}
										/>
									</div>

									{/* {ivrData && (
                                            <div className="col-md-6 my-2">
                                                <Autocomplete
                                                    multiple
                                                    id="tags-filled"
                                                    options={ivrData}
                                                    getOptionLabel={(option) => option.ivr.toString()}
                                                    onChange={(event, newValue) => {
                                                        setIvrNumber(newValue);
                                                    }}
                                                    value={ivrNumber}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip variant="outlined" label={option.ivr.toString()} {...getTagProps({ index })} />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="IVR"
                                                            placeholder="You can SELECT multiple IVR Numbers"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )} */}

									<div className="col-md-6 my-2">
										<TextField error={errorIvrNumber ? true : false} fullWidth inputProps={{ inputMode: 'numeric', pattern: '[6-9]{1}[0-9]{9}' }} label="IVR" name="ivr" value={ivrNumber} onChange={checkIvrNumber} />
									</div>

									{employeesData && (
										<div className="col-md-6 my-2">
											<div className="input_field">
												<Autocomplete
													disablePortal
													id="combo-box-demo"
													options={employeesData}
													onChange={(event, newValue) => { setEmployeeValue(newValue); }}
													defaultValue={defaultEmployee}
													value={employeeValue}
													getOptionLabel={(option) => ucwords((option.employee_name.toString()).toLowerCase())}
													renderInput={(params) => <TextField {...params} label="Default Employee" name="default_employee" required />}
												/>
											</div>
										</div>
									)}

									<div className="col-md-6 my-2">
										<Autocomplete
											disablePortal
											id="combo-box-demo"
											options={["Exclusive", "Channel"]}
											renderInput={(params) => <TextField {...params} label="Sale Type" name="sale_type" required />}
										/>
									</div>

									<div className="col-md-6 my-2">
										<Autocomplete
											disablePortal
											id="combo-box-demo"
											options={["Active", "Inactive"]}
											defaultValue={"Active"}
											renderInput={(params) => <TextField {...params} label="Status" name="project_status" required />}
										/>
									</div>

									<div className="col-md-4 my-2">
										Project Logo <span id="span_project_logo">*</span>
										<button className='btn'><AttachFileIcon className='selImgBtn' id='selImgBtn' onClick={() => selImgBtnClick()} /></button>
										<input type="file" hidden onChange={(e) => { processSelImg(e) }} className="uploadImg" id="uploadImg" accept=".jpg, .jpeg, .png" />

										<div>
											<img id="output" name="output" src="" />
										</div>

									</div>

									<div className="col-md-2 my-2">
										<Button type="submit" className='create_submit' variant="contained" color="success">Submit</Button>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}

export default ProjectAdd