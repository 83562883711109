import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Policy = () => {
    var navigate = useNavigate()

    return (
        <>
            <Navbar style={{ background: "#210039" }}>
                <Container>
                    <Navbar.Brand onClick={() => navigate("/")}>
                        <img
                            style={{ filter: "invert()" }}
                            alt=""
                            src={"/assets/images/pool_logo.png"}
                            height="50"
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                </Container>
            </Navbar>

            <section className='section_policy'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="text-center policy_main_heading">PRIVACY POLICY</h2>

                            <h4 className="policy_heading">Company information</h4>

                            <p>[Hereafter we refer to Pool CRM as the 'Company', the user will be referred to as the 'Client']</p>
                            <p>The Company is totally committed to privacy issues on the Internet and in general has no objection to users  can access the web application without providing any personal information. However, access is available only when the Client subscribes to it and personal information will be required at that point.</p>
                            <p>The Company will share/ disclose the Clients' information in the following ways:</p>
                            <p>As required by law, in matters of public safety or policy, or as compelled by a court of law;</p>
                            <p>We share information with agents/ contractors who use such information on behalf of the Company or in connection with administration or delivery of our products/ services.</p>
                            <p>To enforce the terms and conditions of the products or services.</p>
                            <p>To protect and defend the rights, interests or properties of the Company or its Affiliates.</p>
                            <p>If and as required in connection with the transfer of business assets as in during the event of a business reorganisation, amalgamation, or a merger.</p>

                            <h4 className="policy_heading">Business critical data</h4>

                            <p>The Company has a strict privacy policy whereby we do not share your business information with our employees, contractors, or agents.</p>
                            <p>We follow industry standard security measures and every effort is made to prevent the loss, misuse, alteration, or unauthorised disclosure of information. Information is stored in a secure database servers hosted with the Milesweb.</p>
                            <p>Any and all information pertaining to the Client's business is accessible only to the top management and technical architects. They access this data only for backup, technical upgrades, for heuristic purposes, and such services which require the information to operate, develop, or improve the services.</p>
                            <p>Though we ensure that all possible precautions are taken for the loss, misuse or illegal access of the information, the final backup safety is governed by the rules of the Vendors who host our servers, and the Company would not be responsible for data loss of any kind in case of extreme conditions like fire, earthquake, disk failure of hosting vendors, etc.</p>
                            <p>For offline software deployments, the Company does not take any responsibility for loss, misuse, alteration, or unauthorised disclosure of data.</p>

                            <h4 className="policy_heading">Cookies Policy</h4>
                            <p>Some pages on our application use 'cookies'. Cookies are small files that are placed on the hard drive for identification. This ensures that the next time somebody visits the site/ application, it is registered and customized. You can still choose to accept or reject the cookies, but some pages may not be accessible without accepting them.</p>
                            <p>You may also find links to other sites/ applications such as our partners and affiliates. While we make sure that highest levels of standards are maintained with those who share our enthusiasm for internet privacy, we are not responsible for the content or the privacy practices employed by other sites/ applications.</p>

                            <h4 className="policy_heading">Changes to the Privacy Policy</h4>
                            <p>The Company reserves the right to amend, alter, modify, replace, or suspend this Privacy Policy, from time to time, at its sole discretion.</p>

                            <h4 className="policy_heading">Your Consent:</h4>
                            <p>You agree to this policy when you use this site/ application. This Privacy Policy supersedes any earlier version. Any changes to this Policy will be posted on the site for your immediate referral.</p>

                            <h4 className="policy_heading">Contacting Pool CRM</h4>
                            <p>If you have any questions about this privacy statement, please contact:</p>
                            <p>Pool CRM<br />#1238, 23rd Cross, 30th Main Rd, Banashankari Stage II, Bengaluru, Karnataka 560070.</p>
                            <p>Emails: info@poolcrm.co.in</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Policy