import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const RevenueReport = () => {
	var navigate = useNavigate();
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("login_data")) {
			setLoginData(localStorage.getItem("login_data"))
			var login_data = JSON.parse(localStorage.getItem("login_data"))
			setLoginData(login_data)

			var isAvailable;

			if (login_data.employee_type == "MASTERUSER") {
				isAvailable = true;
			} else if (login_data.employee_rights) {
				var employee_rights_arr = (login_data.employee_rights).trim().split(",");
				var regex = new RegExp(employee_rights_arr.join("|"), "i");
				isAvailable = login_data.employee_type == "MASTERUSER";
			} else {
				navigate("/noAccess")
			}
			setUserRightStatus(isAvailable)
			if (isAvailable != true) { navigate("/noAccess") }
			else {
				getBookings(login_data.employee_type);
			}
		} else {
			navigate("/logout")
		}

		if (!localStorage.getItem("employee_id")) {
			navigate("/logout");
		}

	}, [])

	const getBookings = () => {

	}

	return (
		<section id="sectionLeadsSummary">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<Breadcrumb>
							<Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
							<Breadcrumb.Item onClick={() => navigate("/reports")}>Reports</Breadcrumb.Item>
							<Breadcrumb.Item active>Revenue Report</Breadcrumb.Item>
						</Breadcrumb>
					</div>
				</div>
			</div>
		</section>
	)
}

export default RevenueReport