import React, { useContext } from 'react'
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require('moment')

const ProjectList = (props) => {

  const context_data = useContext(ContextData)

  var navigate = useNavigate();

  const [projectsData, setProjectsData] = useState(false);
  const [loginData, setLoginData] = useState(false);
  const [userRightStatus, setUserRightStatus] = useState(false);
  

  useEffect(() => {
    if (localStorage.getItem("login_data")) {
      setLoginData(localStorage.getItem("login_data"))
      var login_data = JSON.parse(localStorage.getItem("login_data"))
      setLoginData(login_data)

      var isAvailable;

      if (login_data.employee_type == "MASTERUSER") {
        isAvailable = true;
      } else if (login_data.employee_rights) {
        var employee_rights_arr = (login_data.employee_rights).trim().split(",");
        var regex = new RegExp(employee_rights_arr.join("|"), "i");
        isAvailable = regex.test("ADD_PROJECT") || login_data.employee_type == "MASTERUSER";
      } else {
        navigate("/noAccess")
      }
      setUserRightStatus(isAvailable)
      if (isAvailable != true) { navigate("/noAccess") }
      getProjects(login_data.employee_type);

    } else {
      navigate("/logout")
    }

    if (!localStorage.getItem("employee_id")) {
      navigate("/logout");
    }
  }, [])

  async function getProjects(employee_type) {
    context_data.setProcessing(true)
    const getProjectsUrl = global.config.server_url + "getProjects.php";
    await axios.post(getProjectsUrl, { all_data: true }).then((getProjectsResponse) => {
      if (getProjectsResponse.data) {
        setProjectsData(getProjectsResponse.data);
        context_data.setProcessing(false)
      } else {
        alert("error in getting data")
        context_data.setProcessing(false)
      }
    }).catch(err => { console.log(err); context_data.setProcessing(false) });
  }

  const btnRenderer = (params) => {
    let edit_btn = document.createElement("button");
    edit_btn.innerHTML = "Edit";
    edit_btn.classList = "table_list_btn btn btn-info"
    edit_btn.onclick = function () {
      navigate({ pathname: "/editProject/", search: "id=" + params.data.id, state: { id: params.data.id } })
    };
    return edit_btn;
  }

  const imgRenderer = (params) => {
    // return <img src={process.env.PUBLIC_URL + '/uploads/' + params.data.project_logo} onClick={(e) => swipe(e.target.src)} width="50px" height="50px" id="" />

    if (params.data.project_logo) {
      let img = document.createElement("img");
      img.src = process.env.PUBLIC_URL + '/uploads/' + params.data.project_logo;
      img.classList = "table_list_img"
      img.onclick = function (e) {
        swipe(e.target.src)
      }
      return img;
    } else {
      return "-"
    }    // return <button>params.data.project_logo</button>
  }

  const swipe = (src) => {
    let url = src;
    window.open(url, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
  }

  const onFilterTextBoxChanged = useCallback(() => {
    context_data.aggridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-box').value
    );
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Projects</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <input className='btn btn-primary' type="button" value="Add New Project" onClick={() => navigate("/addProject")} />
          </div>
          <div className="col-md-4">
            <h3 className='text-center'>All Projects</h3>
          </div>
          <div className="col-md-4">
            <input className='form-control' type="text" id="filter-text-box" placeholder="Search..." onInput={onFilterTextBoxChanged} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {(projectsData == false || projectsData == null) ? (<div><h3 className="text-center m-5">No Data Available</h3></div>) : (
              <>
                <div className="ag-theme-alpine" style={{ height: "75vh", width: "100%" }}>
                  <AgGridReact
                    defaultColDef={context_data.aggridDefaultColDef}
                    rowData={projectsData}
                    pagination={true}
                    paginationAutoPageSize={true}
                    animateRows={true}
                    ref={context_data.aggridRef}
										onGridReady={() => context_data.aggridAutoSizeAll(false)}
										onFirstDataRendered={() => context_data.aggridAutoSizeAll(false)}

                  >
                    <AgGridColumn width="100" headerName="Sl No" valueGetter={"node.rowIndex+1"} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Project ID" field="builder_project_id" ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Name" field="builder_project_name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Area" field="builder_project_area" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="City" field="builder_project_city" valueGetter={context_data.aggrid_ucwords_value}></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Logo" field="project_logo" cellRenderer={imgRenderer} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Sale Type" field="sale_type" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Status" field="project_status" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Housing ID" field="housing_campaign_id" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Facebook ID" field="facebook_campaign_id" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="99Acres ID" field="acres99_campaign_id" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Magic Bricks ID" field="magicbricks_campaign_id" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="IVR" field="ivr_number" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Default Employee" field="default_employee_name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Builder ID" field="builder_id" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Builder Name" field="builder_name" valueGetter={context_data.aggrid_ucwords_value} ></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Created By" field="create_name" valueGetter={context_data.aggrid_ucwords_value}></AgGridColumn>
                    <AgGridColumn filter='agTextColumnFilter' headerName="Created On" field="create_datetime" valueGetter={context_data.aggrid_datetime_value}></AgGridColumn>
                    <AgGridColumn headerName="Actions" cellRenderer={btnRenderer} ></AgGridColumn>
                  </AgGridReact>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

    </>
  )
}

export default ProjectList