import { Button, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require("moment")

const BuilderAdd = (props) => {
    const context_data = useContext(ContextData)

    var navigate = useNavigate();

    const [buildersData, setBuildersData] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_BUILDER") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getNextBuilderId();
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    const [nextBuilderId, setNextBuilderId] = useState(null);

    const getNextBuilderId = async () => {
        const getNextBuilderIdUrl = global.config.server_url + "getNextBuilderId.php";

        axios.get(getNextBuilderIdUrl).then((getNextBuilderIdResponse) => {
            if (getNextBuilderIdResponse.data === false) {
                console.log(getNextBuilderIdResponse.data);
            } else {
                let builder_id = getNextBuilderIdResponse.data.builder_id;
                builder_id = parseInt(builder_id) + 1;
                builder_id = (String("00000000" + builder_id).slice(-8));
                setNextBuilderId(builder_id)
            }
        }).catch(err => { console.log(err); });
    }

    const submitAddBuilder = (e) => {
        e.preventDefault();

        context_data.setProcessing(true);

        const submitAddBuilderUrl = global.config.server_url + "addBuilder.php";
        const submitAddBuilderData = {
            builder_id: (e.target.builder_id.value),
            builder_name: (e.target.builder_name.value),
            builder_address: (e.target.builder_address.value),
            builder_city: (e.target.builder_city.value),
            builder_gst: (e.target.builder_gst.value),
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
        }

        axios.post(submitAddBuilderUrl, submitAddBuilderData).then((response) => {
            if (response.data === true) {
                alert("Builder Added");
                context_data.setProcessing(false);
                navigate({ pathname: "/allBuilders", state: { login_data: loginData } });
            } else {
                alert("Error in Adding Builder");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Adding Builder"); context_data.setProcessing(false); });

    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate("/allBuilders")}>Builders</Breadcrumb.Item>
                            <Breadcrumb.Item active>Add Builder</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h3 className='text-center'>Add Builder</h3>
                    </div>
                </div>
                <form onSubmit={submitAddBuilder}>
                    <div className="row ">
                        <div className="col-md-12">
                            <fieldset className='fieldset_info'>
                                <legend className='fieldset_info_legend'>Builder Information</legend>
                                <div className="row">

                                    <div className="col-md-6 my-2">
                                        <TextField fullWidth label="Builder ID" name="builder_id" required InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true }} value={nextBuilderId} />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <TextField onChange={context_data.checkSpecialCharacters} onBlur={context_data.checkSpecialCharacters} fullWidth label="Name" name="builder_name" required />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <TextField onChange={context_data.checkSpecialCharacters} onBlur={context_data.checkSpecialCharacters} fullWidth label="Office Address" name="builder_address" required />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <TextField onChange={context_data.checkSpecialCharacters} onBlur={context_data.checkSpecialCharacters} fullWidth label="City" name="builder_city" required />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <TextField onChange={context_data.checkSpecialCharacters} onBlur={context_data.checkSpecialCharacters} fullWidth label="GST Number" name="builder_gst" />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <Button type="submit" className="create_submit" variant="contained" color="success">Submit</Button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default BuilderAdd