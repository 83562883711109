import { LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, Stack, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ucwords from 'ucwords';
import { MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import $ from "jquery";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ReminderDetails from './ReminderDetails';
import { ContextData } from './ContextData';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

var moment = require("moment");

const ConductedReminder = (props) => {

    const context_data = useContext(ContextData)

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        var lead_id = searchParams.get("id");
        setEnquiryId(lead_id)
        getAllEmployees();
        getCoordinates();
    }, [])

    const [clientVisited, setClientVisited] = useState("yes")

    const [enquiryId, setEnquiryId] = useState(false)

    const [conductedFeedback, setConductedFeedback] = useState("")

    const [modeOfPayment, setModeOfPayment] = useState("");
    const [employeesList, setEmployeesList] = useState(false)
    const [advanceReceivedBy, setAdvanceReceivedBy] = useState("")

    const [transactionDatetime, setTransactionDatetime] = useState(new Date());

    const [radioAdvanceValue, setRadioAdvanceValue] = useState(false)

    const [reminderConductedShow, setReminderConductedShow] = useState(false);

    const handleReminderConductedShow = () => {
        context_data.setShowUpdateActivity(false);

        setConductedFeedback("")
        props.setClosedReason("")
        context_data.setPickerDatetime(context_data.reminderType.toLowerCase() == "sitevisit" ? moment(new Date()).add((30 - ((moment(new Date())).minute() % 15)), 'm').toDate() : moment(new Date()).add((5 - ((moment(new Date())).minute() % 5)), 'm').toDate())
        setReminderConductedShow(true);
        setModeOfPayment("")
        setAdvanceReceivedBy("")
        setTransactionDatetime(new Date())
        setRadioAdvanceValue(false)
    }
    const handleReminderConductedClose = () => {
        context_data.setShowUpdateActivity(true);

        setReminderConductedShow(false);
        setConductedFeedback("")
        props.setClosedReason("")
        setModeOfPayment("")
        setAdvanceReceivedBy("")
        setTransactionDatetime(new Date())
        setRadioAdvanceValue(false)
    }

    const [saleType, setSaleType] = useState("")

    const calculateBookedTotal = () => {
        let booked_unit_area = document.getElementById("booked_unit_area");
        booked_unit_area = (booked_unit_area) ? parseFloat(booked_unit_area.value) : "0";
        let booked_unit_price = document.getElementById("booked_unit_price");
        booked_unit_price = (booked_unit_price) ? parseFloat(booked_unit_price.value) : "0";
        let booked_additional_charges = document.getElementById("booked_additional_charges");
        booked_additional_charges = (booked_additional_charges) ? parseFloat(booked_additional_charges.value) : "0";

        if (booked_additional_charges > 0) {
            let total = ((booked_unit_area * booked_unit_price) + booked_additional_charges);
            if (total > 0) {
                document.getElementById("booked_total_price").value = total;
            } else {
                document.getElementById("booked_total_price").value = 0;
            }
        } else {
            let total = (booked_unit_area * booked_unit_price);
            if (total > 0) {
                document.getElementById("booked_total_price").value = total;
            } else {
                document.getElementById("booked_total_price").value = 0;
            }
        }
    }

    const getAllEmployees = async () => {
        const getEmployeesListUrl = global.config.server_url + "getEmployees.php";
        axios.post(getEmployeesListUrl, { all_data_list: true }).then((getEmployeesListResponse) => {
            if (getEmployeesListResponse.data) {
                setEmployeesList(getEmployeesListResponse.data);
            } else {
                console.log(getEmployeesListResponse)
            }
        }).catch(err => console.log(err));
    }

    const submitReminderConducted = async (e) => {

        e.preventDefault();
        props.unblockEmployeeWithLead()

        if (errorTransactionId) {
            alert("Please clear all the errors");
            return;
        } else {
            context_data.setProcessing(true);
            var activity = props.nextReminder.activity;

            var client_visited = e.target.client_visited ? e.target.client_visited.value : "";
            var feedback = e.target.conducted_feedback.value;
            var feedback_notes = "";
            if (activity.toLowerCase().includes("sitevisit")) {
                if (client_visited == "yes") {
                    feedback_notes = "Client visited ";
                }
                if (client_visited == "no") {
                    feedback_notes = "Client didnot visit ";
                }
            }
            feedback_notes = feedback_notes + (e.target.feedback_notes ? e.target.feedback_notes.value : "");
            var closed_reason = e.target.closed_reason ? e.target.closed_reason.value : "";
            var competitor_name = e.target.competitor_name ? e.target.competitor_name.value : "";
            competitor_name = competitor_name.replace(/["']/g, "");
            competitor_name = competitor_name.replace(/\s+/g, ' ').trim()

            var location = e.target.location ? e.target.location.value : props.leadDetails.location;
            location = location ? location : "";
            location = location.replace(/["']/g, "");
            location = location.replace(/\s+/g, ' ').trim()
            var min_budget = e.target.min_budget ? e.target.min_budget.value : props.leadDetails.min_budget;
            var max_budget = e.target.max_budget ? e.target.max_budget.value : props.leadDetails.max_budget;

            var latitude = e.target.latitude ? e.target.latitude.value : "";
            var longitude = e.target.longitude ? e.target.longitude.value : "";

            var booked_advance = e.target.booked_advance ? e.target.booked_advance.value : "";
            var booked_mode_of_payment = modeOfPayment;
            var booked_payment_received_by = e.target.booked_payment_received_by ? e.target.booked_payment_received_by.value : "";
            var booked_payment_received_by_id = e.target.booked_payment_received_by ? e.target.booked_payment_received_by.value.split(":")[0] : "";
            var booked_payment_received_by_name = e.target.booked_payment_received_by ? e.target.booked_payment_received_by.value.split(":")[1] : "";
            var booked_transaction_datetime = moment(transactionDatetime).format("DD-MMM-YYYY h:mm:ss a");
            var booked_transaction_id = e.target.booked_transaction_id ? e.target.booked_transaction_id.value : "";

            var notes = e.target.notes ? e.target.notes.value : "";
            notes = notes.replace(/["']/g, "");
            notes = notes.replace(/\s+/g, ' ').trim()

            var reminder_datetime = context_data.pickerDatetime;
            console.log(reminder_datetime)
            reminder_datetime.setSeconds(0)

            var id = (enquiryId != undefined || enquiryId != null) ? enquiryId : searchParams.get("id");


            const reminderConductedUrl = global.config.server_url + "reminderConducted.php";

            // for images upload START
            var images_names_arr = [];
            var image_output_file = document.getElementsByClassName("image_output_file");
            for (let img = 0; img < image_output_file.length; img++) {
                const element = image_output_file[img]
                const formData = new FormData();
                var current_datetime = moment(new Date()).format("YYYYMMDDHmmss")
                var img_name = "lead_" + id + "_sitevisit_" + current_datetime + "_" + (img + 1) + ".png"
                images_names_arr.push(img_name)
                formData.append(
                    "myFile",
                    element.files[0],
                    img_name
                );
                await axios.post("/reactimageupload.php", formData).then((response) => {
                    if (response.data == true) {
                        console.log("image uploaded");
                    } else {
                        console.log("Error in uploading image");
                    }
                }).catch(err => { console.log(err); console.log("Error in uploading image"); });
            }
            const images_names_string = images_names_arr.toString();
            // for images upload END

            if (feedback.toLowerCase() == "booked") {

                // for booked images upload START
                var booked_images_names_arr = [];
                var booked_image_output_file = document.getElementsByClassName("booked_image_output_file");
                for (let img = 0; img < booked_image_output_file.length; img++) {
                    const element = booked_image_output_file[img]
                    // console.log(element)
                    const formData = new FormData();
                    var current_datetime = moment(new Date()).format("YYYYMMDDHmmss")
                    var img_name = "lead_" + id + "_booked_" + current_datetime + "_" + (img + 1) + ".png"
                    booked_images_names_arr.push(img_name)
                    formData.append(
                        "myFile",
                        element.files[0],
                        img_name
                    );
                    await axios.post("/reactimageupload.php", formData).then((response) => {
                        if (response.data == true) {
                            console.log("image uploaded");
                        } else {
                            console.log("Error in uploading image");
                        }
                    }).catch(err => { console.log(err); console.log("Error in uploading image"); });
                }
                const booked_images_names_string = booked_images_names_arr.toString();
                // for booked images upload END

                const all_images_arr = images_names_arr.concat(booked_images_names_arr)
                const all_images_string = all_images_arr.toString()

                if (booked_images_names_arr.length < 1) {
                    alert("SELECT Booked form image")
                    context_data.setProcessing(false);
                    return
                } else {
                    const reminderConductedData = {
                        images_names: all_images_string,
                        feedback: feedback,
                        client_visited: client_visited,
                        booked_advance: booked_advance,
                        booked_mode_of_payment: booked_mode_of_payment,
                        booked_payment_received_by: booked_payment_received_by,
                        booked_payment_received_by_id: booked_payment_received_by_id ? booked_payment_received_by_id.toUpperCase() : "",
                        booked_payment_received_by_name: booked_payment_received_by_name ? booked_payment_received_by_name.toUpperCase() : "",
                        booked_transaction_datetime: booked_transaction_datetime,
                        booked_transaction_id: booked_transaction_id,
                        activity: activity,
                        latitude: latitude,
                        longitude: longitude,

                        booked_builder_sales_person_name: (e.target.booked_builder_sales_person_name.value) ? (e.target.booked_builder_sales_person_name.value) : "",
                        booked_unit_number: (e.target.booked_unit_number.value) ? (e.target.booked_unit_number.value) : "",
                        booked_unit_area: (e.target.booked_unit_area.value) ? (e.target.booked_unit_area.value) : "",
                        booked_unit_price: (e.target.booked_unit_price.value) ? (e.target.booked_unit_price.value) : "",
                        booked_additional_charges: (e.target.booked_additional_charges.value) ? (e.target.booked_additional_charges.value) : "",
                        booked_total_price: (e.target.booked_total_price.value) ? (e.target.booked_total_price.value) : "",
                        booked_status: "Booked",

                        login_employee_id: (localStorage.getItem("employee_id")),
                        login_employee_name: (localStorage.getItem("employee_name")),
                        id: id
                    };
                    await axios.post(reminderConductedUrl, reminderConductedData).then((response) => {
                        if (response.data == true) {
                            context_data.setProcessing(false);
                            handleReminderConductedClose();
                            props.getLeadDetails();
                        } else {
                            alert("Error in Submitting data");
                            context_data.setProcessing(false);
                        }
                    }).catch(err => { console.log(err); alert("Error"); context_data.setProcessing(false); });
                }
            }
            else if (feedback.toLowerCase() == "closed") {
                const reminderConductedData = {
                    images_names: images_names_string,
                    latitude: latitude,
                    longitude: longitude,
                    location: location,
                    min_budget: min_budget,
                    max_budget: max_budget,

                    feedback: feedback,
                    client_visited: client_visited,
                    feedback_notes: feedback_notes,
                    closed_reason: closed_reason,
                    competitor_name: competitor_name ? ucwords(competitor_name.toLowerCase()) : "",
                    activity: activity,
                    login_employee_id: (localStorage.getItem("employee_id")),
                    login_employee_name: (localStorage.getItem("employee_name")),
                    id: id
                };
                await axios.post(reminderConductedUrl, reminderConductedData).then((response) => {
                    if (response.data == true) {
                        context_data.setProcessing(false);
                        handleReminderConductedClose();
                        props.getLeadDetails();
                    } else {
                        alert("Error in Submitting data");
                        context_data.setProcessing(false);
                    }
                }).catch(err => { console.log(err); alert("Error"); context_data.setProcessing(false); });
            } else {
                const addReminderData = {
                    notes_data: notes ? ucwords(notes.toLowerCase()) : "",
                    latitude: latitude,
                    longitude: longitude,

                    reminder_datetime: reminder_datetime,
                    login_employee_id: (localStorage.getItem("employee_id")),
                    login_employee_name: (localStorage.getItem("employee_name")),
                    id: id
                };

                var addReminderUrl;

                if (context_data.reminderType == "sitevisit") {
                    addReminderUrl = global.config.server_url + "addSiteVisit.php";
                } else if (context_data.reminderType == "followup") {
                    addReminderUrl = global.config.server_url + "addFollowUp.php";
                }

                const reminderConductedData = {
                    feedback: feedback,
                    latitude: latitude,
                    longitude: longitude,

                    feedback_notes: feedback_notes,
                    client_visited: client_visited,
                    images_names: images_names_string,
                    activity: activity,
                    login_employee_id: (localStorage.getItem("employee_id")),
                    login_employee_name: (localStorage.getItem("employee_name")),
                    id: id
                };

                await axios.post(reminderConductedUrl, reminderConductedData).then((response) => {
                    if (response.data == true) {
                        axios.post(addReminderUrl, addReminderData).then((response) => {
                            if (response.data == true) {
                                context_data.setProcessing(false);
                                handleReminderConductedClose();
                                props.getLeadDetails();

                            } else {
                                alert("Error in Creating new Reminder");
                                context_data.setProcessing(false);
                            }
                        }).catch(err => { console.log(err); alert("Error in Adding new Reminder"); context_data.setProcessing(false); });
                    } else {
                        alert("Error in Submitting data");
                        context_data.setProcessing(false);
                    }
                }).catch(err => { console.log(err); alert("Error"); context_data.setProcessing(false); });
            }
        }
    }

    const processSelImg = (e, output_id) => {
        const file = e.target.files[0];
        var btn = document.createElement('input');
        btn.classList.add("btn_output_image_remove");
        btn.classList.add("btn");
        btn.classList.add("btn-danger");
        btn.type = "button"
        btn.value = "X"
        btn.onclick = function () {
            $(this).parent().remove();
            return false;
        };
        var img = document.createElement('img');
        img.classList.add("output_image");

        if (!file) { alert("Image not selected"); return; }
        if (file) {
            try {
                Resizer.imageFileResizer(
                    file, 150, 150, 'JPEG', 100, 0,
                    uri => {
                        // document.querySelector("#" + output_id).src = uri;
                        img.src = uri
                    },
                    'base64'
                );
                // div.append(img)
                // div.append(btn)
                document.getElementById(output_id).append(img);
                document.getElementById(output_id).append(btn);
            } catch (err) {
                console.log(err)
            }
        }
    }

    const selImgBtnClick = () => {
        var output_image = $(".output_image");
        if (output_image.length < 5) {
            var div = document.createElement('div');
            div.classList.add("div_image_output")
            var div_id = "div_image_output_" + (output_image.length + 1)
            div.setAttribute("id", div_id)

            var input_file = document.createElement("input");
            input_file.classList.add("image_output_file")
            input_file.type = "file";
            input_file.hidden = "hidden";
            input_file.name = "image_output_file_" + (output_image.length + 1);
            input_file.setAttribute("id", "image_output_file_" + (output_image.length + 1))
            input_file.accept = ".jpg, .jpeg, .png"
            input_file.click()
            input_file.onchange = function (e) {
                processSelImg(e, div_id)
            }
            div.append(input_file)
            document.getElementById("div_images_files").append(div)
        }
    }


    const processBookedSelImg = (e, output_id) => {
        const file = e.target.files[0];
        var btn = document.createElement('input');
        btn.classList.add("btn_output_image_remove");
        btn.classList.add("btn");
        btn.classList.add("btn-danger");
        btn.type = "button"
        btn.value = "X"
        btn.onclick = function () {
            $(this).parent().remove();
            return false;
        };
        var img = document.createElement('img');
        img.classList.add("output_booked_image");

        if (!file) { alert("Image not selected"); return; }
        if (file) {
            try {
                Resizer.imageFileResizer(
                    file, 150, 150, 'JPEG', 100, 0,
                    uri => {
                        // document.querySelector("#" + output_id).src = uri;
                        img.src = uri
                    },
                    'base64'
                );
                // div.append(img)
                // div.append(btn)
                document.getElementById(output_id).append(img);
                document.getElementById(output_id).append(btn);
            } catch (err) {
                console.log(err)
            }
        }
    }
    const selBookedImgBtnClick = () => {
        var output_booked_image = $(".output_booked_image");
        if (output_booked_image.length < 5) {
            var div = document.createElement('div');
            div.classList.add("div_image_output")
            var div_id = "div_image_output_" + (output_booked_image.length + 1)
            div.setAttribute("id", div_id)

            var input_file = document.createElement("input");
            input_file.classList.add("booked_image_output_file")
            input_file.type = "file";
            input_file.hidden = "hidden";
            input_file.name = "image_output_file_" + (output_booked_image.length + 1);
            input_file.setAttribute("id", "image_output_file_" + (output_booked_image.length + 1))
            input_file.accept = ".jpg, .jpeg, .png"
            input_file.click()
            input_file.onchange = function (e) {
                processBookedSelImg(e, div_id)
            }
            div.append(input_file)
            document.getElementById("div_booked_images_files").append(div)
        }
    }

    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const getCoordinates = async () => {
        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition(
                position => {
                    setLatitude(position.coords.latitude)
                    setLongitude(position.coords.longitude)
                },
                err => {
                    if (err.code == 1) {
                        var statement = "Permission Denied !! Try Refreshing page or Grant Permission using Browser Settings"
                        alert(statement)
                        addTicket(statement)
                    } else {
                        var statement = 'Geolocation is not supported by this device';
                        alert(statement)
                        addTicket(statement)
                    }
                    console.log(err)
                }
            );
        } else {
            var statement = 'Geolocation is not supported by this Device';
            alert(statement)
            addTicket(statement)
        }
    }

    function addTicket(statement) {
        const addTicketData = {
            statement: statement,
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
        };
        const addTicketUrl = global.config.server_url + "addTicket.php";
        axios.post(addTicketUrl, addTicketData).then((response) => {
            if (response.data == true) {
                console.log("Added Ticket")
            } else {
                console.log("Error in adding ticket");
            }
        }).catch(err => { console.log(err); alert("adding ticket Error"); });
    }

    const [errorTransactionId, setErrorTransactionId] = useState(false)
    const [transactionList, setTransactionList] = useState(false)

    const checkTransactionID = (event, column) => {
        const inputValue = event.target.value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (transactionList.filter(function (e) { return e[column].toLowerCase() === inputValue; }).length > 0) {
            setErrorTransactionId(true)
            console.log(true)
        } else {
            setErrorTransactionId(false)
            console.log(false)
        }
    };

    const ChangeRadioAdvanceValue = (params) => {
        if (params == true) {
            const getTransactionListUrl = global.config.server_url + "getTransaction.php";
            axios.post(getTransactionListUrl, { all_data: true }).then((getTransactionListResponse) => {
                if (getTransactionListResponse.data) {
                    setTransactionList(getTransactionListResponse.data);
                } else {
                    console.log(getTransactionListResponse)
                }
            }).catch(err => console.log(err));
        }
    }

    const changeReminderType = (e) => {
        context_data.setReminderType(e.target.value)
        context_data.setPickerDatetime(moment(new Date()).add((30 - ((moment(new Date())).minute() % 15)), 'm').toDate())
        console.log(moment(new Date()).add((30 - ((moment(new Date())).minute() % 15)), 'm').toDate())
    }

    return (
        <>
            <Modal
                show={reminderConductedShow}
                onHide={() => handleReminderConductedClose()}
                // backdrop="static"
                keyboard={false}
                id="reminderConductedShow"
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Details for {props.nextReminder && ucwords(props.nextReminder.activity)} Conducted</Modal.Title>
                </Modal.Header>
                <form className="form-group" onSubmit={(e) => submitReminderConducted(e)}>
                    <Modal.Body >
                        <div>
                            {(props.nextReminder) ? (
                                <div>
                                    <Stack spacing={2}>
                                        {(props.nextReminder.activity == "followup") ? (<>
                                            <TextField
                                                select
                                                name='conducted_feedback'
                                                label="Feedback"
                                                value={conductedFeedback}
                                                onChange={(e) => setConductedFeedback(e.target.value)}
                                                required
                                            >
                                                <MenuItem key={1} value="rnr">RNR</MenuItem>
                                                <MenuItem key={2} value="reschedule">Reschedule</MenuItem>
                                                <MenuItem key={3} value="out of station">Out of Station</MenuItem>
                                                <MenuItem key={4} value="booked">Booked</MenuItem>
                                                <MenuItem key={5} value="closed">Closed</MenuItem>
                                            </TextField>

                                            {(props.nextReminder.activity == "followup" && conductedFeedback.toLowerCase() == "closed") ? (
                                                <>
                                                    <TextField
                                                        select
                                                        name='closed_reason'
                                                        label="Closed Reason"
                                                        value={props.closedReason}
                                                        onChange={(e) => props.setClosedReason(e.target.value)}
                                                        required
                                                    >
                                                        {global.config.closed_reasons_list && global.config.closed_reasons_list.map((list, index) =>
                                                            <MenuItem key={index} value={list.reason}>{ucwords(list.reason)}</MenuItem>
                                                        )}
                                                    </TextField>
                                                </>
                                            ) : ("")}

                                        </>) :
                                            (<>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <FormControl>
                                                            <FormLabel id="demo-client_visited-label">Did Client Visit?</FormLabel>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-client_visited-label"
                                                                defaultValue="yes"
                                                                name="client_visited"
                                                                onChange={(e) => setClientVisited(e.target.value)}
                                                            >
                                                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="no" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>

                                                    <div className="col-md-9">
                                                        <TextField
                                                            select
                                                            name='conducted_feedback'
                                                            label="Feedback"
                                                            value={conductedFeedback}
                                                            onChange={(e) => setConductedFeedback(e.target.value)}
                                                            required
                                                            fullWidth
                                                        >
                                                            {clientVisited == "no" ? <MenuItem key={1} value="client postponed the sitevisit" selected>Client Postponed the Sitevisit</MenuItem> : <MenuItem key={2} value="revisit" selected>Revisit</MenuItem>}
                                                            <MenuItem key={3} value="booked">Booked</MenuItem>
                                                            <MenuItem key={4} value="closed">Closed</MenuItem>
                                                        </TextField>
                                                    </div>
                                                </div>
                                                {(props.nextReminder.activity == "sitevisit" && conductedFeedback.toLowerCase() == "closed") ? (
                                                    <>
                                                        <TextField
                                                            select
                                                            name='closed_reason'
                                                            label="Closed Reason"
                                                            value={props.closedReason}
                                                            onChange={(e) => props.setClosedReason(e.target.value)}
                                                            required
                                                        >
                                                            {global.config.closed_reasons_list && global.config.closed_reasons_list.map((list, index) =>
                                                                <MenuItem key={index} value={list.reason}>{ucwords(list.reason)}</MenuItem>
                                                            )}
                                                        </TextField>
                                                    </>
                                                ) : ("")}
                                            </>)
                                        }

                                        {conductedFeedback && conductedFeedback.toLowerCase() != "closed" && conductedFeedback != "booked" && (
                                            <>
                                                <ToggleButtonGroup
                                                    color="primary"
                                                    value={context_data.reminderType}
                                                    exclusive
                                                    onChange={changeReminderType}
                                                    name="reminder_type"
                                                >
                                                    <ToggleButton value="followup">Create Followup</ToggleButton>
                                                    <ToggleButton value="sitevisit">Create Sitevisit</ToggleButton>
                                                </ToggleButtonGroup>
                                                <ReminderDetails />
                                                {/* <ReminderDetails reminderType={context_data.reminderType} pickerDatetime={context_data.pickerDatetime} setPickerDatetime={context_data.setPickerDatetime} /> */}
                                            </>
                                        )}
                                        {(conductedFeedback == "closed" && props.closedReason == "lost to competitor") ? (
                                            <TextField label={"Competitor Name"} name="competitor_name" variant="outlined" required />
                                        ) : ""}

                                        {(conductedFeedback == "closed" && props.closedReason == "location mismatch") ? (
                                            <TextField label={"Preferred Location"} name="location" variant="outlined" required />
                                        ) : ""}

                                        {(conductedFeedback == "closed" && props.closedReason == "low budget") ? (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <TextField label={"Min Budget"} fullWidth name="min_budget" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} variant="outlined" required />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <TextField label={"Max Budget"} fullWidth name="max_budget" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} variant="outlined" required />
                                                    </div>
                                                </div>
                                            </>
                                        ) : ""}

                                        {conductedFeedback == "booked" && (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <TextField label={"Builder Name"} fullWidth variant='outlined' required value={props.leadDetails.builder_name ? ucwords(props.leadDetails.builder_name.toLowerCase()) : ""} InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField label={"Project Name"} fullWidth variant='outlined' required value={props.leadDetails.project_name ? ucwords(props.leadDetails.project_name.toLowerCase()) : ""} InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            select
                                                            name='sale_type'
                                                            label="Sale Type"
                                                            defaultValue={props.leadDetails.sale_type ? props.leadDetails.sale_type : ""}
                                                            value={saleType}
                                                            onChange={(e) => { setSaleType(e.target.value) }}
                                                            required
                                                            fullWidth
                                                            InputProps={{ readOnly: props.leadDetails.sale_type ? true : false, }}
                                                        >
                                                            <MenuItem key={"Channel"} value="Channel">Channel</MenuItem>
                                                            <MenuItem key={"Exclusive"} value="Exclusive">Exclusive</MenuItem>
                                                        </TextField>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField name="booked_builder_sales_person_name" label={"Builder Sales Person Name"} fullWidth variant='outlined' required />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField name="booked_unit_number" label={"Unit Number"} fullWidth variant='outlined' required />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField name="booked_unit_area" id="booked_unit_area" label={"Area(in Sq ft)"} fullWidth variant='outlined' required onChange={calculateBookedTotal} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField name="booked_unit_price" id="booked_unit_price" label={"Price(per Sq ft)"} fullWidth variant='outlined' required onChange={calculateBookedTotal} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField name="booked_additional_charges" id="booked_additional_charges" label={"Additional Charge"} fullWidth variant='outlined' onChange={calculateBookedTotal} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField name="booked_total_price" id="booked_total_price" label={"Total Price"} fullWidth variant='outlined' required InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div id="btn_sel_booked_img" onClick={selBookedImgBtnClick}>Booked Form: <AttachFileIcon /></div>
                                                        <div id="div_booked_images_files"></div>

                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <Form.Check
                                                            inline
                                                            label="No Advance"
                                                            name="radio_advance"
                                                            type="radio"
                                                            checked={radioAdvanceValue == false ? "checked" : ""}
                                                            onChange={() => { ChangeRadioAdvanceValue(false); setRadioAdvanceValue(false) }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="Advance Paid"
                                                            name="radio_advance"
                                                            type="radio"
                                                            checked={radioAdvanceValue == true ? "checked" : ""}
                                                            onChange={() => { ChangeRadioAdvanceValue(true); setRadioAdvanceValue(true) }}
                                                        />
                                                    </div>
                                                </div>

                                                {(radioAdvanceValue &&
                                                    <>
                                                        <div className="row" hidden>
                                                            <div className="col-6">
                                                                <TextField id="latitude" InputProps={{ readOnly: true }} label="Latitude" name="latitude" variant="outlined" value={latitude} />
                                                            </div>
                                                            <div className="col-6">
                                                                <TextField id="longitude" InputProps={{ readOnly: true }} label="Longitude" name="longitude" variant="outlined" value={longitude} />
                                                            </div>
                                                        </div>
                                                        <div className="row m-0">
                                                            <div className="col-md-6 mb-3" >
                                                                <TextField fullWidth variant="outlined" inputProps={{ min: 0, inputMode: 'numeric', pattern: '[0-9]*' }} label="Advance" type={"number"} name="booked_advance" required />
                                                            </div>
                                                            <div className="col-md-6 mb-3" >
                                                                <TextField
                                                                    select
                                                                    name='booked_mode_of_payment'
                                                                    label="Mode of Payment"
                                                                    value={modeOfPayment}
                                                                    onChange={(e) => { setModeOfPayment(e.target.value) }}
                                                                    required
                                                                    fullWidth
                                                                >
                                                                    <MenuItem key={"Cash"} value="Cash">Cash</MenuItem>
                                                                    <MenuItem key={"GooglePay"} value="GooglePay">GooglePay</MenuItem>
                                                                    <MenuItem key={"PhonePe"} value="PhonePe">PhonePe</MenuItem>
                                                                    <MenuItem key={"Paytm"} value="Paytm">Paytm</MenuItem>
                                                                    <MenuItem key={"BHIM"} value="BHIM">BHIM</MenuItem>
                                                                    <MenuItem key={"Cheque"} value="Cheque">Cheque</MenuItem>
                                                                    <MenuItem key={"DD"} value="DD">DD</MenuItem>
                                                                    <MenuItem key={"RTGS"} value="RTGS">RTGS</MenuItem>
                                                                    <MenuItem key={"NEFT"} value="NEFT">NEFT</MenuItem>
                                                                    <MenuItem key={"IMPS"} value="IMPS">IMPS</MenuItem>
                                                                </TextField>
                                                            </div>

                                                            {modeOfPayment && modeOfPayment != "Cash" && (
                                                                <div className="col-md-6 mb-3" >
                                                                    <TextField fullWidth error={errorTransactionId ? true : false} helperText={errorTransactionId ? "Transaction ID already used" : ""} variant="outlined" label="Transaction ID" name="booked_transaction_id" required onChange={(e) => checkTransactionID(e, "transaction_id")} />
                                                                </div>
                                                            )}

                                                            <div className="col-md-6 mb-3" >
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                                    <MobileDateTimePicker
                                                                        value={transactionDatetime}
                                                                        label={"SELECT Transaction Date and Time"}
                                                                        minDateTime={moment(new Date()).subtract(1, "days").toDate()}
                                                                        disableFuture
                                                                        onChange={(newValue) => {
                                                                            setTransactionDatetime(newValue);
                                                                        }}
                                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                                    />
                                                                </LocalizationProvider>

                                                            </div>
                                                            <div className="col-md-6 mb-3" >
                                                                <TextField fullWidth
                                                                    select
                                                                    name='booked_payment_received_by'
                                                                    label="Advance Received By"
                                                                    value={advanceReceivedBy}
                                                                    onChange={(e) => setAdvanceReceivedBy(e.target.value)}
                                                                    required
                                                                >
                                                                    {employeesList && employeesList.map((employee, index) =>
                                                                        <MenuItem key={index} value={employee.employee_id + ":" + employee.employee_name}>{ucwords(employee.employee_name.toLowerCase())}</MenuItem>
                                                                    )}
                                                                </TextField>

                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}


                                        {(props.nextReminder.activity == "sitevisit") ? (
                                            <>
                                                {(latitude && longitude) ? (
                                                    <>
                                                        <div className="container-fluid" hidden>
                                                            <div className="row" >
                                                                <div className="col">
                                                                    <TextField id="latitude" InputProps={{ readOnly: true }} label="Latitude" name="latitude" variant="outlined" value={latitude} />
                                                                </div>
                                                                <div className="col">
                                                                    <TextField id="longitude" InputProps={{ readOnly: true }} label="Longitude" name="longitude" variant="outlined" value={longitude} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div><Button variant='contained' onClick={() => getCoordinates()}>Get Current Location</Button></div>
                                                )}

                                                {conductedFeedback != "booked" && (
                                                    <div><Button variant='contained' onClick={() => selImgBtnClick()}>Add Image</Button></div>
                                                )}
                                                <div id="div_images_files"></div>
                                            </>
                                        ) : ""}
                                    </Stack>
                                </div>
                            ) : ""}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="submit" className="btn btn-primary" id="" value="Submit" />
                        {/* <input type="button" className="btn btn-danger" id="" onClick={() => handleReminderConductedClose()} value="Cancel" /> */}
                    </Modal.Footer>
                </form>
            </Modal>

            <input type="button" onClick={() => handleReminderConductedShow()} disabled={(props.nextReminder && (props.nextReminder.activity.toLowerCase() == "followup")) ? (props.leadDetails.pending_lead == true ? false : true) : false} className="leads_list_btn btn btn-success me-2 " value="Update" />
        </>
    )
}

export default ConductedReminder