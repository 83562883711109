import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useNavigate } from 'react-router-dom'

const NoAccess = () => {
    var navigate = useNavigate()
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div><h1 className="text-center">You don't have access to this page</h1></div>
        </>
    )
}

export default NoAccess