import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Breadcrumb, FloatingLabel, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';
import { ContextData } from '../Components/ContextData';

const IncentiveAdd = () => {
    const context_data = useContext(ContextData)
    var navigate = useNavigate();

    const [projectsData, setProjectsData] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);
    
    const exportRef = useRef();

    const [selectedProject, setSelectedProject] = useState(false);
    const [incentivesData, setIncentivesData] = useState([])
    const [incentiveType, setIncentiveType] = useState("%")
    const [allIncentivesData, setAllIncentivesData] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_PROJECT") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getProjects(login_data.employee_type);
            getAllIncentives();

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    async function getProjects(employee_type) {
        context_data.setProcessing(true)
        const getProjectsUrl = global.config.server_url + "getProjects.php";
        await axios.post(getProjectsUrl, { all_data: true }).then((getProjectsResponse) => {
            if (getProjectsResponse.data) {
                setProjectsData(getProjectsResponse.data);
                context_data.setProcessing(false)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    async function getAllIncentives() {
        context_data.setProcessing(true)
        const getIncentivesUrl = global.config.server_url + "getIncentives.php";
        await axios.post(getIncentivesUrl, { all_data_active: true }).then((getIncentivesResponse) => {
            if (getIncentivesResponse.data) {
                setAllIncentivesData(getIncentivesResponse.data);
                context_data.setProcessing(false)
                getIncentivesData(getIncentivesResponse.data)
            } else {
                alert("error in getting data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); context_data.setProcessing(false) });
    }

    // async function getIncentives(project) {
    //     var project_id = project.builder_project_id;
    //     context_data.setProcessing(true)
    //     const getIncentivesUrl = global.config.server_url + "getIncentives.php";
    //     await axios.post(getIncentivesUrl, { project_id: project_id }).then((getIncentivesResponse) => {
    //         if (getIncentivesResponse.data) {
    //             setIncentivesData(getIncentivesResponse.data);
    //             context_data.setProcessing(false)
    //         } else {
    //             alert("error in getting data")
    //             context_data.setProcessing(false)
    //         }
    //     }).catch(err => { console.log(err); context_data.setProcessing(false) });
    // }

    // const showProjectData = (projectData) => {
    //     var project_id = projectData.builder_project_id ? projectData.builder_project_id.toString().toUpperCase() : "";
    //     var project_name = projectData.builder_project_name ? ucwords((projectData.builder_project_name.toString()).toLowerCase()) : "";
    //     return project_id + " : " + project_name;
    // }

    async function submitAddIncentive(e) {
        e.preventDefault();

        var project = projectsData.find(obj => (obj.builder_project_id == e.target.project.value))

        var builder_id = project.builder_id;
        var builder_name = project.builder_name;
        var project_id = project.builder_project_id;
        var project_name = project.builder_project_name;

        var booking_value = e.target.booking_value.value;
        var incentive_type = e.target.incentive_type.value;
        var incentive_value = e.target.incentive_value.value;
        var active_from = e.target.active_from.value;

        var incentive_data = {
            builder_id: builder_id,
            builder_name: builder_name,
            project_id: project_id,
            project_name: project_name,
            booking_value: booking_value,
            incentive_type: incentive_type,
            incentive_value: incentive_value,
            active_from: active_from,

            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
        }

        context_data.setProcessing(true)
        const addIncentiveUrl = global.config.server_url + "addIncentive.php";
        await axios.post(addIncentiveUrl, incentive_data).then((addIncentiveResponse) => {
            if (addIncentiveResponse.data == true) {
                alert("Data Added");
                context_data.setProcessing(false)
                getAllIncentives();
            } else {
                alert("error in adding data")
                context_data.setProcessing(false)
            }
        }).catch(err => { console.log(err); alert("error in adding data"); context_data.setProcessing(false) });

    }

    const [selectedIncentivesData, setSelectedIncentivesData] = useState(false)

    const getIncentivesData = (all_incentives_data = allIncentivesData) => {

        var project = document.getElementById("select_project")
        var project_value = project ? project.value : ""

        if (project_value) {
            var foundValue = all_incentives_data.filter(obj => (obj.project_id == project_value));
            setSelectedIncentivesData(foundValue)
        } else {
            setSelectedIncentivesData(false)
        }
    }

    async function deleteIncentive(table_id) {
        context_data.setProcessing(true);

        const deleteIncentiveUrl = global.config.server_url + "deleteIncentive.php";
        await axios.post(deleteIncentiveUrl, { table_id: table_id }).then((deleteIncentiveResponse) => {
            if (deleteIncentiveResponse.data == true) {
                alert("Deleted");
                context_data.setProcessing(false);
                getAllIncentives()
            } else {
                alert("Error in Deleting");
                context_data.setProcessing(false);
            }
        }).catch(err => {
            alert("Error in Deleting");
            context_data.setProcessing(false);
            console.log(err);
        }
        );
    }

    return (
        <section id="section_incentive_add">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Incentive Add</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <form onSubmit={submitAddIncentive}>
                    <div className="row">
                        {(projectsData == false || projectsData == null) ? ("No Project Available") : (
                            <div className="col-md-3 col-12 my-2">
                                <FloatingLabel controlId="floatingSelect" label="Project">
                                    <Form.Select name="project" id="select_project" aria-label="Floating label SELECT example" required onChange={(e) => { setSelectedProject(e.target.value); getIncentivesData() }}>
                                        <option value="" selected>SELECT Project</option>
                                        {projectsData.map((project) => <option value={project.builder_project_id}>{ucwords(project.builder_project_name.toLowerCase())}</option>)}
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                        )}
                        {/* {projectsData && (
                            <div className="col-md-3 col-12 my-2">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={projectsData}
                                    onChange={(event, newValue) => {
                                        setSelectedProject(newValue);
                                        getIncentives(newValue);
                                    }}
                                    getOptionLabel={(option) => showProjectData(option)}
                                    renderInput={(params) => <TextField {...params} label="Project" name="project" required />}
                                />
                            </div>)} */}

                        {selectedProject && <>
                            <div className="col-md-2 col-6 my-2">
                                <FloatingLabel controlId="floatingBookingValue" label="Booking Value">
                                    <Form.Control name="booking_value" type="number" min="1" placeholder="Booking Value" required />
                                </FloatingLabel>
                            </div>
                            <div className="col-md-2 col-6 my-2">
                                <FloatingLabel controlId="floatingSelect" label="Type">
                                    <Form.Select name="incentive_type" aria-label="Type" onChange={(e) => setIncentiveType(e.target.value)} required>
                                        <option value="%" selected>%</option>
                                        <option value="Rs">Rs</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                            <div className="col-md-2 col-6 my-2">
                                <FloatingLabel controlId="floatingIncentive" label="Incentive Value">
                                    <Form.Control name="incentive_value" type="number" min="1" max={incentiveType == "%" ? "100" : ""} placeholder="Incentive Value" required />
                                </FloatingLabel>
                            </div>
                            <div className="col-md-2 col-6 my-2">
                                <FloatingLabel controlId="floatingActiveFrom" label="Active From">
                                    <Form.Control name="active_from" type="date" placeholder="Active From" required />
                                </FloatingLabel>
                            </div>
                            <div className="col-md-1 col-6 my-2">
                                <input type="submit" value="Submit" className="btn btn-success" />
                            </div>

                        </>}
                    </div>
                </form>

                <div className="row">
                    <div className="col-12">
                        {(selectedIncentivesData && selectedIncentivesData.length > 0) ? (
                            <>
                                <div className="div_incentives_data_list" id="div_incentives_data_list">
                                    <table id="all_incentives_data_list" className="display table table-striped table-bordered " ref={exportRef} >
                                        <thead className="bg-dark text-white">
                                            {/* <tr>
                                                <th colSpan={"16"} className="text-center">Incentives Data</th>
                                            </tr> */}
                                            <tr>
                                                <th className='incentives_data_list_th'>#</th>
                                                {/* <th className='incentives_data_list_th'>Builder Id</th> */}
                                                <th className='incentives_data_list_th'>Builder Name</th>
                                                {/* <th className='incentives_data_list_th'>Project Id</th> */}
                                                <th className='incentives_data_list_th'>Project Name</th>
                                                <th className='incentives_data_list_th'>Active From</th>
                                                <th className='incentives_data_list_th'>Booking Value</th>
                                                <th className='incentives_data_list_th'>Incentive Type</th>
                                                <th className='incentives_data_list_th'>Incentive Value</th>
                                                <th className='incentives_data_list_th'>Create Id</th>
                                                <th className='incentives_data_list_th'>Create Name</th>
                                                <th className='incentives_data_list_th'>Create Datetime</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {selectedIncentivesData.map((incentive, index) =>
                                                <tr key={incentive.id} >
                                                    <td className="incentives_data_list_td">{index + 1}</td>
                                                    <td className="incentives_data_list_td">{(incentive.builder_name) ? ucwords(incentive.builder_name.toLowerCase()) : "-"}</td>
                                                    <td className="incentives_data_list_td">{(incentive.project_name) ? ucwords(incentive.project_name.toLowerCase()) : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.active_from) ? incentive.active_from : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.booking_value) ? incentive.booking_value : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.incentive_type) ? incentive.incentive_type : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.incentive_value) ? incentive.incentive_value : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.create_id) ? incentive.create_id : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.create_name) ? incentive.create_name : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.create_datetime) ? incentive.create_datetime : "-"}</td>
                                                    <td className="assigned_projects_list_td"> <input type="button" className="projects_list_btn btn btn-info m-0 pt-0 pb-0 pl-1 pr-1" onClick={() => deleteIncentive(incentive.id)} value="Delete" />  </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        ) : ""}
                        {(!selectedIncentivesData && allIncentivesData && allIncentivesData.length > 0) ? (
                            <>
                                <div className="div_incentives_data_list" id="div_incentives_data_list">
                                    <table id="all_incentives_data_list" className="display table table-striped table-bordered " ref={exportRef} >
                                        <thead className="bg-dark text-white">
                                            {/* <tr>
                                                <th colSpan={"16"} className="text-center">Incentives Data</th>
                                            </tr> */}
                                            <tr>
                                                <th className='incentives_data_list_th'>#</th>
                                                {/* <th className='incentives_data_list_th'>Builder Id</th> */}
                                                <th className='incentives_data_list_th'>Builder Name</th>
                                                {/* <th className='incentives_data_list_th'>Project Id</th> */}
                                                <th className='incentives_data_list_th'>Project Name</th>
                                                <th className='incentives_data_list_th'>Active From</th>
                                                <th className='incentives_data_list_th'>Booking Value</th>
                                                <th className='incentives_data_list_th'>Incentive Type</th>
                                                <th className='incentives_data_list_th'>Incentive Value</th>
                                                <th className='incentives_data_list_th'>Create Id</th>
                                                <th className='incentives_data_list_th'>Create Name</th>
                                                <th className='incentives_data_list_th'>Create Datetime</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {allIncentivesData.map((incentive, index) =>
                                                <tr key={incentive.id} >
                                                    <td className="incentives_data_list_td">{index + 1}</td>
                                                    <td className="incentives_data_list_td">{(incentive.builder_name) ? ucwords(incentive.builder_name.toLowerCase()) : "-"}</td>
                                                    <td className="incentives_data_list_td">{(incentive.project_name) ? ucwords(incentive.project_name.toLowerCase()) : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.active_from) ? incentive.active_from : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.booking_value) ? incentive.booking_value : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.incentive_type) ? incentive.incentive_type : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.incentive_value) ? incentive.incentive_value : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.create_id) ? incentive.create_id : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.create_name) ? incentive.create_name : "-"}</td>
                                                    <td className="incentives_data_list_td text-end">{(incentive.create_datetime) ? incentive.create_datetime : "-"}</td>
                                                    <td className="assigned_projects_list_td"> <input type="button" className="projects_list_btn btn btn-info m-0 pt-0 pb-0 pl-1 pr-1" onClick={() => deleteIncentive(incentive.id)} value="Delete" />  </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </>

                        ) : ("")}

                    </div>
                </div>


            </div >
        </section >
    )
}

export default IncentiveAdd